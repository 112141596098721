//general icons
export const EDIT_ICON = 'edit';
export const SAVE_ICON = 'save';
export const DELETE_ICON = 'delete';
export const REMOVE_ICON = 'remove';
export const DROPDOWN_ICON = 'dropdown';
export const ADDRESS_BOOK_ICON = 'address book';
export const FILE_ALTERNATE_ICON = 'file alternate';
export const TAG_ICON = 'tag';
export const PENCIL_ALTERNATE_ICON = 'pencil alternate';
export const CHAT_ICON = 'wechat';
export const SEND_ICON = 'paper plane outline';
export const ANGLE_DOWN_ICON = 'angle down';
export const CARET_UP_ICON = 'caret up';
export const CARET_DOWN_ICON = 'caret down';
export const BULLHORN_ICON = 'bullhorn';
export const TRASH_ICON = 'trash';
export const TRASH_ALTERNATE_OUTLINE_ICON = 'trash alternate outline';
export const UPLOAD_ICON = 'upload';
export const DOWNLOAD_ICON = 'download';
export const X_ICON = 'x';
export const USER_ICON = 'user';
export const USERS_ICON = 'users';
export const ADDRESS_CARD_ICON = 'address card';
export const MAIL_ICON = 'mail';
export const SPY_ICON = 'spy';
export const QUESTION_CIRCLE_ICON = 'question circle';
export const QUESTION_CIRCLE_OUTLINE_ICON = 'question circle outline';
export const ANGLE_DOUBLE_LEFT_ICON = 'angle double left';
export const ANGLE_LEFT_ICON = 'angle left';
export const ANGLE_RIGHT_ICON = 'angle right';
export const ANGLE_DOUBLE_RIGHT_ICON = 'angle double right';
export const CLOSE_ICON = 'close';
export const EYE_ICON = 'eye';
export const EYE_SLASH_ICON = 'eye slash';
export const ARROW_RIGHT_ICON = 'arrow right';
export const REDO_ICON = 'redo';
export const CHECKMARK_ICON = 'checkmark';
export const CHECK_ICON = 'check'
export const ELLIPSIS_HORIZONTAL_ICON = 'ellipsis horizontal';
export const CLIPBOARD_ICON = 'clipboard';
export const SEARCH_ICON = 'search';
export const PLUS_ICON = 'plus';
export const EXCLAMATION_ICON = 'exclamation';
export const COPY_OUTLINE_ICON = 'copy outline';
export const COPY_ICON = 'copy';
export const REFRESH_ICON = 'refresh';
export const TIME_ICON = 'time';
export const UNDO_ICON = 'undo';
export const FOLDER_OPEN_OUTLINE_ICON = 'folder open outline';
export const FOLDER_ICON = 'folder';
export const CHEVRON_LEFT_ICON = 'chevron left';
export const CHEVRON_RIGHT_ICON = 'chevron right';
export const ASTERISK_ICON = 'asterisk';
export const HAND_POINT_UP_OUTLINE_ICON = 'hand point up outline';
export const HAND_POINT_UP = 'hand point up';
export const STAR_ICON = 'star';
export const STAR_OUTLINE_ICON = 'star outline';
export const CALENDAR_ALTERNATE_OUTLINE_ICON = 'calendar alternate outline';
export const CANCEL_ICON = 'cancel';
export const IMAGE_ICON = 'image';
export const LIST_ICON = 'list';
export const TH_LIST_ICON = 'th list';
export const MOBILE_ICON = 'mobile';
export const MOBILE_ALTERNATE_ICON = 'mobile alternate';
export const CALENDAR_OUTLINE_ICON = 'calendar outline';
export const EDIT_OUTLINE_ICON = 'edit outline';
export const STOP_ICON = 'stop';
export const QUESTION_MARK_ICON = 'question mark';
export const IOS_ICON = 'apple';
export const ANDROID_ICON = 'android';
export const SPINNER_ICON = 'spinner';
export const HOME_ICON = 'home';
export const EXCHANGE_ICON = 'exchange';
export const KEY_ICON = 'key';
export const PAUSE_ICON = 'pause';
export const FILE_CODE_ICON = 'file code';
export const LOCATION_ARROW_ICON = 'location arrow';
export const ARROR_ALTERNATE_CIRCLE_DOWN_ICON = 'arrow alternate circle down';
export const ARROW_DOWN_ICON = 'arrow down';
export const INFO_ICON = 'info';
export const DONT_ICON = 'dont';
export const VIDEO_ICON = 'video';
export const PHOTO_ICON = 'photo';
export const SIDEBAR_ICON = 'sidebar';
export const FILE_IMAGE_ICON = 'file image';
export const AREA_GRAPH_ICON = 'area graph';
export const HAND_SCISSORS_ICON = 'hand scissors';
export const HAND_PEACE_ICON = 'hand peace';
export const MOUSE_POINTER_ICON = 'mouse pointer';
export const CLOUD_UPLOAD_ICON = 'cloud upload';
export const UNORDERED_LIST_ICON = 'unordered list';
export const SHARE_ALTERNATE_ICON = 'share alternate';
export const ADD_ICON = 'add';
export const SYNC_ICON = 'sync';
export const POWER_ICON = 'power';
export const LOCK_ICON = 'lock';
export const WORLD_ICON = 'world';
export const BACKWARD_ICON = 'backward';
export const USER_TIMES_ICON = 'user times';
export const USER_PLUS_ICON = 'user plus';
export const ADD_USER_ICON = 'add user';
export const BUG_ICON = 'bug';
export const USER_SECRET_ICON = 'user secret';
export const BROWSER_ICON = 'browser';
export const OPTIONS_ICON = 'options';
export const SHIELD_ALTERNATE_ICON = 'shield alternate';
export const SYNC_ALTERNATE_ICON = 'sync alternate';
export const PLAY_ICON = 'play';
export const ARCHIVE_ICON = 'archive';
export const TROPHY_ICON = 'trophy';
export const CALENDAR_PLUS_OUTLINE_ICON = 'calendar plus outline';
export const CIRCLE_ICON = 'circle';
export const MAP_SIGNS_ICON = 'map signs';
export const ALARM_ICON = 'alarm';
export const COG_ICON = 'cog';
export const CIRCLE_THIN_ICON = 'circle thin';
export const SITEMAP_ICON = 'sitemap';
export const TABLET_ICON = 'tablet';
export const TABLET_ALTERNATE_ICON = 'tablet alternate';
export const SERVER_ICON = 'server';
export const ADJUST_ICON = 'adjust';
export const EXPAND_ARROWS_ALTERNATE_ICON = 'expand arrows alternate';
export const BOX_ICON = 'box';
export const CODE_ICON = 'code';
export const FILTER_ICON = 'filter';
export const BARS_ICON = 'bars';
export const SETTING_ICON = 'setting';
export const BELL_ICON = 'bell';
export const USER_OUTLINE_ICON = 'user outline';
export const SIGN_OUT_ICON = 'sign out';
export const ARROW_LEFT_ICON = 'arrow left';
export const CLOCK_ICON = 'clock';
export const CSV_ICON = 'file excel'
export const BRAILLE_ICON = 'braille'
export const WEB_MODE_ICON = 'world'
export const INSPECTOR_COPY = 'copy'
export const INSPECTOR_DOWNLOAD = 'download'
export const FILE_OUTLINE = 'file outline'