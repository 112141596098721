import React, {Component} from 'react';
import {Button, Header, Modal, Segment, Icon} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { OK_BUTTON, warningMessages } from '../utils/UIMessages';
import { EXCLAMATION_ICON, CHECKMARK_ICON } from '../utils/UiIcons';
class TimedOutWarningModal extends Component {
    render() {
        return (
            <div>
                <Modal open={this.props.warningModalVisibility} size='small' closeIcon style={{zIndex:9999}}
                       onClose={this.props.onCloseWarningModal} dimmer={'blurring'}>
                    <Header icon={EXCLAMATION_ICON} content={warningMessages().MODAL_HEADER} color='yellow'/>
                    <Modal.Content style={{textAlign: 'center'}}>
                        <Segment>
                            <Header size='medium'>{this.props.message}</Header>
                        </Segment>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='green' onClick={this.props.onCloseWarningModal} icon> <Icon name={CHECKMARK_ICON}/> {OK_BUTTON()} </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

export default withTranslation()(withRouter(TimedOutWarningModal));
