import React, {Component} from 'react';
import {LazyLog, ScrollFollow} from 'react-lazylog'
import {Button, Icon, Placeholder, Segment, Tab} from "semantic-ui-react";
import ManageDeviceInfo from './ManageDeviceInfo';
import ManageInspector from './ManageInspector'
import {withTranslation} from "react-i18next";
import {MANAGE_MODES} from "../../utils/Constants"
import ManageLocation from "./ManageLocation";
import axios from 'axios'
import { showError } from '../../utils/ToastHelpers';
import {
    startCollectingLogs,
    stopCollectingLogs
} from '../../api/apiCalls'
import { DEVICE_DETAILS_LABEL, DEVICE_LOGS_HEADER, FAILED_ERROR_MESSAGE, INSPECTOR_HEADER, manageLogMessages } from '../../utils/UIMessages';
import { ARROR_ALTERNATE_CIRCLE_DOWN_ICON, ARROW_DOWN_ICON, PAUSE_ICON, STOP_ICON } from '../../utils/UiIcons';
import { connect } from 'react-redux';



class ManageLog extends Component {
    manageInspectorRef = undefined;
    state = {
        showLogs: false,
        follow: true,
        rightBottomButton: "none",
        // token: JSON.parse(localStorage.getItem("basicToken")), bearer
        activeIndex: MANAGE_MODES.DEVICE_DETAILS,
        uuId:'',
        fileName:undefined,
        downloadable:false,
        logType:undefined
    };


    handleLogsOn = () => {
      const logType = this.state.activeIndex === MANAGE_MODES.DEVICE_LOGS ? 'Device' : 'Appium'
        this.setState({logType,showLogs: true},()=>{
            startCollectingLogs(this.props.id,logType)
            .then(resp => {
                this.setState({
                    uuId:resp.data.replace(/[\r\n]+/gm, ''),
                    downloadable:true
                })
          })
          .catch(()=>{
            this.setState({downloadable:false});
          })
        })
    };

    handleLogsOff = () => {
        const {uuId, showLogs} = this.state;
        if (!showLogs) {
            return;
        }
        this.setState({showLogs: false},()=>{
            if(this.state.downloadable){
                stopCollectingLogs(this.props.id,uuId)
                .then(resp => {
                    this.setState({
                        fileName:resp.data.replace(/[\r\n]+/gm, '')
                    })
                })
            }
        })
    };

    handleDownloadLogs = () =>{
      const {fileName } = this.state;
      let urlDownload = `/api/v1/devices/${this.props.id}/logs/download-file`;
      if (fileName !== undefined) {
          urlDownload = urlDownload.concat('?file=' + fileName);
      }
      axios
          .get(urlDownload, { responseType: 'blob' })
          .then(res => {
              //Create URL for file object
              const objectURL = window.URL.createObjectURL(new Blob([res.data]));
              const linkElement = document.createElement('a');
              linkElement.href = objectURL;
              linkElement.setAttribute('download',`${fileName}`);
              document.body.appendChild(linkElement);
              linkElement.click();
              linkElement.remove();
              this.setState({downloadable:false})
          })
          .catch(err => {
              showError(
                  FAILED_ERROR_MESSAGE() +
                      err?.response?.data?.message ?? err.toString()
              );
          });
    }

    handleLogFollow = () => {
        this.setState(prevState => ({
            follow: !prevState.follow
        }));
    };


    handleTabChange = (e, {activeIndex}) => {
        this.setState({activeIndex}, () => {
            this.props.setActiveIndex(activeIndex)
        })
    };

    setDeviceDisconnected = () => {
        this.handleLogsOff();
        this.manageInspectorRef?.setInspectorClosed();
    }

    componentDidMount = () => {
        this.props.onRef(this);
    }

    componentWillUnmount = () =>{
        this.props.onRef(undefined);
        if(this.state.downloadable){
            //TODO: should obey rest naming conventions
            axios.get(`/api/v1/devices/${this.props.id}/logs/stop?uuId=`+this.state.uuId);
        }
    }

    renderItems = (showLogs) => {
        if (showLogs) {
            let url = "";
            let deviceOrAppium = ""
            const udId = this.props.id;
            if (this.state.activeIndex === MANAGE_MODES.DEVICE_LOGS) {
                url = `/api/devices/${udId}/log`;
                deviceOrAppium = "Device"
            } else if (this.state.activeIndex === MANAGE_MODES.APPIUM_LOGS) {
                url = `/api/devices/${udId}/appium-log`;
                deviceOrAppium = "Appium"
            }


            let {follow} = this.state;

            return (
                <Segment id='logs-segment'>
                    <ScrollFollow
                        startFollowing
                        render={() => (
                            <>
                                <div id='logs-buttons-div'>
                                    <Button size='mini' className='logs-button' circular secondary compact animated={'vertical'}
                                        onClick={() => {
                                            this.handleLogsOff()
                                        }}>
                                        <Button.Content visible>{manageLogMessages(deviceOrAppium).STOP_LOGS_LABEL}</Button.Content>
                                        <Button.Content hidden>
                                            <Icon name={STOP_ICON}/>
                                        </Button.Content>
                                    </Button>
                                    <Button size='mini' className='logs-button' circular secondary animated={'vertical'}
                                        onClick={() => this.handleLogFollow()}>
                                        <Button.Content visible>{this.state.follow ? <><Icon name={PAUSE_ICON}/></> : <><Icon
                                            name={ARROR_ALTERNATE_CIRCLE_DOWN_ICON}/></>}</Button.Content>
                                        <Button.Content hidden>
                                            {this.state.follow ? <>{manageLogMessages().PAUSE_LABEL}</> : <>{manageLogMessages().FOLLOW_LABEL}</>}
                                        </Button.Content>
                                    </Button>
                                </div>
                                <LazyLog
                                    extraLines={0}
                                    enableSearch
                                    url={url}
                                    stream
                                    fetchOptions={{
                                        headers: new Headers({
                                            "Authorization": `Bearer ${localStorage.getItem('accessToken')}` //bearer
                                        }),
                                        credentials: 'include' //Always send user credentials (cookies, basic HTTP auth, etc..), even for cross-origin calls.
                                    }
                                    }
                                    onScroll={() => {
                                        // if (scrollHeight - scrollTop <= clientHeight) {
                                        //     this.setState({ follow: true, rightBottomButton: "none" })
                                        // }
                                        // else if (scrollHeight - scrollTop === 0) {
                                        //     this.setState({ follow: true, rightBottomButton: "none" })
                                        // }
                                        //     else {
                                        //     this.setState({ follow: false, rightBottomButton: "inline-block" })
                                        // }
                                    }}
                                    follow={follow}
                                    selectableLines={true}
                                    overscanRowCount={1}
                                />
                                 

                            </>
                        )}
                    />
                </Segment>

            )
        } else {
         const {activeIndex,logType,downloadable} = this.state;
            return (<>
                    {/*RBA check*/}
                    {this.props.embedMode && this.props.embedMode==='inspector' ? 
                    <Tab
                    menu={{secondary: true, pointing: true, color: 'orange'}}
                    panes={[
                        {
                            menuItem: INSPECTOR_HEADER(),
                            render: () => <ManageInspector
                                onRef={ref => this.manageInspectorRef = ref}
                                id={this.props.id}
                                handleChangeInspectorMode={this.props.handleChangeInspectorMode}
                                inspectorMode={this.props.inspectorMode}
                                handleSelectedElement={this.props.handleSelectedElement}
                                sendAppSourceToManagePage={this.props.handleAppSource}
                                sendScreenshotToManagePage={this.props.handleScreenshot}
                                sendScreenResolutionToManagePage={this.props.handleScreenResolution}
                                selectedElementPath={this.props.selectedElementPath}
                                resetWebSocket={this.props.resetWebSocket}
                                inspectorStarted={this.props.inspectorStarted}
                                recordedActions={this.props.recordedActions}
                                clearActions={this.props.clearActions}
                                updateRecordedActions={this.props.updateRecordedActions}
                                isRecording={this.props.isRecording}
                                handleRecordingStatus={this.props.handleRecordingStatus}
                                embedMode={this.props.embedMode}
                                udId={this.props.udId}
                                userId={this.props.userId}
                                token={this.props.token}

                                // frozen inspector
                                detailedInfoId={this.props.detailedInfoId}
                                idd={this.props.id}
                                deviceScreenState={this.props.deviceScreenState}
                                connectedDeviceCount={this.props.connectedDeviceCount}
                                smartScreenshotLoading={this.props.smartScreenshotLoading}
                                selectedDevice={this.props.selectedDevice}
                                onLoadImage={this.props.onLoadImage}
                                deviceImgStyle={this.props.deviceImgStyle}
                                icon={this.props.icon}
                                handleKeyEvents={this.props.handleKeyEvents}
                                hoveredElement={this.props.hoveredElement}
                                selectedElement={this.props.selectedElement}
                                selectHoveredElement={this.props.selectHoveredElement}
                                selectSelectedElement={this.props.selectSelectedElement}
                                source={this.props.source}
                                ratio={this.props.ratio}
                                setAppiumSessionId={this.props.setAppiumSessionId}
                                clearAppiumSessionId={this.props.clearAppiumSessionId}
                                deviceOs={this.props.deviceOs}
                            />,
                        }
                    ]}
                    onTabChange={this.handleTabChange}
                    activeIndex={this.state.activeIndex}
                    />
                    : 
                    <Tab
                        menu={{secondary: true, pointing: true, color: 'orange'}}
                        panes={[
                            {
                                menuItem: DEVICE_DETAILS_LABEL(),
                                render: () => <ManageDeviceInfo id={this.props.id}
                                                                     setAfterInspector={this.props.setAfterInspector}
                                                                     afterInspector={this.props.afterInspector}
                                                                     privileges={this.props.privileges}

                                />,
                            },
                            this.props.privileges.includes("9") && {
                                menuItem: DEVICE_LOGS_HEADER(),
                                render: () => <></>,
                            },
                            this.props.privileges.includes("8") && this.props.systemParameterList.length > 0 && 
                            this.props.systemParameterList.find(param => param.parameterKey === 'allowLocalUserTestAutomation')?.parameterValue ==='true' && {
                                menuItem: manageLogMessages().APPIUM_LOGS_HEADER,
                                render: () => <></>,
                            },
                            this.props.systemParameterList.length > 0 && 
                            this.props.systemParameterList.find(param => param.parameterKey === 'allowLocalUserTestAutomation')?.parameterValue ==='true' && {
                                menuItem: INSPECTOR_HEADER(),
                                render: () => <ManageInspector
                                    onRef={ref => this.manageInspectorRef = ref}
                                    id={this.props.id}
                                    handleChangeInspectorMode={this.props.handleChangeInspectorMode}
                                    inspectorMode={this.props.inspectorMode}
                                    handleSelectedElement={this.props.handleSelectedElement}
                                    sendAppSourceToManagePage={this.props.handleAppSource}
                                    sendScreenshotToManagePage={this.props.handleScreenshot}
                                    sendScreenResolutionToManagePage={this.props.handleScreenResolution}
                                    selectedElementPath={this.props.selectedElementPath}
                                    resetWebSocket={this.props.resetWebSocket}
                                    inspectorStarted={this.props.inspectorStarted}
                                    recordedActions={this.props.recordedActions}
                                    clearActions={this.props.clearActions}
                                    updateRecordedActions={this.props.updateRecordedActions}
                                    isRecording={this.props.isRecording}
                                    handleRecordingStatus={this.props.handleRecordingStatus}
                                    embedMode={this.props.embedMode}
                                    udId={this.props.udId}
                                    userId={this.props.userId}
                                    token={this.props.token}

                                    // frozen inspector
                                    detailedInfoId={this.props.detailedInfoId}
                                    idd={this.props.id}
                                    deviceScreenState={this.props.deviceScreenState}
                                    connectedDeviceCount={this.props.connectedDeviceCount} 
                                    smartScreenshotLoading={this.props.smartScreenshotLoading}
                                    selectedDevice={this.props.selectedDevice}
                                    onLoadImage={this.props.onLoadImage}
                                    deviceImgStyle={this.props.deviceImgStyle}
                                    icon={this.props.icon}
                                    handleKeyEvents={this.props.handleKeyEvents}
                                    hoveredElement={this.props.hoveredElement}
                                    selectedElement={this.props.selectedElement}
                                    selectHoveredElement={this.props.selectHoveredElement}
                                    selectSelectedElement={this.props.selectSelectedElement}
                                    source={this.props.source}
                                    ratio={this.props.ratio}     
                                    setAppiumSessionId={this.props.setAppiumSessionId}
                                    clearAppiumSessionId={this.props.clearAppiumSessionId}
                                    deviceOs={this.props.deviceOs}                  
                                />,
                            },
                            !this.props.embedMode && {
                                menuItem: manageLogMessages().LOCATION_HEADER,
                                render: () => <ManageLocation id={this.props.id}/>,
                            }
                        ]}
                        onTabChange={this.handleTabChange}
                        activeIndex={this.state.activeIndex}
                        /> 
                    }

                    {(activeIndex === MANAGE_MODES.DEVICE_LOGS || activeIndex === MANAGE_MODES.APPIUM_LOGS) &&
                    <Segment inverted color="black">
                        <Button fluid color='orange' inverted animated={"vertical"} onClick={() => {
                            this.handleLogsOn()
                        }}>
                            <Button.Content visible>
                              {manageLogMessages(activeIndex === MANAGE_MODES.DEVICE_LOGS ? 'Device' : 'Appium').START_LOGS_LABEL}
                            </Button.Content>
                            <Button.Content hidden>
                                <Icon name='arrow down'/>
                            </Button.Content>
                        </Button>
                        {downloadable && ((activeIndex === MANAGE_MODES.DEVICE_LOGS && logType==='Device') ||(activeIndex === MANAGE_MODES.APPIUM_LOGS && logType==='Appium')) &&
                        <Button style= {{marginTop:'1vh'}} fluid color='orange' inverted animated={'vertical'} onClick={() => {
                            this.handleDownloadLogs()
                        }}>
                            <Button.Content visible>
                            {manageLogMessages(activeIndex === MANAGE_MODES.DEVICE_LOGS ? 'Device' : 'Appium').DOWNLOAD_LOGS_LABEL}
                            </Button.Content>
                            <Button.Content hidden>
                                <Icon name={ARROW_DOWN_ICON}/>
                            </Button.Content>
                        </Button>
                        }


                        <Placeholder inverted fluid style={{marginTop: "15"}}>
                            {Array.apply(null, Array(5)).map((p, index) => {
                                return (
                                    <Placeholder.Paragraph key={index}>
                                        <Placeholder.Line/>
                                        <Placeholder.Line/>
                                        <Placeholder.Line/>
                                        <Placeholder.Line/>
                                        <Placeholder.Line/>
                                        <Placeholder.Line/>
                                        <Placeholder.Line/>
                                    </Placeholder.Paragraph>
                                )
                            })}
                        </Placeholder>
                    </Segment>
                    }
                </>
            )
        }
    }

    render() {
        const {showLogs} = this.state;
        return (
            <>{this.renderItems(showLogs)}</>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        systemParameterList : state.systemParameters.systemParameters
    };
};

export default withTranslation()(
    connect(mapStateToProps)(ManageLog)
);

