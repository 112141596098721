import React, {Component} from 'react';
import {Grid, Header, Icon, Segment, Statistic, List} from "semantic-ui-react";
import {ResponsiveLine} from "@nivo/line";
import {ResponsivePie} from "@nivo/pie";
import DateRangeDropdown from "../../../../components/DateRangeDropdown";
import moment from "moment";
import _ from "lodash";
import CountUp from "react-countup";
import axios from "axios";
import {dataRefresher, renameKey} from "../../../../utils/Methods";
import {withTranslation} from 'react-i18next';
import {showError} from "../../../../utils/ToastHelpers";
import ReactTableV8 from '../../../../components/ReactTableV8/ReactTableV8'
import { chartsNumberOfUsersMessages, EVERY_DAY_LABEL, EVERY_MONTH_LABEL, FAILED_ERROR_MESSAGE, FULLNAME_HEADER, TIME_LABEL, USERNAME_HEADER, USERS_HEADER, USER_ROLES_LABEL } from '../../../../utils/UIMessages';
import { USER_ICON } from '../../../../utils/UiIcons';
class ChartsNou extends Component {

    //New React Table V8 Columns
    activeUsersColumns = () => [
        {
            header: () => <div>{USERNAME_HEADER()}</div>,
            accessorKey: 'userName',
            id: 'userName',
            cell: props => (<span className='number'><Icon name={USER_ICON} /> {props.getValue()}</span>)
        },
        {
            header: () => <div>{FULLNAME_HEADER()}</div>,
            accessorKey: 'fullName',
            id: 'fullName',
            cell: props => (<span className='number'>{props.getValue()}</span>)
        },
        {
            header: () => <div>{USER_ROLES_LABEL()}</div>,
            accessorKey: 'userRoles',
            id: 'userRoles',
            cell: info => (<List as='ol' verticalAlign='middle'>{info.getValue()?.map((role, i) => {
                return (<List.Item key={i} as='li' value='*'>
                    {role}
                </List.Item>)
            })}</List>)
        },
    ]

    //New React Table V8 Columns
    registeredUsersColumns = () => [
        {
            header: () => <div>{USERNAME_HEADER()}</div>,
            accessorKey: 'userName',
            id: 'userName',
            cell: props => (<span className='number'><Icon name={USER_ICON} /> {props.getValue()}</span>)
        },
        {
            header: () => <div>{FULLNAME_HEADER()}</div>,
            accessorKey: 'fullName',
            id: 'fullName',
            cell: props => (<span className='number'>{props.getValue()}</span>)
        },
        {
            header: () => <div>{USER_ROLES_LABEL()}</div>,
            accessorKey: 'userRoles',
            id: 'userRoles',
            cell: info => (<List as='ol' verticalAlign='middle'>{info.getValue()?.map((role, i) => {
                return (<List.Item key={i} as='li' value='*'>
                    {role}
                </List.Item>)
            })}</List>)
        }
    ]

    state = {
        componentDidUpdateCheck:false,
        dataForLineChart: [],
        dataForPieChart: [],
        visibility: false,
        totalUsers: 0,
        activeUserList: [],
        registeredUserList: [],
        startDate: new Date(),
        endDate: new Date(),
        pageSize1: 10,
        pageSize2: 10,
        //sorted1: [],
        //sorted2: [],
        loadingUserList: false,
        language: localStorage.getItem('lang') || 'en'
    }

    generateLines = (x, y, arr) => {
        let lineArray = []
        for (let arrKey in arr) {
            lineArray.push(
                {
                    "x": x[arrKey],
                    "y": y[arrKey]
                }
            )
        }
        return lineArray
    }

    convertDataForLineChart = (...allData) => {
        this.setState({visibility: false})
        let dataForLineChart = allData.map((el, index) => {
            let c = index * 4
            return {
                id: this.props.t(_.startCase(el.y)),
                color: `hsl(${c}, 70%, 50%)`,
                data: this.generateLines(el?.response[el.x], el?.response[el.y], el?.response[el.x])
            }
        })
        this.setState({dataForLineChart}, () => {
            this.setState({visibility: true})
        })
    }

    convertDataForPieChart = (...allData) => {
        this.setState({visibility: false})
        let dataForPieChart, totalUsers
        let registeredUserSum = allData[1]?.total
        let activeUsersSum = allData[0]?.total
        let inactiveUsers = registeredUserSum - activeUsersSum
        let activeValue = {
            id: chartsNumberOfUsersMessages().ACTIVE_USERS_LABEL,
            label: chartsNumberOfUsersMessages().ACTIVE_USERS_LABEL,
            value: activeUsersSum,
            color: "hsl(15, 70%, 50%)"
        }
        let inactiveValue = {
            id: chartsNumberOfUsersMessages().INACTIVE_USERS_LABEL,
            label: chartsNumberOfUsersMessages().INACTIVE_USERS_LABEL,
            value: inactiveUsers,
            color: "hsl(242, 70%, 50%)"
        }
        dataForPieChart = [activeValue, inactiveValue]
        totalUsers = activeUsersSum + inactiveUsers
        this.setState({dataForPieChart, totalUsers, visibility: true})

    }

    dailyOrMonthlyCheck = async (type, params = "") => { //server request
        this.setState({ loadingUserList: true });
        try {
            if (type === "daily") {
                let [activeDaily, registeredDaily] = await Promise.all([
                  axios.get('/api/reports/active-users-daily' + params),
                  axios.get('/api/reports/registered-users-daily' + params)
                ]);
                activeDaily = activeDaily.data;
                registeredDaily = registeredDaily.data;
                const active_users_daily = renameKey(activeDaily, "users", "activeUsers")
                const registered_users_daily = renameKey(registeredDaily, "users", "registeredUsers")
                this.convertDataForLineChart(
                    {"response": active_users_daily, x: "dates", y: "activeUsers"},
                    {"response": registered_users_daily, x: "dates", y: "registeredUsers"})
                this.convertDataForPieChart(active_users_daily, registered_users_daily)
                this.setState({
                    activeUserList: active_users_daily.userList,
                    registeredUserList: registered_users_daily.userList,
                    loadingUserList: false
                })
                let startDate = new Date(active_users_daily.dates[0])
                let endDate = new Date(active_users_daily.dates[active_users_daily.dates.length - 1])
                this.setState({startDate, endDate})
            }
            if (type === "monthly") {
                let [active_users_monthly, registeredMonthly] = await Promise.all([
                  axios.get('/api/reports/active-users-monthly' + params),
                  axios.get('/api/reports/registered-users-monthly' + params)
                ]);
                active_users_monthly = active_users_monthly.data;
                registeredMonthly = registeredMonthly.data;
                const registered_users_monthly = renameKey(registeredMonthly, "activeUsers", "registeredUsers")
                this.convertDataForLineChart(
                    {"response": active_users_monthly, x: "dates", y: "activeUsers"},
                    {"response": registered_users_monthly, x: "dates", y: "registeredUsers"})
                this.convertDataForPieChart(active_users_monthly, registered_users_monthly)
                this.setState({
                    activeUserList: active_users_monthly.userList,
                    registeredUserList: registered_users_monthly.userList,
                    loadingUserList: false
                })
                let startDate = new Date(active_users_monthly.dates[0])
                let endDate = new Date(active_users_monthly.dates[active_users_monthly.dates.length - 1])
                this.setState({startDate, endDate})

            }
        } catch (err) {
            showError(FAILED_ERROR_MESSAGE()+err?.response?.data?.message ?? err.toString());
            this.setState({ loadingUserList: false });
        }
    }

    componentDidMount() {
        this.dailyOrMonthlyCheck(this.props.type)
    }

    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type) {
            this.dailyOrMonthlyCheck(this.props.type)
            this.setState({
                // sorted1: [],
                // sorted2: [],
                componentDidUpdateCheck: !this.state.componentDidUpdateCheck,
                startDate: new Date(),
                endDate: new Date()
            })
        }
        dataRefresher(
            this.state.language,
            () => this.setState({ language: localStorage.getItem('lang') },
                () => this.dailyOrMonthlyCheck(this.props.type))
        )
    }
    
    onChangeDateComponent = link => {
        const params = "?" + link
        this.dailyOrMonthlyCheck(this.props.type, params)
    }

    render() {
        return (
            <Grid>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <DateRangeDropdown
                            isOrderByVisibility={false}
                            isSizeVisibility={false}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            isUserRolesVisibility={true}
                            type={this.props.type}
                            callback={(link) => {
                                this.onChangeDateComponent(link)
                            }} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered columns={1}>
                    <Grid.Column>
                        <Header textAlign={"center"}>
                            {chartsNumberOfUsersMessages(this.props.type === 'daily' ? 'Day' : 'Month').NUMBER_OF_USERS_BY_HEADER}
                        </Header>
                        <Segment placeholder style={{height: 500}} loading={this.state.loadingUserList}>
                            {this.state.visibility && <ResponsiveLine
                                data={this.state.dataForLineChart}
                                margin={{top: 50, right: 30, bottom: 70, left: 60}}
                                // xFormat={this.props.type === "daily" ? "time:%Y-%m-%d" : "time:%Y-%m"}
                                // xScale={{
                                //     type: 'time',
                                //     format: this.props.type === "daily" ? "%Y-%m-%d" : "%Y-%m",
                                //     useUTC: false,
                                //     precision: this.props.type === "daily" ? 'day': "month",
                                // }}
                                yFormat=">-d"
                                enableSlices="x"
                                yScale={{
                                    type: "linear",
                                    min: 0,
                                    max: "auto",
                                    stacked: false,
                                    reverse: false
                                }}
                                axisTop={null}
                                axisRight={null}
                                axisLeft={{
                                    orient: "left",
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    tickValues: 5,
                                    legend: USERS_HEADER(),
                                    legendOffset: -40,
                                    legendPosition: "middle"
                                }}
                                axisBottom={{
                                    format: this.props.type === 'daily' ? (tick) => moment(tick).format('MMM DD') : (tick) => moment(tick).format('YYYY MMM'),
                                    tickValues: this.props.type === 'daily' ? EVERY_DAY_LABEL() : EVERY_MONTH_LABEL(),
                                    tickRotation: -55,
                                    legend: TIME_LABEL(),
                                    legendOffset: 55,
                                    orient: 'bottom',
                                    tickSize: 5,
                                    tickPadding: 5,
                                    legendPosition: 'middle'
                                }}
                                crosshairType={"bottom-left"}
                                colors={{scheme: 'set1'}}
                                pointSize={10}
                                pointColor={{from: 'color', modifiers: []}}
                                pointBorderWidth={2}
                                pointBorderColor={{from: "serieColor"}}
                                pointLabel="y"
                                pointLabelYOffset={-12}
                                useMesh={true}
                                legends={[
                                    {
                                        anchor: "bottom-right",
                                        direction: "row",
                                        justify: false,
                                        translateX: 30,
                                        translateY: 60,
                                        itemsSpacing: 30,
                                        itemDirection: "left-to-right",
                                        itemWidth: 100,
                                        itemHeight: 0,
                                        itemOpacity: 0.75,
                                        symbolSize: 12,
                                        symbolShape: "circle",
                                        symbolBorderColor: "rgba(0, 0, 0, .5)",
                                        effects: [
                                            {
                                                on: "hover",
                                                style: {
                                                    itemBackground: "rgba(0, 0, 0, .03)",
                                                    itemOpacity: 1
                                                }
                                            }
                                        ]
                                    }
                                ]}
                            />}
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <Segment placeholder style={{height: 300, minHeight: "22rem"}} loading={this.state.loadingUserList}>
                            <ResponsivePie
                                data={this.state.dataForPieChart}
                                margin={{top: 40, right: 80, bottom: 80, left: 80}}
                                innerRadius={0.5}
                                padAngle={0.7}
                                cornerRadius={3}
                                colors={{scheme: 'set2'}}
                                borderWidth={1}
                                borderColor={{from: 'color', modifiers: [['darker', 0.2]]}}
                                radialLabelsSkipAngle={10}
                                radialLabelsTextColor="#333333"
                                radialLabelsLinkColor={{from: 'color'}}
                                sliceLabelsSkipAngle={10}
                                sliceLabelsTextColor="#333333"
                                legends={[
                                    {
                                        anchor: 'bottom',
                                        direction: 'row',
                                        justify: false,
                                        translateX: 25,
                                        translateY: 50,
                                        itemsSpacing: 20,
                                        itemWidth: 100,
                                        itemHeight: 18,
                                        itemTextColor: '#999',
                                        itemDirection: 'left-to-right',
                                        itemOpacity: 1,
                                        symbolSize: 18,
                                        symbolShape: 'circle',
                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemTextColor: '#000'
                                                }
                                            }
                                        ]
                                    }
                                ]}
                            />
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment placeholder style={{display: "flex", alignItems: "center", minHeight: "22rem"}} loading={this.state.loadingUserList}>
                            <div>
                                <Statistic.Group horizontal>
                                    {this.state.dataForPieChart?.map((el, index) => {
                                        return (
                                            <Statistic key={index}>
                                                <Statistic.Value>
                                                    <CountUp start={0} end={el.value} delay={0.7}/>
                                                </Statistic.Value>
                                                <Statistic.Label>{this.props.t(`${el.label}`)}</Statistic.Label>
                                            </Statistic>
                                        )
                                    })}
                                    <hr style={{width: "100%"}}/>
                                    {this.state.totalUsers !== 0 && <Statistic key={99}>
                                        <Statistic.Value>
                                            <CountUp start={0} end={this.state.totalUsers} delay={0.5}/>
                                        </Statistic.Value>
                                        <Statistic.Label>{chartsNumberOfUsersMessages().TOTAL_USER_LABEL}</Statistic.Label>
                                    </Statistic>}
                                </Statistic.Group>
                            </div>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{marginTop:10}}>
                    <Grid.Column>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <Header textAlign={'center'}>{chartsNumberOfUsersMessages().ACTIVE_USERS_LABEL}</Header>
                                    <Segment placeholder style={{minHeight: '22rem'}}>
                                        {/* Active Users React Table V8 */}
                                            <ReactTableV8
                                                data={this.state.activeUserList}
                                                columns={this.activeUsersColumns()}
                                                onComponentDidUpdateProp={this.state.componentDidUpdateCheck}
                                                loadingProp={this.state.loadingUserList}
                                            />
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column>
                                    <Header textAlign={'center'}>{chartsNumberOfUsersMessages().REGISTERED_USERS_HEADER}</Header>
                                    <Segment placeholder style={{ minHeight: '22rem' }}>
                                        {/* Active Users React Table V8 */}
                                        <ReactTableV8
                                            data={this.state.registeredUserList}
                                            columns={this.registeredUsersColumns()}
                                            onComponentDidUpdateProp={this.state.componentDidUpdateCheck}
                                            loadingProp={this.state.loadingUserList}
                                        />
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default withTranslation()(ChartsNou);
