/*----User Manuel for Announcement Modal Component ------- */
/*    openModalProp = Must. Defines the openning status of the announcement form modal,
    setOpenModalFuncProp = Must. Runs to close the modal

    updatableAnnouncementObjectProp = Arbitrary. Used during update operations.
   
    defaultAnnouncementNameProp = Arbitrary. Used when it is needed.
    defaultAnnouncementDescriptionProp = Arbitrary. Used when it is needed.
*/
import React, { useState, useEffect } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import tr from 'date-fns/locale/tr';
import en from 'date-fns/locale/en-GB';
import { Flip, toast } from 'react-toastify';
import moment from 'moment';
import { Base64 } from 'js-base64';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import {
    ACTIVE_LABEL, announcementMessages, CLOSE_BUTTON, ANNOUNCER_HEADER,
    START_DATE_LABEL, TIME_LABEL, END_DATE_LABEL, SAVE_BUTTON, UPDATE_BUTTON,
    TARGET_HEADER, GROUP_LABEL
} from '../../utils/UIMessages';
import { BULLHORN_ICON, X_ICON, QUESTION_CIRCLE_ICON } from '../../utils/UiIcons';
import {
    createAnnouncement,
    updateAnnouncement,
    getGroupList,
    getUserGroups
} from '../../api/apiCalls';
import { PrivilegeConstants } from '../../utils/PrivilegeConstants';
import {
    DATETIME_FORMAT,
    POPUP_POSITIONS,
    ANNOUNCEMENT_CONSTANTS,
    YEARLY_DATE_FORMAT,
    HOUR_FORMAT,
} from '../../utils/Constants';
import { showError, showSuccess } from '../../utils/ToastHelpers';
import { Button, Form, Dropdown, Input, Radio, Checkbox, Popup, Icon, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

registerLocale('tr', tr);
registerLocale('en', en);

const NewAnnouncementFormModal = ({
    openModalProp = false,
    updatableAnnouncementObjectProp = null,
    setOpenModalFuncProp = () => { },
    defaultAnnouncementNameProp = null,
    defaultAnnouncementDescriptionProp = null,
    updateListFunctionProp = () => { }
}) => {
    const { t } = useTranslation();

    // Props to state
    const [updatableAnnouncementId, setUpdatableAnnouncementId] = useState(null);

    //For groups info
    const [nameOfAnnFormInp, setNameOfAnnFormInp] = useState('');
    const [descriptionOfAnnFormInp, setDescriptionOfAnnFormInp] = useState('');
    const [startDateOfAnnFormInp, setStartDateOfAnnFormInp] = useState(null);
    const [endDateOfAnnFormInp, setEdDateOfAnnFormInp] = useState(null);
    const [isActiveFormInp, setIsActiveFormInp] = useState(false);
    const [isTargetPublicFormInp, setIsTargetPublicFormInp] = useState(true);
    const [isGrouplessTargetedFormInp, setIsGrouplessTargetedFormInp] = useState(false);
    const [groupsDataForDropdownFormInp, setGroupsDataForDropdownFormInp] = useState([]);
    const [currentUserGroupIds, setCurrentUserGroupIds] = useState([]);
    const [targetGroupsOfAnnouncementFormInp, setTargetGroupsOfAnnouncementFormInp] = useState([]);
    const [isDescriptionInputError, setIsDescriptionInputError] = useState(false);

    /*Pending*/
    const [pendingAnnouncementOperation, setPendingAnnouncementOperation] = useState(false);

    /*User Privileges*/
    const [privileges, setPrivileges] = useState([]);

    const filterTime = (time) => {
        const now = new Date();
        const selectedDate = new Date(time);
        return now.getTime() < selectedDate.getTime();
      };

    /*If user has Group Management Privilege, this function will be triggered to get all groups.*/
    const getAllGroups = async () => {
        setPendingAnnouncementOperation(true);
        getGroupList().then(res => {
            let data = res?.data?.map(oneGroup => {
                return {
                    key: oneGroup.id,
                    value: oneGroup.id,
                    text: oneGroup.name
                }
            });
            data.push({
                key: -1,
                value: -1,
                text: 'Groupless Users'
            })
            setGroupsDataForDropdownFormInp(data)
        }).catch(err => {
            showError('Can\'t fetch groups from server' + err?.response?.data?.message ?? err.toString());
        }).finally(() => {
            setPendingAnnouncementOperation(false)
        }
        );
    }

    /*Group Based Announcement group selection dropdown and its array list(if selected any target group)*/
    const handleDropdown = (e, data) => {
        if (updatableAnnouncementObjectProp != null && !privileges.includes(PrivilegeConstants.GROUP_MANAGEMENT)) {
            if ((groupsDataForDropdownFormInp.filter(x => x.value === -1).length > 0 && data.value.includes(-1) === false)
                || (groupsDataForDropdownFormInp.filter(x => x.value === -1).length === 0 && data.value.includes(-1) === true)) {
                return toast.error(announcementMessages().NO_RPIV_TO_EDIT_GROUPLESS_TARGET, {
                    transition: Flip,
                    autoClose: 4000
                })
            }

            //Check if there is a difference among Responsed Announcements' targets and users selection from dropdown
            const diffFromReponsedAnnTrgt = groupsDataForDropdownFormInp.filter(x => !data.value.includes(x.value));

            /*If there is a difference among Responsed Announcements' targets and users selection from dropdown,
            control that if changed target groups(via dropdown) are belonging to current user beacuse users having no Group
            Management Privilege must not re-arrange other group's target status. Only his own groups can be edited by him*/
            const guiltyValues = diffFromReponsedAnnTrgt.filter(x => !currentUserGroupIds.includes(x.value))

            /*guiltyValues.length > 0  means those guiltValues are group ID's which our current user does not belong to!!!
            Therefore, he cannot edit these guilty groups' target status.*/
            if (guiltyValues.length > 0) {
                return toast.error(announcementMessages().NO_PRIV_EDIT_TARGETNESS_OF_OTHER_GROUPS, {
                    transition: Flip,
                    autoClose: 4000
                })
            }
            setTargetGroupsOfAnnouncementFormInp(data.value);
        } else {
            setTargetGroupsOfAnnouncementFormInp(data.value);
            setIsGrouplessTargetedFormInp(data.value.includes(-1) ? true : false);
        }
    }

    const onChangeStartDate = date => setStartDateOfAnnFormInp(date);

    const onChangeEndDate = date => setEdDateOfAnnFormInp(date)

    const cleanAnnouncementInputs = () => {
        setUpdatableAnnouncementId('');
        setNameOfAnnFormInp('');
        setDescriptionOfAnnFormInp('');
        setIsActiveFormInp(false);
        setStartDateOfAnnFormInp(null);
        setEdDateOfAnnFormInp(null);
        setIsTargetPublicFormInp(true);
        setIsGrouplessTargetedFormInp(false);
        setTargetGroupsOfAnnouncementFormInp([]);
        setOpenModalFuncProp(false);
        setUpdatableAnnouncementId(null);
    }

    const saveAndUpdateButtonDisableCheck = () => {
        return (!nameOfAnnFormInp?.trim() || !descriptionOfAnnFormInp?.trim() || !startDateOfAnnFormInp ||
            !endDateOfAnnFormInp || !isActiveFormInp === undefined || pendingAnnouncementOperation ||
            (!isTargetPublicFormInp && targetGroupsOfAnnouncementFormInp?.length === 0));
    }

    /*When user enters redundant new lines (\n) into Announcement Description or Name ad input.*/
    const inputErrorVisibilitySetter = () => {
        setIsDescriptionInputError(true);
        setTimeout(() => {
            setIsDescriptionInputError(false);
        }, 3000)
    }

    const createNewAnnouncement = () => {
        //For loading and disable button visibility.
        setPendingAnnouncementOperation(true);

        createAnnouncement({
            'name': nameOfAnnFormInp,
            'description': descriptionOfAnnFormInp,
            'startDate': moment(startDateOfAnnFormInp).format(DATETIME_FORMAT),
            'endDate': moment(endDateOfAnnFormInp).format(DATETIME_FORMAT),
            'active': isActiveFormInp,
            'publicAnnouncement': isTargetPublicFormInp,
            'announcementTargetGroups': (isGrouplessTargetedFormInp && targetGroupsOfAnnouncementFormInp.length === 1) ? [] : targetGroupsOfAnnouncementFormInp,
            'grouplessUsersTargeted': isGrouplessTargetedFormInp
        })
            .then(() => {
                //Renew In-Process Announcements Tab
                // getInProcessAnnouncementsFunc();
                //Renew History Tab
                // getAnnouncementHistoryFunc();
                showSuccess(announcementMessages().NEW_ANNOUNCEMENT_SUCCESS);
                toast.success(announcementMessages().OPERATION_WILL_BE_VISIBLE_IN_5_MINUTES, {
                    transition: Flip,
                    autoClose: 5000
                })
                setOpenModalFuncProp(false);
            })
            .then(() => {
                //Renew InProgress Announcements' Tab Pane
                updateListFunctionProp();
                setTargetGroupsOfAnnouncementFormInp([]);
            })
            .catch(e => {
                showError(t(e.response?.data?.message) || announcementMessages().NEW_ANNOUNCEMENT_ERROR)
            })
            .finally(() => {
                setPendingAnnouncementOperation(false);
            });
    }

    const updateAnnouncementFunc = id => {
        setPendingAnnouncementOperation(true);

        updateAnnouncement(id, {
            'name': nameOfAnnFormInp,
            'description': descriptionOfAnnFormInp,
            'startDate': moment(startDateOfAnnFormInp).format(DATETIME_FORMAT),
            'endDate': moment(endDateOfAnnFormInp).format(DATETIME_FORMAT),
            'active': isActiveFormInp,
            'publicAnnouncement': isTargetPublicFormInp,
            'announcementTargetGroups': (isGrouplessTargetedFormInp && targetGroupsOfAnnouncementFormInp.length === 1) ? [] : targetGroupsOfAnnouncementFormInp,
            'grouplessUsersTargeted': isGrouplessTargetedFormInp
        })
            .then(() => {
                //Renew In-Process Announcements Tab
                // getInProcessAnnouncementsFunc();
                //Renew History Tab
                // getAnnouncementHistoryFunc();
                showSuccess(announcementMessages().UPDATE_SUCCESSFULL);
                toast.success(announcementMessages().OPERATION_WILL_BE_VISIBLE_IN_5_MINUTES, {
                    transition: Flip,
                    autoClose: 5000
                })
                setOpenModalFuncProp(false);
            })
            .then(() => {
                //Renew InProgress Announcements' Tab Pane
                updateListFunctionProp();
                // getPublishingAllAnnouncementsFunc();
                cleanAnnouncementInputs('close');
            })
            .catch(e => {
                showError(t(e.response?.data?.message) || announcementMessages().UPDATE_ERROR);
            })
            .finally(() => {
                setPendingAnnouncementOperation(false);
            })
    }

    useEffect(() => {
        //This useEffect is for updation operations.
        if (updatableAnnouncementObjectProp != null) {
            setUpdatableAnnouncementId(updatableAnnouncementObjectProp?.id)
            setNameOfAnnFormInp(updatableAnnouncementObjectProp.name);
            setDescriptionOfAnnFormInp(updatableAnnouncementObjectProp.description);
            setIsActiveFormInp(updatableAnnouncementObjectProp.active);
            setStartDateOfAnnFormInp(new Date(updatableAnnouncementObjectProp.startDate));
            setEdDateOfAnnFormInp(new Date(updatableAnnouncementObjectProp.endDate));
            setIsTargetPublicFormInp(updatableAnnouncementObjectProp.publicAnnouncement);

            /* This part is required for Update process. In Update process, non-allowed users
            cannot remove other groups from announcement target. Therefore, he is only allowed to
            edit target groups he belongs to.*/
            if (!updatableAnnouncementObjectProp.publicAnnouncement &&
                (updatableAnnouncementObjectProp?.announcementTargetGroups != null && updatableAnnouncementObjectProp?.announcementTargetGroups?.length > 0)) {
                if (privileges.includes(PrivilegeConstants.GROUP_MANAGEMENT)) {
                    setTargetGroupsOfAnnouncementFormInp(updatableAnnouncementObjectProp?.announcementTargetGroups
                        .map(oneGroup => oneGroup.id));
                } else {
                    /*
                    User not having Group Management Priv cannot edit an announcemnet's other target groups.
                    Therefore algorithm works like this:
                    When User clicks edit button and responsedAnnouncement's target groups array is set to dropdown.
                    User can edit only his own groups' name, not other groups. This control is provided in 
                    "handleDropdown()" method.*/
                    let targetGroupsOfResponsedAnnouncement = updatableAnnouncementObjectProp.announcementTargetGroups.map(oneGroup => {
                        return {
                            key: oneGroup.id,
                            value: oneGroup.id,
                            text: oneGroup.name
                        }
                    });

                    //If Response Announcement is groupless targeted, push this opiton to dropdown as well.
                    if (updatableAnnouncementObjectProp?.grouplessUsersTargeted) {
                        targetGroupsOfResponsedAnnouncement.push({
                            key: -1,
                            value: -1,
                            text: announcementMessages().GROUPLESS_LABEL
                        })
                    }
                    //Set dropdown data
                    setGroupsDataForDropdownFormInp(targetGroupsOfResponsedAnnouncement);

                    //Now, for choice box of dropdown, set which group is targeted by the reponse announcement.
                    setTargetGroupsOfAnnouncementFormInp(updatableAnnouncementObjectProp?.announcementTargetGroups
                        .map(oneGroup => oneGroup.id));
                }
            }
            if (updatableAnnouncementObjectProp?.grouplessUsersTargeted) {
                setTargetGroupsOfAnnouncementFormInp(prevState => [...prevState, -1]);
            }
        }
    }, []);

    useEffect(() => {
        if (openModalProp) {
            if (localStorage.getItem('privileges')) {
                setPrivileges(Base64.decode(
                    localStorage.getItem('privileges')
                ).split(','));
            } else {
                showError('Privileges could not be gotten!')
            }
            defaultAnnouncementNameProp && setNameOfAnnFormInp(defaultAnnouncementNameProp);
            defaultAnnouncementDescriptionProp && setDescriptionOfAnnFormInp(defaultAnnouncementDescriptionProp);
        }
    }, [openModalProp]);

    /*Bring user details and so his belonging group(s) information*/
    useEffect(() => {
        if (openModalProp) {
            if (privileges?.includes(PrivilegeConstants.ANNOUNCEMENT_MANAGEMENT)) {
                if (privileges?.includes(PrivilegeConstants.GROUP_MANAGEMENT)) {
                    /*if user has GROUP MANAGEMENT privilege, this means the user can send
                    announcement to all groups.*/
                    getAllGroups();
                } else {
                    /*If Announcement From opens for Update, no need to re-fill dropdown data here because it is filled
                    when "edit" button is clicked with responsedAnnouncementTargetGroups array.*/
                    setPendingAnnouncementOperation(true);
                    getUserGroups().then(res => {
                        if (updatableAnnouncementObjectProp == null) {
                            setGroupsDataForDropdownFormInp(
                                res.data?.map(oneGroup => {
                                    return {
                                        key: oneGroup.id,
                                        value: oneGroup.id,
                                        text: oneGroup.name
                                    }
                                })
                            );
                        }

                        /*This will be used during update process by user not having Group Man Priv.
                        He will only be able to edit his own groups targeted status, not other groups.*/
                        setCurrentUserGroupIds(res.data?.map(oneGroup => oneGroup.id));
                    })
                        .catch(e => showError(announcementMessages().USER_GROUPS_COULD_NOT_BE_FETCHED + e.response?.data?.message))
                        .finally(() => setPendingAnnouncementOperation(false))
                }
            }
        }

    }, [privileges]);

    return (<>
        <Modal
            style={{ width: '60%' }}
            open={openModalProp}
            closeIcon
            closeOnDimmerClick={true}
            onClose={() => cleanAnnouncementInputs()}>
            <Modal.Header className='text-align-c-imp'>
                <div style={{ fontSize: '25px' }}>
                    <Icon name={BULLHORN_ICON} />
                    {updatableAnnouncementObjectProp != null
                        ? <>
                            {announcementMessages().UPDATE_ANNOUNCEMENT}
                            <br />
                            <p style={{ color: 'grey', fontSize: '14px' }}>({ANNOUNCER_HEADER()}: {updatableAnnouncementObjectProp?.announcerUsername})</p>
                        </>
                        : announcementMessages().CREATE_NEW_ANNOUNCEMENT}
                </div>

            </Modal.Header>
            <Modal.Content >
                <Form style={{ marginTop: '10px' }}>
                    <div className='dsp-flx-jstfy-contnt-space-btw'>
                        <div className='announcement-form-left-right-parts'>
                            <Form.Field>
                                <div className='announcement-max-word-limit'>
                                    <b>{announcementMessages().NAME}<span className='form-inputs-required-sign'>*</span></b>
                                    <div style={nameOfAnnFormInp?.length === ANNOUNCEMENT_CONSTANTS.TOP_LIMIT_ANNOUNCEMENT_NAME
                                        ? { color: 'red' }
                                        : { color: 'black' }}>
                                        {nameOfAnnFormInp?.length}/{ANNOUNCEMENT_CONSTANTS.TOP_LIMIT_ANNOUNCEMENT_NAME}
                                    </div>
                                </div>
                                <Input
                                    placeholder={announcementMessages().NAME_PLACEHOLDER}
                                    maxLength={70}
                                    value={nameOfAnnFormInp}
                                    onChange={e => setNameOfAnnFormInp(e.target.value)}
                                />
                            </Form.Field>
                            <Form.Field>
                                <div className='announcement-max-word-limit'>
                                    <b>{announcementMessages().DESCRIPTION}<span className='form-inputs-required-sign'>*</span></b>
                                    <div style={descriptionOfAnnFormInp?.length === ANNOUNCEMENT_CONSTANTS.TOP_LIMIT_ANN_DESC
                                        ? { color: 'red' } : { color: 'black' }}>
                                        {descriptionOfAnnFormInp?.length}/{ANNOUNCEMENT_CONSTANTS.TOP_LIMIT_ANN_DESC}
                                    </div>
                                </div>
                                <Form.TextArea
                                    maxLength={500}
                                    style={{ height: '116px' }}
                                    placeholder={announcementMessages().DESCRIPTION_PLACEHOLDER}
                                    value={descriptionOfAnnFormInp}
                                    error={isDescriptionInputError && {
                                        content: t(`You cannot put more than ${ANNOUNCEMENT_CONSTANTS.TOP_LIMIT_ANN_DESCRIPTION_LINE_BREAK} new lines.`),
                                        pointing: 'above',
                                    }}
                                    onChange={e => {
                                        (e.target.value.match(/\n/g) || []).length < 8 ? setDescriptionOfAnnFormInp(e.target.value) : inputErrorVisibilitySetter();
                                    }}
                                />
                            </Form.Field>
                        </div>

                        <div className='announcement-form-left-right-parts'>
                            <div id='div-announcement-form-date-pickers' className='dsp-flx-jstfy-contnt-space-btw'>
                                <div className='each-date-picker-announcement-form'>
                                    <b>{START_DATE_LABEL()}<span className='form-inputs-required-sign'>*</span></b>
                                    <DatePicker
                                        id='startDate'
                                        //prevent manual input injection
                                        onKeyDown={e => e.preventDefault()}
                                        selected={startDateOfAnnFormInp}
                                        onChange={date => {
                                            onChangeStartDate(date)
                                            setEdDateOfAnnFormInp(null);
                                        }}
                                        selectsStart
                                        //today's date will be the minimum date for startDate of the announcement
                                        minDate={new Date()}
                                        startDate={startDateOfAnnFormInp}
                                        endDate={endDateOfAnnFormInp}
                                        filterTime={filterTime}
                                        dateFormat={`${YEARLY_DATE_FORMAT} ${HOUR_FORMAT}`}
                                        showTimeSelect
                                        timeFormat={HOUR_FORMAT}
                                        timeIntervals={15}
                                        timeCaption={TIME_LABEL()}
                                        locale={localStorage.getItem('lang') === 'null' ? 'en' : localStorage.getItem('lang')}
                                    />
                                </div>
                                <div className='each-date-picker-announcement-form'>
                                    <b>{END_DATE_LABEL()}<span className='form-inputs-required-sign'>*</span></b>
                                    <DatePicker
                                        id='endDate'
                                        //prevent manual input
                                        onKeyDown={e => e.preventDefault()}
                                        selected={endDateOfAnnFormInp}
                                        onChange={date => onChangeEndDate(date)}
                                        selectsEnd
                                        startDate={startDateOfAnnFormInp}
                                        endDate={endDateOfAnnFormInp}
                                        minDate={startDateOfAnnFormInp != null
                                            ? startDateOfAnnFormInp : new Date()}
                                        filterTime={filterTime}
                                        dateFormat={`${YEARLY_DATE_FORMAT} ${HOUR_FORMAT}`}
                                        showTimeSelect
                                        timeFormat={HOUR_FORMAT}
                                        timeIntervals={15}
                                        timeCaption={TIME_LABEL()}
                                        locale={localStorage.getItem('lang') === 'null' ? 'en' : localStorage.getItem('lang')}
                                    />
                                </div>
                            </div>

                            <div id='ann-form-checkbox-options'>
                                <div id='ann-form-target-group-checkboxes-div'>
                                    <b>{TARGET_HEADER()}<span className='form-inputs-required-sign'>*</span></b>
                                    <div className='ann-form-manual-segment dsp-flx-jstfy-contnt-space-evenly ann-target-segment-customized'>
                                        <Radio
                                            className='overflow-hidden'
                                            name='annTargetRadioGroup'
                                            label={announcementMessages().PUBLIC_LABEL}
                                            checked={isTargetPublicFormInp}
                                            onClick={() => {
                                                setIsTargetPublicFormInp(true);
                                                setTargetGroupsOfAnnouncementFormInp([]);
                                                setIsGrouplessTargetedFormInp(false);
                                            }}
                                        />
                                        <Radio
                                            className='overflow-hidden'
                                            label={GROUP_LABEL()}
                                            name='annTargetRadioGroup'
                                            checked={!isTargetPublicFormInp}
                                            onClick={() => setIsTargetPublicFormInp(false)}
                                        />

                                    </div>
                                </div>
                                <div id='ann-form-status-checkbox-div'>
                                    <b>{ACTIVE_LABEL()}</b>
                                    <div className='ann-form-manual-segment dsp-flx-jstfy-contnt-space-evenly'>
                                        <Checkbox
                                            toggle
                                            id='new-announcement-activation-slider'
                                            checked={isActiveFormInp}
                                            onChange={(e, data) => setIsActiveFormInp(data.checked)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div id='new-announcement-target-group-dropdown-outer'>
                                <b style={isTargetPublicFormInp ? { opacity: '0.45' } : { opacity: '1.0' }}>{announcementMessages().TARGET_GROUP_LABEL}</b>
                                {!privileges.includes(PrivilegeConstants.GROUP_MANAGEMENT) &&
                                    <Popup
                                        disabled={isTargetPublicFormInp}
                                        position={POPUP_POSITIONS.BOTTOM_RIGHT}
                                        content={announcementMessages().POPUP_WARNING_ONLY_YOUR_GROUPS_CAN_BE_SELECTED}
                                        trigger={<Icon name={QUESTION_CIRCLE_ICON} />}>
                                    </Popup>
                                }
                                <Dropdown
                                    disabled={isTargetPublicFormInp}
                                    fluid
                                    search
                                    multiple
                                    selection
                                    clearable
                                    placeholder={announcementMessages().PLACEHOLDER_SELECT_TARGET_GROUPS}
                                    name={'targetGroup'}
                                    value={targetGroupsOfAnnouncementFormInp}
                                    options={groupsDataForDropdownFormInp}
                                    onChange={handleDropdown}
                                />
                            </div>
                        </div>
                    </div>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button color='red'
                    disabled={pendingAnnouncementOperation}
                    loading={pendingAnnouncementOperation}
                    onClick={() => cleanAnnouncementInputs()}
                >
                    <Icon name={X_ICON} />{CLOSE_BUTTON()}
                </Button>
                <Button
                    color='green'
                    onClick={() => {
                        if (updatableAnnouncementObjectProp != null) {
                            updateAnnouncementFunc(updatableAnnouncementId)
                        } else {
                            createNewAnnouncement();
                        }
                    }}
                    disabled={saveAndUpdateButtonDisableCheck()}
                    loading={pendingAnnouncementOperation}
                >
                    <><Icon name={BULLHORN_ICON} />{updatableAnnouncementObjectProp != null ? UPDATE_BUTTON() : SAVE_BUTTON()}</>
                </Button>
            </Modal.Actions>
        </Modal>
    </>);
}

export default NewAnnouncementFormModal;