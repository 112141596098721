import React from 'react';
import { Button, Dimmer, Divider, Form, Grid, Header, Image, Input, Loader, Message , Popup, Segment} from 'semantic-ui-react'
import axios from 'axios';
import LicenseModal from '../../components/LicenseModal';
import TwoFactorAuthenticationModal from '../../components/TwoFactorAuthenticationModal';
import { withTranslation } from 'react-i18next';
import Cookies from 'js-cookie'
import { clearLocalStorage, setSessionValuesFromToken, getPrivileges } from '../../utils/Methods';
import { PrivilegeConstants } from '../../utils/PrivilegeConstants';
import { showError } from '../../utils/ToastHelpers';
import {
    getCaptchaLoader,
    getCaptchaInitial,
    isViewedVersionPromoterModal,
    getActiveOAuth2List,
    getLoginUser,
    refreshAccessToken
} from '../../api/apiCalls'
import { encryptData } from '../../utils/PublicKeyHelper';
import { CAPTCHA_OR_2FA_RELOAD_DURATION, POPUP_POSITIONS, TWO_FA_MESSAGE_DISPLAY_DURATION } from '../../utils/Constants';
import { LOADING, loginMessages, PASSWORD_HEADER, promoteFeatureMessages, USERNAME_HEADER } from '../../utils/UIMessages';
import { LOCK_ICON, USER_ICON } from '../../utils/UiIcons';
import packageJSON from '../../../package.json';

import _ from 'lodash';
import { Flip, toast } from 'react-toastify';

class LoginPage extends React.Component {

    state = {
        email: '',
        hideMessage: true,
        privileges: [],
        licenseModalVisibility: false,
        twoFactorModalVisibility: false,
        remember: false,
        isBlocked: false,
        isCaptcha: false,
        captchaCode: undefined,
        captchaId: undefined,
        captchaImage: undefined,
        failMessage: '',
        twoFactorVerificationCode: '',
        twoFactorInfoMessage: '',
        hideTwoFactorMessage: true,
        pendingRequest: false,
        oAuth2List: [],
        loadingOAuth2Segment: false,
        providerId:undefined,
        oaut2LoginError:''
    };

    componentDidMount() {
        clearLocalStorage();
        Cookies.remove('JSESSIONID');
        //axios.get('/api/captcha-initial')
        getCaptchaInitial().then(response => {
            this.setState({ isCaptcha: response.data.captcha, isBlocked: response.data.blocked }, () => {
                if (response.data.captcha && !response.data.blocked) {
                    this.getCaptcha();
                }
            })
        });
        this.getOAuth2List();
        const params = new URLSearchParams(this.props.location?.search);
        const oAuth2RefreshToken = params.get('token');
        const error = params.get('error')
        if (error) {
            this.setState({ oaut2LoginError: error, loadingOAuth2Segment: false }, () => {
                toast.error(this.props.t(this.state.oaut2LoginError), {
                    transition: Flip,
                    autoClose: false
                })
                params.delete('error');
                this.props.history?.replace({ search: params.toString() })
            });
        }
        if (oAuth2RefreshToken) {
            this.setState({loadingOAuth2Segment: true })
            refreshAccessToken(oAuth2RefreshToken).then(res=> {
                const { accessToken, refreshToken } = res.data;
                if(accessToken){
                    getLoginUser({"Authorization":"Bearer " + accessToken}).then(res2 => {
                        setSessionValuesFromToken(accessToken, refreshToken, res2.data);
                        this.setState({ loadingOAuth2Segment:false }, () => {
                            localStorage.setItem('pathSaved', 'deviceList');
                            this.props.history.push('/device-list')
                            window.location.reload();
                        });
                    }).catch(err => {
                        this.setState({ loadingOAuth2Segment: false });
                        toast.error(err?.response?.data?.message ?? err.toString(),{
                            transition: Flip,
                            autoClose: false
                        });
                    })
                }
            }).catch(err => {
                this.setState({ loadingOAuth2Segment: false });
                toast.error(err?.response?.data?.message ?? err.toString(), {
                    transition: Flip,
                    autoClose: false
                });
            })
        }
        
    }

    getOAuth2List = () => {
        getActiveOAuth2List().then(res => {
            this.setState({ oAuth2List: res.data })
        }).catch(err => {
            toast.error(loginMessages().NOT_FETCH_ACTIVE_OAUTH2_MESSAGE + err?.response?.data?.message ?? err.toString(), {
                transition: Flip,
                autoClose: false
            });
        })
    }

    getCaptcha = () => {
        //axios.get('/api/captcha-loader')
        getCaptchaLoader().then(res => {
            this.setState({ captchaImage: res.data.image, captchaId: res.data.id })
        }).catch(err => {
            toast.error(loginMessages().NOT_LOADED_CAPTCHA_ERROR_MESSAGE+ err?.response?.data?.message ?? err.toString(), {
                transition: Flip,
                autoClose: false
            });
        });
    }

    handleChange = e => {
        const { name, value } = e.target;
        this.setState({ [name]: value.trim() })
    }

    onChangeTFPin = value => {
        this.setState({
            'twoFactorVerificationCode': value
        })
    }

    versionPromoterSettings = reloadWindow => {
        isViewedVersionPromoterModal(packageJSON.version.replace(/(\d+\.\d+.\d+)[-\\.].*/, "$1")).then(res => {
            localStorage.setItem('showVersionPromoterModal', res.data);
        }).catch(err => showError(promoteFeatureMessages().ERR_MODAL_STATUS + '!' + err?.response?.data?.message ?? err.toString()))
            .finally(() => reloadWindow());
    }

    disabledProvider = oAuth2 => this.state.loadingOAuth2Segment && this.state.providerId !==  oAuth2.id;
    
    loadingProvider = oAuth2 => this.state.loadingOAuth2Segment && this.state.providerId ===  oAuth2.id;

    onProviderIconClicked = oAuth2 => {
        this.setState({ loadingOAuth2Segment: true, providerId: oAuth2.id })
    }

    handleSubmit = async e => {
        e.preventDefault();
        this.setState({ pendingRequest: true });
        const encryptedUsername = await encryptData(this.state.email);
        const encryptedPassword = await encryptData(this.state.password);

        const data = {
            username: encryptedUsername,
            password: encryptedPassword
        };
        const params = {
            captchaCode: this.state.captchaCode,
            captchaId: this.state.captchaId,
            twoFactorVerificationCode: this.state.twoFactorVerificationCode,
            resetSecret: e.target.id === 'MFAReset'
        };
        axios.post('/api/login', data, { params }).then(res => {
            const { accessToken, refreshToken, user, twoFactor, licenseState, firstLogin} = res.data;
            if (twoFactor) {
                this.setState({
                    isCaptcha: res.data.captcha,
                    isBlocked: res.data.blocked,
                    twoFactorModalVisibility: twoFactor,
                    twoFactorInfoMessage: res.data.message,
                    hideTwoFactorMessage: res.data.message === ''
                }, () => {
                    setTimeout(() => {
                        this.setState({ hideTwoFactorMessage: true })
                    }, TWO_FA_MESSAGE_DISPLAY_DURATION)
                })
            } else if (licenseState) {
                setSessionValuesFromToken(accessToken, refreshToken, user);
                localStorage.setItem('pathSaved', 'deviceList');
                const privileges = getPrivileges();
                privileges.includes(PrivilegeConstants.DEVICE_LIST) ? this.props.history.push('/device-list') : this.props.history.push('/me')
                this.versionPromoterSettings(() => window.location.reload());
                localStorage.setItem('showActiveGroupModal', firstLogin);
            } else {
                localStorage.setItem('accessToken', accessToken)
                localStorage.setItem('refreshToken', refreshToken);
                this.setState({ licenseModalVisibility: true })
            }


        }).catch(err => {
            this.setState({ captchaCode: '', captchaId: '' })
            if (err.response?.data?.twoFactor) {
                this.setState({
                    isCaptcha: err.response.data.captcha,
                    isBlocked: err.response.data.blocked,
                    twoFactorModalVisibility: err.response.data.twoFactor,
                    twoFactorInfoMessage: err.response.data.message,
                    failMessage: err.response.data.message,
                    hideTwoFactorMessage: (!(err.response.data.twoFactor === true && err.response.data.message !== '')),
                    hideMessage: (!(err.response.data.twoFactor === false && err.response.data.message !== ''))
                }, () => setTimeout(() => this.setState({ hideMessage: true }), CAPTCHA_OR_2FA_RELOAD_DURATION))
            } else {
                if (err.response.data.captcha && !err.response.data.blocked) {
                    this.getCaptcha();
                }
                this.setState({
                    hideMessage: false,
                    isCaptcha: err.response.data.captcha,
                    isBlocked: err.response.data.blocked,
                    failMessage: err.response.data.message
                }, () => setTimeout(() => this.setState({ hideMessage: true }), CAPTCHA_OR_2FA_RELOAD_DURATION))
            }
        })
            .finally(() => this.setState({ pendingRequest: false }));
    }


    render() {
        return (
            <>
                {this.state.loadingOAuth2Segment ? <Dimmer active>
                    <Loader active size='large' indeterminate>
                        <div id='loaderText'>{LOADING()}</div>
                    </Loader>
                </Dimmer> :
                    <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle' >
                        <Grid.Column style={{ maxWidth: 450 }}>
                            <Image src='/images/visiumFarmLogo.png' centered style={{ height: 120 }} />
                            {!this.state.isBlocked && <>
                                <Form size='large' error>
                                    <div style={{ paddingTop: 0, paddingBottom: 20 }}>
                                        <Header as='h3' style={{ color: '#172B4D' }} textAlign='center'>
                                            {loginMessages().LOGIN_HEADER}
                                        </Header>
                                        <Form.Field>
                                            <Input size='large' fluid name='email' icon={USER_ICON} value={this.state.email.trim()} iconPosition='left'
                                                onChange={this.handleChange} placeholder={USERNAME_HEADER()} />
                                        </Form.Field>
    
                                        <Form.Field>
                                            <Input fluid size='large'
                                                icon={LOCK_ICON}
                                                iconPosition='left' name='password' type='password'
                                                onChange={this.handleChange} placeholder={PASSWORD_HEADER()} />
                                        </Form.Field>
                                        {this.state.isCaptcha &&
                                            <>
                                                <Form.Field className='captcha-field'>
                                                    <Image src={this.state.captchaImage} />
                                                </Form.Field>
                                                <Form.Field>
                                                    <Input
                                                        fluid
                                                        placeholder={loginMessages().CAPTCHA_IMAGE_PLACEHOLDER}
                                                        value={this.state.captchaCode}
                                                        id='captchaUserInput'
                                                        type='text'
                                                        name='captchaCode'
                                                        onChange={this.handleChange}
                                                    />
                                                </Form.Field>
                                            </>
                                        }
                                        <Button color='orange' fluid size='huge' onClick={this.handleSubmit}
                                            loading={this.state.pendingRequest}
                                            disabled={this.state.pendingRequest || !this.state.email || !this.state.password || (this.state.isCaptcha && !this.state.captchaCode)}>
                                            {loginMessages().LOGIN_BUTTON}
                                        </Button>
                                    </div>
                                </Form>
                                <Message error hidden={this.state.hideMessage}>
                                    {this.props.t(this.state.failMessage)}
                                </Message>
                                <Image src='/images/netaslogo.png' centered id='netas-logo' />
                                {!_.isEmpty(this.state.oAuth2List) &&
                                    <>
                                        <Divider />
                                        <Segment id='oauth2-login-segment'>
                                            <Header as='h3' id='sign-in-with-label' textAlign='center'>
                                                {loginMessages().SIGN_IN_WITH_LABEL}
                                            </Header>
                                            <div id='oauth2-login-navigator'>
                                                {this.state.oAuth2List.map((oAuth2, i) =>
                                                    <Popup key={i}
                                                        trigger={
                                                            <Image
                                                                src={'data:image/png;base64,' + oAuth2.iconImage}
                                                                alt='iconImage'
                                                                as='a'
                                                                className='oauth2-logo'
                                                                disabled={this.disabledProvider(oAuth2)}
                                                                hidden={this.disabledProvider(oAuth2)}
                                                                onClick={() => this.onProviderIconClicked(oAuth2)}
                                                                href={(window.location.host.includes('localhost:') || process.env.NODE_ENV === 'development') ?
                                                                    'http://localhost:8080/api/v1/oauth2/authorization/' + oAuth2.registrationId : '/api/v1/oauth2/authorization/' + oAuth2.registrationId}
                                                            />}
                                                        content={oAuth2.providerTypes}
                                                        basic
                                                        disabled={this.disabledProvider(oAuth2)}
                                                        position={POPUP_POSITIONS.BOTTOM_CENTER}
                                                        on='hover'
                                                    />
                                                )}
                                            </div>
                                        </Segment>
                                    </>
                                }
                            </>}
                            <Message error hidden={!this.state.isBlocked}>
                                {this.props.t("Login Blocked. Please Try Again Later")}
                            </Message>
                        </Grid.Column>
                    </Grid>

                }
                <LicenseModal licenseModalVisibility={this.state.licenseModalVisibility}
                    email={this.state.email}
                    password={this.state.password}
                    onClose={() => {
                        this.setState({ licenseModalVisibility: false })
                    }} />
                <TwoFactorAuthenticationModal
                    twoFactorModalVisibility={this.state.twoFactorModalVisibility}
                    twoFactorVerificationCode={this.state.twoFactorVerificationCode}
                    hideTwoFactorMessage={this.state.hideTwoFactorMessage}
                    twoFactorInfoMessage={this.state.twoFactorInfoMessage}
                    onClose={() => { this.setState({ twoFactorModalVisibility: false, twoFactorVerificationCode: '' }) }}
                    onChangeTFPin={this.onChangeTFPin}
                    handleSubmit={this.handleSubmit} />
            </>
        )
    }
}

export default withTranslation()(LoginPage)
