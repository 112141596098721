import FileUploadModal from '../../components/FileUploadModal';
import React, { useState } from 'react';
import { onChangeFileInput } from '../../utils/Methods';
import { uploadInjectionResource } from '../../api/apiCalls';
import { showError, showSuccess } from '../../utils/ToastHelpers';
import {
  FAILED_ERROR_MESSAGE,
  HARDWARE_SIMULATION_RES_DESCRIPTION,
  HARDWARE_SIMULATION_RES_HEADER,
  libraryMessages
} from "../../utils/UIMessages";
import axios from 'axios';

const SimulationResourceUpload = (props) => {
  const [resFile, setResFile] = useState(null);
  const [name, setName] = useState('');
  const [loadingFileUpload, setLoadingFileUpload] = useState(false);
  const [fileExtensionErrorMsg, setFileExtensionErrorMessage] = useState('');
  const extensions = ['jpg', 'jpeg'];

  const onChangeFileUpload = (e) => {
    const file = e.target.files[0];
    onChangeFileInput(
      file,
      extensions,
      (errorMsg) => setFileExtensionErrorMessage(errorMsg),
      (resFile) => setResFile(resFile),
      (fileName) => setName(fileName)
    );
  };

  const clearStates = () => {
    setName('');
    setResFile(null);
    setLoadingFileUpload(false);
    setFileExtensionErrorMessage('');
  };

  const closeModal = () => {
    props.handleCloseModal();
    clearStates();
  };


  const handleUploadFile = async () => {
    setLoadingFileUpload(true);
    const formData = new FormData();
    formData.append('file', resFile.get('file'));

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      },
      params: {
        udId:props.deviceUdId,
        resourceType:'IMAGE'
      }
    };

    try {
      await uploadInjectionResource(formData, config);
      showSuccess(libraryMessages().SHOW_SUCCESS_FILE_UPLOAD, 1000);
    } catch (err) {
      if (axios.isCancel(err)) {
        showError();
      } else {
        showError(`${FAILED_ERROR_MESSAGE()} ${err?.response?.data?.message ?? err.toString()}`);
      }
    } finally {
      closeModal();
    }
  };

  return (
    <>
      <FileUploadModal
        fileUploadModalVisibility={props.visibility}
        handleCloseFileUploadModal={closeModal}
        name={name}
        onChangeName={(value) => setName(value)}
        onChangeFileUpload={onChangeFileUpload}
        handleUploadFile={handleUploadFile}
        loadingUploadButton={loadingFileUpload}
        uploadFile={resFile}
        fileExtensionErrorMsg={fileExtensionErrorMsg}
        header={HARDWARE_SIMULATION_RES_HEADER()}
        isContainFileName={false}
        description={HARDWARE_SIMULATION_RES_DESCRIPTION()}
        acceptableExtensions={extensions}
      />
    </>
  );
};
export default SimulationResourceUpload;
