import axios from 'axios';

export const TEST_SESSION_REPORT_URL = '/api/v1/test-sessions';
export const DEVICE_USAGE_URL = '/api/v1/reports/raw-data';
export const DEVICE_USAGE_URL_V2 = '/api/v2/reports/raw-data';

//DEVICE CATEGORY
export const createDeviceCategory = name => axios.post('/api/v1/device-categories', {name});
export const getDeviceCategories = () => axios.get('/api/v1/device-categories');
export const exportDevicesInformation = () => axios.get('/api/v1/devices/export', {responseType: 'blob'});
export const deleteCategory = categoryIds => axios.delete(`/api/v1/device-categories`, { params: { categoryIds } })


//AGENT
export const getAgentConfigurationList = () => axios.get('/api/v1/agents/config/all');
export const deleteAgentConfig = id => axios.delete(`/api/v1/agents/${id}/config`);
export const updateAgentConfig = (id,data) => axios.put(`/api/v1/agents/${id}/config`, data);
export const createAgentConfig = data => axios.post(`/api/v1/agents/config`, data);
export const getAgentListSummary = () => axios.get('/api/v1/agents/summary');

//APPS RESTRICTION
export const getWhiteList = () => axios.get('/api/v1/app/restrictions');
export const updateAppRestriction = (id,data) => axios.put(`/api/v1/app/restrictions/${id}`, data);
export const createAppRestriction = data => axios.post(`/api/v1/app/restrictions`, data);
export const deleteAppRestriction = id => axios.delete(`/api/v1/app/restrictions/${id}`);


//ANNOUNCEMENT
export const getLiveAnnouncements = () => axios.get('/api/v1/announcements/live');
export const getLiveAndUnreadAnnouncements = () => axios.get('/api/v1/announcements/live/unread');
export const getNextAnnouncements = () => axios.get('/api/v1/announcements/next');
export const getPastAnnouncements = params => axios.get('/api/v1/announcements/past',{params});
export const getAnnouncement = id => axios.get(`/api/v1/announcements/${id}`);
export const createAnnouncement = data => axios.post('/api/v1/announcements',data);
export const deleteAnnouncement = id => axios.delete(`/api/v1/announcements/${id}`);
export const updateAnnouncement = (id,data) => axios.put(`/api/v1/announcements/${id}`,data);
export const markAnnouncementAsRead = id => axios.put(`/api/v1/announcements/${id}/read`);
export const markAnnouncementAsUnread = id => axios.put(`/api/v1/announcements/${id}/unread`);

//CAPTCHA LOADER

export const getCaptchaLoader = () => axios.get('/api/v1/captcha-loader');
export const getCaptchaInitial = () => axios.get('/api/v1/captcha-initial');


//DEVICE CONNECTION
export const stopManual = params =>  axios.post('/api/v1/device-connection/stop-manual', null, { params });
export const stopDevelopment = params => axios.post('/api/v1/device-connection/stop-development', null, { params });
export const getBusyDevices = () => axios.get('/api/v1/device-connection/busy-devices');


//E-MAIL CONFIG
export const getEmailConfigList = () => axios.get('/api/v1/email/config');
export const updateEmailConfig = (id,data) => axios.put(`/api/v1/email/config?emailId=${id}`, data);
export const createEmailConfig = data => axios.post(`/api/v1/email/config`, data);
export const deleteEmailConfig = id => axios.delete(`/api/v1/email/config?emailId=${id}`);
export const createTestEmailConfig = data =>axios.post('/api/v1/email/test', data);


//EMBEDDED SCREEN SHARE
export const getEmbeddedScreenShareToken = udId => axios.get(`/api/v1/embedded-screen-share/token/${udId}/infinite`);
export const generateEmbeddedScreenShareToken = data => axios.post('/api/v1/embedded-screen-share/token',data);
export const updateToken = (udId,type) => axios.put(`/api/v1/embedded-screen-share/refresh-token/${udId}/infinite/${type}`);

//FAVORITE DEVICE
export const deleteFavoriteDevice = id => axios.delete(`/api/v1/favorite-devices/${id}`);
export const createFavoriteDevice = id => axios.post(`/api/v1/favorite-devices/${id}`);


//GROUP
const GROUP_ENDPOINT_V1 = '/api/v1/groups';
export const createGroup = data => axios.post(GROUP_ENDPOINT_V1, data);
export const deleteGroup = id => axios.delete(`${GROUP_ENDPOINT_V1}/${id}`);
export const updateGroup = (id,data) => axios.put(`${GROUP_ENDPOINT_V1}/${id}`, data);
export const getGroupList = () => axios.get(GROUP_ENDPOINT_V1);
export const getReservableGroupList = () => axios.get(`${GROUP_ENDPOINT_V1}/reservable`);
export const addUsersToGroup = (id,userFormData) => axios.post(`${GROUP_ENDPOINT_V1}/${id}/users`, userFormData);
export const addReservationUsersToGroup = (id,userFormData) => axios.post(`${GROUP_ENDPOINT_V1}/${id}/reservation/users`, userFormData);
export const getAllUsersInGroup = id => axios.get(`${GROUP_ENDPOINT_V1}/${id}/users`);
export const getAllGroupsInUser = userName =>axios.get(`${GROUP_ENDPOINT_V1}/user`,{params:{userName}});
export const getReservationUsersInGroup = id => axios.get(`${GROUP_ENDPOINT_V1}/${id}/reservation/users`)
export const deleteUserInGroup = (id,userId) => axios.delete(`${GROUP_ENDPOINT_V1}/${id}/users/${userId}`);
export const deleteReservationUserInGroup = (groupId,userId) => axios.delete(`${GROUP_ENDPOINT_V1}/${groupId}/reservation/users/${userId}`)
export const addDevicesToGroup = (id,deviceFormData) => axios.post(`${GROUP_ENDPOINT_V1}/${id}/devices`, deviceFormData);
export const getAllDevicesInGroup = id => axios.get(`${GROUP_ENDPOINT_V1}/${id}/devices`);
export const deleteDeviceInGroup = (id,deviceId) => axios.delete(`${GROUP_ENDPOINT_V1}/${id}/devices/${deviceId}`);
export const addAppToGroup = (id,appFormData) => axios.post(`${GROUP_ENDPOINT_V1}/${id}/apps`, appFormData);
export const addAllAppsToGroup = (id,appFormData) =>axios.post(`${GROUP_ENDPOINT_V1}/${id}/apps`, appFormData);
export const getAllAppsInGroup = id => axios.get(`${GROUP_ENDPOINT_V1}/${id}/apps`);
export const deleteAppInGroup = (id,appId) => axios.delete(`${GROUP_ENDPOINT_V1}/${id}/apps/${appId}`);
export const removeAllAppsInGroup = (id,appIds) => axios.delete(`${GROUP_ENDPOINT_V1}/${id}/apps`,{params:{appIds}});
export const removeAllUsersInGroup = (id,userIds) => axios.delete(`${GROUP_ENDPOINT_V1}/${id}/users`,{params:{userIds}});
export const removeAllDevicesInGroup = (id,deviceIds) => axios.delete(`${GROUP_ENDPOINT_V1}/${id}/devices`,{params:{deviceIds}});
export const removeAllFilesInGroup = (id,fileIds) => axios.delete(`${GROUP_ENDPOINT_V1}/${id}/files`,{params:{fileIds}});
export const deleteFileInGroup = (id,fileId) => axios.delete(`${GROUP_ENDPOINT_V1}/${id}/files/${fileId}`);
export const addAllFilesToGroup = (id,fileFormData) =>axios.post(`${GROUP_ENDPOINT_V1}/${id}/files`, fileFormData);
export const addFileToGroup = (id,fileFormData) => axios.post(`${GROUP_ENDPOINT_V1}/${id}/files`, fileFormData);
export const getAllFilesInGroup = id => axios.get(`${GROUP_ENDPOINT_V1}/${id}/files`);
export const changeActiveGroup = id => axios.put(`${GROUP_ENDPOINT_V1}/me/active`, { }, { params: { id } });
export const getUserGroups = () => axios.get(`${GROUP_ENDPOINT_V1}/me`);

//HTTP TRACE
export const getAllApi = params => axios.get(`/api/v1/api-traces?${params || ''}`);


//LDAP
export const createLdap = data => axios.post(`/api/v1/ldaps`, data);
export const deleteLdap = id => axios.delete(`/api/v1/ldaps/${id}`);
export const getLdapList = () => axios.get('/api/v1/ldaps');
export const updateLdap = (id,data) => axios.put(`/api/v1/ldaps/${id}`, data);
export const testLdapAuth = data => axios.post('/api/v1/ldaps/test', data);
export const checkLdap = data => axios.post('/api/v1/ldaps/check', data);
export const getLdapAttr = () => axios.get('/api/v1/ldaps/attributes');

//OAUTH2 
export const getAllOAuth2 = params => axios.get('/api/v1/oauth2',{params});
export const getOAuth2ProviderTypes = () => axios.get('/api/v1/oauth2/provider-types');
export const updateAuthProvider = (id,data) => axios.put(`/api/v1/oauth2/${id}`,data);
export const createAuthProvider = data => axios.post('/api/v1/oauth2',data);
export const deleteAuthProvider = id => axios.delete(`/api/v1/oauth2/${id}`);
export const getActiveOAuth2List = () => axios.get('/api/v1/oauth2/active');

//LOCATION
export const createSystemLocation = data => axios.post('/api/v1/location/system',data);
export const createUserLocation = data => axios.post('/api/v1/location/user',data);
export const updateSystemLocation = (id,data) => axios.put(`/api/v1/locations/${id}/system`, data);
export const updateUserLocation = (id,data) => axios.put(`/api/v1/locations/${id}/user`, data);
export const deleteSystemLocation = id => axios.delete(`/api/v1/locations/${id}/system`);
export const deleteUserLocation = id => axios.delete(`/api/v1/locations/${id}/user`);
export const getUserLocationList = () => axios.get('/api/v1/location/users');
export const getUserAndSystemLocationList = () => axios.get('/api/v1/locations');

//Webhook
export const getAllWebHooks = params => axios.get('/api/v1/webhooks',{params});
export const getWebhookEventTypes = () => axios.get('/api/v1/webhooks/event-types');
export const createWebhook = data => axios.post('/api/v1/webhooks',data);
export const getWebhook = id => axios.get(`/api/v1/webhooks/${id}`);
export const updateWebhook = (id,data) => axios.put(`/api/v1/webhooks/${id}`,data);
export const deleteWebhook = id => axios.delete(`/api/v1/webhooks/${id}`);
export const checkWebhook = data => axios.post('/api/v1/webhooks/check',data);
export const getWebhookAuthMethod = () => axios.get('/api/v1/webhooks/auth-methods');

//ROLE
export const getAllRoles = () => axios.get('/api/v1/roles');
export const createRole = data => axios.post('/api/v1/role', data);
export const updateRole = (roleId,data) => axios.put(`/api/v1/role?roleId=${roleId}`, data);
export const deleteRole = roleId => axios.delete(`/api/v1/role?roleId=${roleId}`);


//PRIVILEGE
export const getAllPrivileges = () => axios.get('/api/v1/privileges');
export const getPrivilegeGroups = () =>axios.get('/api/v1/privilege-groups');


//STORAGE
const APPS_ENDPOINT_V1 = '/api/v1/apps';
export const uploadApp = (formData,config) => axios.post(APPS_ENDPOINT_V1, formData, config);
export const uploadAppV3 = body=> axios.post('api/v3/apps',body);
export const getAppList = filter => axios.get(APPS_ENDPOINT_V1, {params: filter});
export const getAppListForEmbedded = config => axios.get(APPS_ENDPOINT_V1, config);
export const deleteSelectedApps = appIds => axios.delete(`${APPS_ENDPOINT_V1}`,{params:{appIds}});
export const getApp = selectedVersion => axios.get(`${APPS_ENDPOINT_V1}?id=${selectedVersion}`);
export const downloadApp = id => axios.get(`${APPS_ENDPOINT_V1}/${id}/download`, {responseType: 'blob'});
export const getAppCategoryList = () => axios.get(`${APPS_ENDPOINT_V1}/categories`);


//SYSTEM PARAMETER
export const getSystemParameters = () => axios.get('/api/v1/system-parameters');
export const updateSystemParameter = (parameterId,data) => axios.put(`/api/v1/system-parameters/${parameterId}`, data);


//TEST SESSION
export const getTestSessionList = params => axios.get(`${TEST_SESSION_REPORT_URL}?${params}`);
export const deleteTestSession = id => axios.delete(`${TEST_SESSION_REPORT_URL}/${id}`);
export const deleteReportFile = (id,file) =>axios.delete(`${TEST_SESSION_REPORT_URL}/${id}/delete-file?file=${file}`)
export const downloadReportFile = (id,fileParam) => axios.get(`${TEST_SESSION_REPORT_URL}/${id}/download-file${fileParam || ''}`, { responseType: 'blob' });
export const startTestSession = testSessionField => axios.post(`${TEST_SESSION_REPORT_URL}/start`, testSessionField);
export const startTestSessionOptional = (testSessionField, testRun) => axios.post(`${TEST_SESSION_REPORT_URL}/start?${testRun}`, testSessionField);
export const stopTestSession = sessionId => axios.put(`${TEST_SESSION_REPORT_URL}/${sessionId}/stop`);
export const getActiveTestSession = sessionId => axios.get(`${TEST_SESSION_REPORT_URL}/active/${sessionId}`);
export const pingSession = id => axios.put(`${TEST_SESSION_REPORT_URL}/ping/${id}`);
export const reconnectSession = sessionId => axios.get(`${TEST_SESSION_REPORT_URL}/reconnect/${sessionId}`);
export const swipeToTakeScreenshot = (id,swipeBuffer,offsetX, offsetY, width, height) => axios.post(`${TEST_SESSION_REPORT_URL}/${id}/screenshot`, {
    type: 'swipe',
    x1: swipeBuffer[0].x * 1.0 / width,
    y1: swipeBuffer[0].y * 1.0 / height,
    x2: offsetX * 1.0 / width,
    y2: offsetY * 1.0 / height
  });
export const clickToTakeScreenshot = (id,clickOffsetX, clickOffsetY, clickWidth, clickHeight) =>axios.post(`/api/v1/test-sessions/${id}/screenshot`, {
    type: 'click',
    x1: clickOffsetX * 1.0 / clickWidth,
    y1: clickOffsetY * 1.0 / clickHeight
  })

export const takeScreenshot = id => axios.post(`/api/v1/test-sessions/${id}/screenshot`);

export const startVideoRecord = id => axios.post(`/api/v1/test-sessions/${id}/video/start`);
export const stopVideoRecord = id => axios.post(`/api/v1/test-sessions/${id}/video/stop`);

export const startDeviceLog = id => axios.post(`/api/v1/test-sessions/${id}/device-log/start`);
export const stopDeviceLog = id => axios.post(`/api/v1/test-sessions/${id}/device-log/stop`);

export const startTrafficRecord = id => axios.post(`/api/v1/test-sessions/${id}/traffic-record/start`);
export const stopTrafficRecord = id => axios.post(`/api/v1/test-sessions/${id}/traffic-record/stop`);


//USER
export const getUserList = () => axios.get('/api/v1/users');
export const getUserSummaryList = () => axios.get('/api/v1/users/summary'); //For filtering based on users with simple few info about users
export const updateCredential = (id,data) => axios.put(`/api/v1/users/credential?id=${id}`, data);
export const createUser = data => axios.post('/api/v1/users', data);
export const deleteUser = id => axios.delete(`api/v1/users/${id}`);
export const checkUser = (credentialType,checkParam) => axios.get('/api/v1/user', {
    params: {
        type: credentialType,
        param: checkParam
    }
});
export const getLoginUser = headers => axios.get('/api/v1/users/me', { headers });


//LDAP USER
export const getLdapUsers = (ldapId,type,param) => axios.get(`/api/v1/ldap-users`, { params:{ldapId,type,param} });
export const createLdapUsers = selectedResults => axios.post('/api/v1/ldap-users', selectedResults);
export const deleteLdapUser = id => axios.delete(`api/v1/ldap-users/${id}`);


export const createPasswordRules = data =>axios.post('/api/v1/users/password-rules',data);
export const getPasswordRules = () => axios.get('/api/v1/users/password-rules');
export const changePassword = (userName, body) => axios.put(`/api/v1/users/password/${userName}`, body);
export const deleteTwoFactorSecretKey = id =>axios.delete(`/api/v1/users/two-factor-secret-key/${id}`);
export const downloadUserReport = () => axios.get('/api/v1/users/report', {responseType: 'blob'});


//ACCESS KEY
export const getAccessKey = () =>axios.get(`/api/v1/users/access-key`);
export const createAccessKey = () => axios.post(`/api/v1/users/access-key`);


//API KEY
export const getApiKey = () => axios.get(`/api/v1/users/api-key`);
export const createApiKey = () => axios.post(`/api/v1/users/api-key`);


//APPLICATION
export const getApplicationVersion = () => axios.get('/api/v1/application/version');


//CERTIFICATE
export const createCertificate = (data,config) => axios.post('/api/v1/certificates', data, config);
export const updateCertificate = (id, data, config) =>axios.put(`/api/v1/certificates/${id}`, data, config);
export const getCertificateList = () => axios.get('/api/v1/certificates');
export const deleteCertificate = id => axios.delete(`/api/v1/certificates/${id}`);


//TOKEN
export const refreshAccessToken = refreshToken => axios.post('/api/v1/token/refresh', { refreshToken });


//REPORT
export const getReportDeviceUsageRawData = params => axios.get(`${DEVICE_USAGE_URL}?${params}`);
export const getReportDeviceUsageRawDataCsv = params => axios.get(`${DEVICE_USAGE_URL}/csv`, {responseType: 'blob', params});
export const downloadDeviceUsagesAsZip = (params,id) => axios.get(`${DEVICE_USAGE_URL_V2}/csv/${id}/download`, {responseType: 'blob', params});
export const startReportDeviceUsageRawData = params => axios.get(`${DEVICE_USAGE_URL_V2}/csv`,{params});


// DEVICE AND APPIUM LOGS
export const startCollectingLogs = (id,logType) => axios.get(`/api/v1/devices/${id}/logs/start`,{params:{logType}})
export const stopCollectingLogs = (id,uuId) => axios.get(`/api/v1/devices/${id}/logs/stop`,{params:{uuId}});
export const deviceReboot = udId => axios.get('/api/screen-share/reboot', {params: {udId: udId}})

//SECURITY
export const getPublicKey = () => axios.get(`/api/v1/security/public-key`);

//ALARMS
export const getAlarmList = params => axios.get('/api/v1/alarms', {params});
export const getAlarm = id => axios.get(`/api/v1/alarms/${id}`);
export const updateAlarm = (id, data) => axios.put(`/api/v1/alarms/${id}`, data);
export const getAlarmStatusList = () => axios.get(`/api/v1/alarms/status-list`);

//FILE LIBRARY
export const getFileList = params => axios.get(`api/v1/files`, { params });
export const downloadFile = id => axios.get(`/api/v1/files/${id}/download`, {responseType: 'blob'});
export const uploadFile = (formData,config) => axios.post(`/api/v1/files`,formData,config);
export const deleteFile = id => axios.delete(`/api/v1/files/${id}`);
export const updateFileName = (id,name) => axios.put(`/api/v1/files/${id}`,null,{params:{name}});
export const deployFile = (udId, fileId) =>axios.post(`api/v1/files/deploy`, null, {params:{udId, fileId}});
export const deployBulkFile = data => axios.post(`api/v1/files/bulk-deploy`,data);

export const uploadFileV2 = body => axios.post('api/v2/files',body);


//DEVICE
export const getDeviceInfo = udId => axios.get(`/api/v1/devices/${udId}/info`);
export const getDeviceDetails = deviceId => axios.get(`/api/devices/${deviceId}`);
export const updateDeviceInfo = (id,data) => axios.put(`/api/v1/devices/${id}`, data);
export const deleteDevice = deviceId => axios.delete(`/api/devices/${deviceId}`);
export const deleteDeviceImage = deviceModel => axios.delete(`/api/devices/${deviceModel}/image`);

//INTEGRATION
//VISIUM MANAGE INTEGRATION
export const getVisiumManageIntegration = () => axios.get('api/v1/integrations/visium-manage');
export const updateVisiumManageIntegration = data => axios.put(`api/v1/integrations/visium-manage`, data);
export const getSingleUseToken = tsid => axios.get(`api/v1/integrations/token?tsid=${tsid}`);
export const getTestSessionInfo = tsid => axios.get(`/api/v1/test-session/${tsid}`);
export const getIntegrationDefects = tsid => axios.get(`/api/v1/test-sessions/${tsid}/defects`);
export const getAllDefects = () => axios.get(`/api/v1/test-sessions/defects`);

export const manageRefreshRequest = () => {
  return axios.post(`/manage/api/auth/refresh`,
  {
    accessToken: '',
    refreshToken: localStorage.getItem('manageRefreshToken')
  },
  {headers: {
    Authorization: `Bearer ${localStorage.getItem('manageAccessToken')}`
  }}
  );
}

export const sendTestResult = (startDate, endDate, status, testRunId) => {
  const params = {
    startDate,
    endDate,
    status,
    testRunId,
    "quickRunExecution": true
  }

  return axios.post(`/manage/api/test-run-executions/quick`, params, {headers: {
    Authorization: `Bearer ${localStorage.getItem('manageAccessToken')}`
  }});
}

export const getTestRunSteps = project => {
  axios.defaults.headers.common['X-Project-Id'] = project.id;

  return axios.get(`/manage/api/test-runs/${project.id}/steps`, {headers: {
    Authorization: `Bearer ${localStorage.getItem('manageAccessToken')}`
  }});
}

export const getTestRunByProject = project => {

  axios.defaults.headers.common['X-Project-Id'] = project.id;

  const params = {
    projectId: `${project.id}`,
    page: 0,
    size: 10,
    sortBy: 'code',
    sortDir: 'DESC'
  }

  return axios.get(`/manage/api/test-runs/byTester`, {
    params,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('manageAccessToken')}`
    }
  });
}

export const getManageProjects = () => {
  return axios.get('/manage/api/projects', {headers: {
    Authorization: `Bearer ${localStorage.getItem('manageAccessToken')}`,
  }});
}

//RESERVATION
export const getDeviceReservationInfo = udid => axios.get(`/api/v1/devices/${udid}/reservation`);
export const getReservations = params => axios.get(`/api/v1/reservations`, {params});
export const deleteReservation = (id, reason) => axios.delete(`/api/v1/reservations/${id}?reason=${reason}`);
export const getReservationCancelReasons = () => axios.get(`/api/v1/reservations/config/cancel-reasons`);
export const getReservationTypeList = udid => axios.get(`/api/v1/reservations/config/types?udId=${udid}`);


//CHAT APP
export const getDeviceChatMessages = id => axios.get(`/api/v1/chat/messages/${id}`);


//NODE
export const getNodeList = () => axios.get('/api/v1/nodes');
export const updateNode = (id,node) => axios.put(`/api/v1/nodes/${id}`,node);

//SCREEN SHARE
export const stopApplication = (udId, packageName) => axios.post(`/api/screen-share/stop-app`, {udId, packageName})

//AUTOMATION SETTINGS
export const getAutomationTypes = () => axios.get(`/api/v1/automation/types`);
export const getAutomationCapabilities = (udId, appId, type, url) => axios.get(`/api/v1/automation/capabilities`, {params:{udId, appId, type, url}});

//MANAGE INSPECTOR
export const switchContext = (sessionId, contextType) => axios.post(`/wd/hub/session/${sessionId}/context`, {'name': `${contextType}`});
export const getTitle = (sessionId) => axios.get(`/wd/hub/session/${sessionId}/title`)
export const getSessionSource = sessionId => axios.get(`/wd/hub/session/${sessionId}/source`);
export const getSessionSourceEmbedded = (sessionId, headers) => axios.get(`/wd/hub/session/${sessionId}/source`, {headers});
export const getElementId = (sessionId,body) => axios.post(`/wd/hub/session/${sessionId}/element`, body)
export const getSync = (sessionId,body) => axios.post(`/wd/hub/session/${sessionId}/execute/sync`,body);
export const getRect = (sessionId,elementId) => axios.get(`/wd/hub/session/${sessionId}/element/${elementId}/rect`)


//VERSION PROMOTER OPERATIONS
export const viewVersionPromoterModal = version => axios.put(`/api/v1/users/${version}/view-version-promoter`);
export const isViewedVersionPromoterModal = version => axios.get(`/api/v1/users/${version}/is-viewed-version-promoter`);
export const getVersionInnovations = () => axios.get(`/api/v1/version-innovations`);


// ASYNC TASK
export const checkAsyncTaskStatus = id => axios.get(`/api/v1/tasks/${id}/status`);

// Injection
export const uploadInjectionResource = (formData,config) => axios.post('/api/v1/hardware-simulate/resource/upload', formData, config);

// FileUpload
const FILES_ENDPOINT = '/api/v1/files';
export const uploadChunk = (formData,config) => axios.post(`${FILES_ENDPOINT}/chunk`, formData,config);
export const abortSession = (token ) => axios.delete(`${FILES_ENDPOINT}/upload/session/${token}`);
