import React from 'react'
import axios from 'axios'
import { withRouter } from "react-router-dom"
import { withTranslation } from 'react-i18next';
import { Grid, Modal,Header,Dimmer,Loader } from 'semantic-ui-react'
import { showError } from '../../utils/ToastHelpers';
import AndroidEventEmitter from "../../utils/AndroidEventEmitter";
import { toast } from "react-toastify";
import DeviceClient from "../../utils/DeviceClient";
import { parseJwt } from '../../utils/Methods';
import { EXCLAMATION_ICON } from '../../utils/UiIcons';
import { embeddedScreenMessages, FAILED_ERROR_MESSAGE, GET_REMOTE_INFO_ERROR, LOADING, NOT_STREAMED_DATA_MESSAGE } from '../../utils/UIMessages';
import { WEBSOCKET_MESSAGES } from '../../utils/Constants';

class EmbeddedScreen extends React.Component {
    eventEmitter = new AndroidEventEmitter();
    state={
      icon:undefined,
      deviceScreenState: "",
      deviceImgStyle: {
        height: "calc(93vh - 120px)"
      },
      screenResolution: [],
      imageAspectRatio: 1,
      scaleRatio: [],
      imageBounds: [],
      visibility:false,
      errMsg:null,
      termMsg:null
    }


    updateScaleRatio = () => {
      if (this.state.imageAspectRatio !== 1) {
        let resolutionWidth = this.state.screenResolution[0];
        let resolutionHeight = this.state.screenResolution[1];
        let updatedImageHeight = window.innerHeight * 0.93 - 120;  // 93vh-120px
        if (this.state.deviceScreenState === '1' || this.state.deviceScreenState === '2') { //landscape mode
          updatedImageHeight = window.innerWidth * 0.3 - 177;  // 30vw - 177px
          resolutionWidth = this.state.screenResolution[1];
          resolutionHeight = this.state.screenResolution[0];
        }
        let updatedImageWidth = updatedImageHeight * this.state.imageAspectRatio;
        this.setState({
          scaleRatio: [updatedImageWidth / resolutionWidth, updatedImageHeight / resolutionHeight],
          imageBounds: [updatedImageWidth, updatedImageHeight]
        });
      }
    }


    onLoadImage = (event) => {
      let imageHeight = window.innerHeight * 0.93 - 120;  // 93vh-120px
      if (this.state.deviceScreenState === '1' || this.state.deviceScreenState === '2') { //landscape mode
        imageHeight = window.innerWidth * 0.3 - 177;  // 30vw - 177px
      }
      let imageWidth = imageHeight * this.state.imageAspectRatio;
      this.setState({
        imageBounds: [imageWidth, imageHeight],
        imageAspectRatio: event.target.width / event.target.height
      }, () => this.updateScaleRatio());
    }

    handleHasFocus = () => {
      this.eventEmitter.emit(WEBSOCKET_MESSAGES.PONG + this.state.tokenPayload.udId, `FOCUS|${document.hasFocus()}`);
    }

    startDeviceStream = () => {
        let token = this.props.match.params;
        this.setState({tokenPayload: parseJwt(token)}, () => {

          axios.get("/api/screen-share/view", { params: { udId: this.state.tokenPayload.udId },headers: {'Embedded-Screen-Key':`${token.token}`}})
          .then((response) => {
            if (response.data.port) {
              let ipAddress
              let url;
              let schema;
              ipAddress = window.location.host;
              if (window.location.protocol === "https:") {
                schema = "wss";
              } else {
                schema = "ws";
              }
              if (window.location.host.includes("localhost:") || process.env.NODE_ENV === 'development') { //#fordevelopment
                schema = response.data.schema;
                ipAddress = response.data.ipAddress;
              }
              url = schema + "://" + ipAddress + "/websocket/" + response.data.port.toString();
              url += `?token=${response.data.token}`;
      
              this.client = new DeviceClient(url, this.eventEmitter, this.state.tokenPayload.udId);
      
            } else {
              showError(GET_REMOTE_INFO_ERROR());
            }
          }).catch(err => {
             if (err?.response?.data?.message) {
                 if(err?.response?.data?.message.includes('concurrent access limit')){
                     showError(embeddedScreenMessages().MAXIMUM_CONCURRENT_ACCESS_LIMIT_MESSAGE)
                     return;
                 }else{
                      this.setState({errMsg:`${err?.response?.data?.message}`,visibility:true})
                 }
             } else if (err?.response?.data) {
                 showError(`${err?.response?.data}`);
             } else {
                 showError(FAILED_ERROR_MESSAGE());
             }
          });
  
  
          this.eventEmitter.on('jpeg' + this.state.tokenPayload.udId, (jpeg) => {
            if (jpeg) {
              this.setState({ icon: jpeg }, () => {
                let img = new Image();
                img.src = "data:image/png;base64," + jpeg;
                if (img.height > img.width) { //portrait mode
                  this.setState({ deviceImgStyle: { height: "calc(93vh - 120px)" }, deviceScreenState: "0" });
                }
                if (img.height < img.width) { //landscape mode
                  this.setState({ deviceImgStyle: { height: "calc(30vw - 177px)" }, deviceScreenState: "1" });
                }
              })
            } else {
              showError(NOT_STREAMED_DATA_MESSAGE());
            }
          });
  
  
          this.eventEmitter.on('connection', (data) => {
  
            if(data.payload ==="concurrent access limit"){
                this.client.closeSocket();
                showError(embeddedScreenMessages().MAXIMUM_CONCURRENT_ACCESS_LIMIT_MESSAGE);
            }
  
            let payload = data.payload;
            let args = data.args;
  
            if (data.id === "CONNECTION_TERMINATED_BY") {
              toast.dismiss();
              showError(this.props.t(payload).replace('%s', args[0]));
            } else if (data.id === 'PING') {
              const query = payload.query;
              if (query === "focus") {
                this.handleHasFocus();
              }
            } else if (data.id === 'TERM') {
              const message = payload.message;
              this.setState({termMsg: message, visibility: true});
            }
  
          })
        });

      }


        async componentDidMount() {
          this.startDeviceStream();
        }


        componentWillUnmount() {
          if (this.client) {
            this.client.closeSocket()
          }
        }


        render() {
          if (this.state.termMsg !== null) {
            return (
              <Grid>
                <Grid.Column style={{ display: 'flex',justifyContent: 'center',paddingTop: 24,paddingBottom: 20}}>
                   <Modal open={this.state.visibility} size={"tiny"}  style={{ zIndex: 9999 }}>
                      <Header icon={EXCLAMATION_ICON} style={{ display: 'flex',justifyContent: 'center'}}  content={this.state.termMsg} color={"red"} />
                   </Modal>
                </Grid.Column>
              </Grid>
            )
          } else if (this.state.icon !== undefined) {
            return (
              <>
              <Grid centered columns={1}>
                <Grid.Column style={{
                  display:"flex",
                  justifyContent: 'center',
                  alignItems:'center',
                  paddingTop: 60,
                  paddingBottom: 20
                }}
                    className={'multi-device-column ' + (this.state.deviceScreenState === '1' ? 'landscape-mode' : '')}>

                    <div id="remoteSegment"
                         style={{display: 'flex', position: 'relative', justifyContent: 'center', alignItems: 'center'}}
                    >
                        <img className="deviceImage"
                             style={this.state.deviceImgStyle}
                             src={'data:image/png;base64,' + this.state.icon} alt="phone"
                             tabIndex="0"
                             onLoad={(event) => this.onLoadImage(event)}
                        />
                    </div>
                </Grid.Column>
                </Grid>
              </>
            );
          } else {
               if(this.state.errMsg == null){
                   return (
                    <Grid>
                      <Grid.Column style={{ display: 'flex',justifyContent: 'center',paddingTop: 24,paddingBottom: 20 }}>
                         <div className="load-device">
                            <Dimmer active>
                               <Loader active={true} size={"large"} indeterminate>
                                 <div id={"loaderText"}>{LOADING()}</div>
                               </Loader>
                            </Dimmer>
                          </div>
                      </Grid.Column>
                    </Grid>
                     );
                }else{
                   return (
                    <Grid>
                      <Grid.Column style={{ display: 'flex',justifyContent: 'center',paddingTop: 24,paddingBottom: 20}}>
                         <Modal open={this.state.visibility} size={"tiny"}  style={{ zIndex: 9999 }}>
                            <Header icon={EXCLAMATION_ICON} style={{ display: 'flex',justifyContent: 'center'}}  content={embeddedScreenMessages().INVALID_ACCESS_TOKEN_MESSAGE}color={"red"} />
                         </Modal>
                      </Grid.Column>
                    </Grid>
                   );
                }
           }
        }

  }
  export default withTranslation()(withRouter(EmbeddedScreen));
  
