import React, {Component} from 'react';
import {Button, Form, Header, Icon, Label, Menu, Table} from "semantic-ui-react";
import {withTranslation} from 'react-i18next'
import {Base64} from "js-base64";
import copy from "copy-to-clipboard";
import {Flip, toast} from "react-toastify";
import {getDate} from "../../utils/Methods";
import {showError, showInfo} from '../../utils/ToastHelpers';
import {
    getAccessKey,
    createAccessKey,
    changePassword,
    getApiKey,
    createApiKey
} from '../../api/apiCalls'
import { encryptData } from '../../utils/PublicKeyHelper';
import { COPIED_LABEL, COPY_BUTTON, FAILED_ERROR_MESSAGE, GENERATE_BUTTON, KEY_LABEL, profileMessages, SUCCESS_MESSAGE } from '../../utils/UIMessages';
import { EYE_ICON, EYE_SLASH_ICON, USER_ICON } from '../../utils/UiIcons';


class ProfilePage extends Component {
    state = {
        userDetails: {},
        automationKey: "",
        showAutomationKey: false,
        apiKey: "",
        showApiKey: false,
        showOldPassword: false,
        showNewPassword: false,
        oldPassword: "",
        newPassword: "",
        activeItem:'RoleAndPrivileges',
    };

    

    componentDidMount() {
        //axios.get(`/api/user/access-key`)
        getAccessKey().then(res => {
            this.setState({ automationKey: res.data.accessKey })
        }).catch(err => {
            if (err?.response?.status === 404 || err?.response?.status === 400) { //first situation, no access key
                this.setState({ automationKey: profileMessages().NOT_HAVE_AUTOMATION_KEY_MESSAGE })
            } else {
                showError(profileMessages().NOT_FETCH_AUTOMATION_KEY + err?.response?.data?.message ?? err.toString())
            }
        })
        //axios.get(`/api/user/api-key`)
        getApiKey().then(res => {
            this.setState({ apiKey: res.data.apiKey })
        }).catch(err => {
            if (err?.response?.status === 404 || err?.response?.status === 400) {
                this.setState({ apiKey: profileMessages().NOT_HAVE_API_KEY_MESSAGE})
            } else {
                showError(profileMessages().NOT_FETCH_API_KEY + err?.response?.data?.message ?? err.toString())
            }
        })
        let userDetails = JSON.parse(Base64.decode(localStorage.getItem("userDetails")))
        this.setState({ userDetails })
    }

    generateKey = (key) => {
        if (key === "automationKey") {
            //axios.post(`/api/user/access-key`)
            createAccessKey().then(res => {
                this.setState({ automationKey: res.data.accessKey })
            }).catch(err => {
                showError(profileMessages().NOT_GENERATE_AUTOMATION_KEY + err?.response?.data?.message ?? err.toString())
            })
        } else if (key === "apiKey") {
            //axios.post(`/api/user/api-key`)
            createApiKey().then(res => {
                this.setState({ apiKey: res.data.apiKey })
            }).catch(err => {
                showError(profileMessages().NOT_GENERATE_API_KEY + err?.response?.data?.message ?? err.toString())
            })
        }
    }

    copyKey = (key) => {
        copy(this.state[key]);
        showInfo(COPIED_LABEL(), 1000);
    }

    downloadTxtFile = (key) => {
        const element = document.createElement("a");
        const file = new Blob([this.state[key]], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = `${key}_` + getDate() + ".txt";
        document.body.appendChild(element);
        element.click();
        element.remove();
    }

    onToggleShowKey = (key) =>
        this.setState(
            prevState => ({
                [key]: !prevState[key]
            }),
        );

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }

    changePassword = async () => {
        const oldPassword = await encryptData(this.state.oldPassword);
        const newPassword = await encryptData(this.state.newPassword);
        //axios.post(`/api/user/change-password/${this.state.userDetails.userName}?oldPassword=${Base64.encode(this.state.oldPassword)}&newPassword=${Base64.encode(this.state.newPassword)}`)
        changePassword(this.state.userDetails.userName, { oldPassword, newPassword }).then(() => {
            toast.success(SUCCESS_MESSAGE(), {
                autoClose: 3000,
                transition: Flip
            })
            this.setState({
                oldPassword: "",
                newPassword: ""
            })
        }).catch(err => {
            showError(FAILED_ERROR_MESSAGE() + err?.response?.data?.message ?? err.toString())
        })
    }

    handleItemClick = (e, { name }) => this.setState({ activeItem: name })

    renderRolesAndPrivileges = () => {
        return <>
            {this.state.userDetails?.roles?.map((role, i) => {
                return <div key={i}><Header size={"small"}>{role.name}</Header>
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{profileMessages().PRIVILEGE_NAME_LABEL}</Table.HeaderCell>
                                <Table.HeaderCell>{profileMessages().PRIVILEGE_DESCRIPTION_LABEL}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {role.privileges.map((privilege, i) => {
                                return <Table.Row key={i}>
                                    <Table.Cell>
                                        <Header as='h4'>
                                            <Header.Content>
                                                {this.props.t(privilege.name)}
                                            </Header.Content>
                                        </Header>
                                    </Table.Cell>
                                    <Table.Cell>{this.props.t(privilege.description)}</Table.Cell>
                                </Table.Row>
                            })}
                        </Table.Body>
                        <Table.Footer fullWidth>
                            <Table.Row>
                                <Table.HeaderCell>

                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <Label color='blue' ribbon={"right"}>
                                        {profileMessages().TOTAL_NUMBER_OF_PRIVILEGES_LABEL}: {role?.privileges?.length}
                                    </Label>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </div>
            })}
        </>
    }

    renderChangePassword = () => {
        return <>
            {
                !this.state.userDetails.ldapuser && <>
                    <Form name='changePassword'>
                        <Form.Field inline>
                            <Form.Input
                                name='oldPassword'
                                onFocus={(e) => {
                                    e.target.select()
                                }}
                                onClick={(e) => {
                                    e.target.select()
                                }}
                                onChange={this.handleChange}
                                className="centeredPlaceholder"
                                placeholder={profileMessages().OLD_PASSWORD_PLACEHOLDER}
                                type={this.state.showOldPassword ? 'text' : 'password'}
                                value={this.state.oldPassword}
                                icon={
                                    <Icon
                                        name={this.state.showOldPassword ? EYE_SLASH_ICON : EYE_ICON}
                                        link
                                        onClick={() => this.onToggleShowKey("showOldPassword")}
                                    />
                                }
                            />
                        </Form.Field>
                        <Form.Field inline>
                            <Form.Input
                                name='newPassword'
                                onFocus={(e) => {
                                    e.target.select()
                                }}
                                onClick={(e) => {
                                    e.target.select()
                                }}
                                onChange={this.handleChange}
                                className="centeredPlaceholder"
                                placeholder={profileMessages().NEW_PASSWORD_PLACEHOLDER}
                                type={this.state.showNewPassword ? 'text' : 'password'}
                                value={this.state.newPassword}
                                icon={
                                    <Icon
                                        name={this.state.showNewPassword ? EYE_SLASH_ICON : EYE_ICON}
                                        link
                                        onClick={() => this.onToggleShowKey("showNewPassword")}
                                    />
                                }
                            />
                        </Form.Field>
                        <Button color={"blue"}
                                disabled={!this.state.oldPassword || !this.state.newPassword}
                                onClick={this.changePassword}>
                            {profileMessages().CHANGE_PASSWORD_BUTTON}
                        </Button>
                    </Form>
                </>}
        </>
    }

    renderAutomationKey=()=>{
        return <>
            <Form>
                <Form.Field inline>
                    <Form.Input
                        onFocus={(e) => {
                            e.target.select()
                        }}
                        onClick={(e) => {
                            e.target.select()
                        }}
                        readOnly
                        className="centeredPlaceholder"
                        placeholder={KEY_LABEL()}
                        value={profileMessages(this.state.automationKey).AUTOMATION_KEY_VALUE}
                        type={this.state.showAutomationKey ? 'text' : 'password'}
                        icon={
                            <Icon
                                name={this.state.showAutomationKey ? EYE_SLASH_ICON : EYE_ICON}
                                link
                                onClick={() => this.onToggleShowKey("showAutomationKey")}
                            />
                        }
                    />
                </Form.Field>
                <Button color={"blue"} style={{ width: "10em" }}
                    disabled={profileMessages(this.state.automationKey).AUTOMATION_KEY_VALUE === '' ||
                        profileMessages(this.state.automationKey).AUTOMATION_KEY_VALUE === profileMessages().NOT_HAVE_AUTOMATION_KEY_MESSAGE}
                    onClick={() => this.copyKey("automationKey")}>
                    {COPY_BUTTON()}
                </Button>
                <Button color={"facebook"} style={{ width: "10em" }} onClick={() => this.generateKey("automationKey")}>
                    {GENERATE_BUTTON()}
                </Button>
                <Button color={"blue"} style={{ width: "10em" }}
                    disabled={profileMessages(this.state.automationKey).AUTOMATION_KEY_VALUE === '' ||
                        profileMessages(this.state.automationKey).AUTOMATION_KEY_VALUE === profileMessages().NOT_HAVE_AUTOMATION_KEY_MESSAGE}
                    onClick={() => this.downloadTxtFile("automationKey")}>
                    {profileMessages().DOWNLOAD_TXT_BUTTON}
                </Button>
            </Form>
        </>
    }

    renderApiKey=()=>{
        return <>
            <Form>
                <Form.Field inline>
                    <Form.Input
                        onFocus={(e) => {
                            e.target.select()
                        }}
                        onClick={(e) => {
                            e.target.select()
                        }}
                        readOnly
                        className="centeredPlaceholder"
                        placeholder={profileMessages().API_KEY_LABEL}
                        value={profileMessages(this.state.apiKey).API_KEY_VALUE}
                        type={this.state.showApiKey ? 'text' : 'password'}
                        icon={
                            <Icon
                                name={this.state.showApiKey ? EYE_SLASH_ICON : EYE_ICON}
                                link
                                onClick={() => this.onToggleShowKey("showApiKey")}
                            />
                        }
                    />
                </Form.Field>
                <Button color={"blue"} style={{ width: "10em" }}
                        disabled={!profileMessages(this.state.apiKey).API_KEY_VALUE
                        || profileMessages(this.state.apiKey).API_KEY_VALUE === profileMessages().NOT_HAVE_API_KEY_MESSAGE
                        || profileMessages(this.state.apiKey).API_KEY_VALUE.includes(".*****")}
                        onClick={() => this.copyKey("apiKey")} content={COPY_BUTTON()} />
                <Button color={"facebook"} style={{ width: "10em" }} onClick={() => this.generateKey("apiKey")} content={GENERATE_BUTTON()} />
                <Button color={"blue"} style={{ width: "10em" }}
                        disabled={!profileMessages(this.state.apiKey).API_KEY_VALUE
                        || profileMessages(this.state.apiKey).API_KEY_VALUE === profileMessages().NOT_HAVE_API_KEY_MESSAGE
                        || profileMessages(this.state.apiKey).API_KEY_VALUE.includes(".*****")}
                        onClick={() => this.downloadTxtFile("apiKey")} content={profileMessages().DOWNLOAD_TXT_BUTTON} />
            </Form>
        </>
    }


    render() {
    let content, pageHeader;
    const {activeItem} = this.state
    if (activeItem === 'RoleAndPrivileges'){
        content = this.renderRolesAndPrivileges()
        pageHeader = profileMessages().ROLES_AND_PRIVILEGES_HEADER
    }
    if (activeItem === 'ChangePassword'){
        content = this.renderChangePassword()
        pageHeader = profileMessages().CHANGE_PASSWORD_HEADER
    }
    if (activeItem === 'AutomationKey'){
        content = this.renderAutomationKey()
        pageHeader = profileMessages().AUTOMATION_KEY_HEADER
    }
    if (activeItem === 'APIKey'){
        content = this.renderApiKey()
        pageHeader = profileMessages().API_KEY_LABEL
    }
        return (
            <>
                <div className="main-container">
                    <div className="main-left-container profile-page-left-menu">
                        <h1>{profileMessages().PROFILE_PAGE_HEADER}</h1>
                        <div className="main-left-content">
                            <div className="profile-me-box">
                                <Icon name={USER_ICON} circular />
                                <>{this.state.userDetails.fullName}</>
                                <div>{this.state.userDetails.userMail}</div>
                            </div>

                            <Menu vertical fluid secondary >
                                <Menu.Item
                                    name='RoleAndPrivileges'
                                    active={this.state.activeItem === 'RoleAndPrivileges'}
                                    onClick={this.handleItemClick}
                                >
                                    {profileMessages().ROLES_AND_PRIVILEGES_HEADER}

                                </Menu.Item>

                                {!this.state.userDetails.ldapuser && <Menu.Item
                                    name='ChangePassword'
                                    active={this.state.activeItem === 'ChangePassword'}
                                    onClick={this.handleItemClick}
                                >
                                    {profileMessages().CHANGE_PASSWORD_HEADER}
                                    
                                </Menu.Item>}

                                <Menu.Item
                                    name='AutomationKey'
                                    active={this.state.activeItem === 'AutomationKey'}
                                    onClick={this.handleItemClick}
                                >
                                    {profileMessages().AUTOMATION_KEY_HEADER}
                                    
                                </Menu.Item>
                                <Menu.Item
                                    name='APIKey'
                                    active={this.state.activeItem === 'APIKey'}
                                    onClick={this.handleItemClick}
                                >
                                    {profileMessages().API_KEY_LABEL}
                                </Menu.Item>
                            </Menu>
                        </div>
                    </div>
                    <div className="main-right-container">
                        <div className="main-right-header">
                            <h2>{pageHeader}</h2>
                        </div>
                        {content}
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation()(ProfilePage);