import React from 'react';
import { Icon, Form, Popup } from 'semantic-ui-react';
import { POPUP_POSITIONS } from '../utils/Constants';
import { QUESTION_CIRCLE_OUTLINE_ICON } from '../utils/UiIcons';
import { ACCEPTABLE_FILE_EXTENSION_POPUP } from '../utils/UIMessages';

const FileSelectionForm = props => {

    return (
        <div style={{ textAlign: 'left', marginTop: 25 }}>
            <Form>
                <Form.Field required>
                    <label style={{ textAlign: 'left' }}>
                        <>{props.headerName}</>
                        <Popup
                            size='small'
                            content={`${ACCEPTABLE_FILE_EXTENSION_POPUP()} ${props.acceptableExtensions}`}
                            position={POPUP_POSITIONS.RIGHT_CENTER}
                            trigger={<Icon name={QUESTION_CIRCLE_OUTLINE_ICON} size='large' style={{ marginLeft: 5 }} />} />
                    </label>
                    <Form.Input
                        error={props.errMsg.length > 0 && {
                            content: props.errMsg,
                            pointing: 'below'
                        }}
                        type={'file'}
                        accept={props.acceptableExtensions}
                        onChange={props.onChangeFileUpload}
                    />
                </Form.Field>
            </Form>
        </div>
    );
}
export default FileSelectionForm
