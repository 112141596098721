import { useState, useEffect, useCallback, useRef } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {ALARM_TYPE, calendarDateFormat, DEBOUNCE_WAIT, DEFAULT_PAGINATION_PROPS} from '../../utils/Constants';
import {
  Button,
  Dropdown,
  Form,
  Modal,
  Header,
  Icon,
  Segment
} from 'semantic-ui-react';
import { getAlarmList, updateAlarm, getAlarmStatusList } from '../../api/apiCalls';
import { withTranslation } from 'react-i18next';
import { showError, showSuccess } from '../../utils/ToastHelpers';
import ReactTableV8 from '../../components/ReactTableV8/ReactTableV8'
import DebouncedInput from '../../components/ReactTableV8/DebouncedInput'
import { alarmsMessages, ALARMS_HEADER, CANCEL_BUTTON, EDIT_BUTTON, END_DATE_LABEL, EVENT_TIME_HEADER, ID_HEADER, N_A_LABEL, RESET_TABLE_BUTTON, SAVE_BUTTON, START_DATE_LABEL, STATUS_HEADER, SUCCESS_MESSAGE, TYPE_LABEL } from '../../utils/UIMessages';
import { ARCHIVE_ICON, CHECKMARK_ICON, EDIT_ICON, REMOVE_ICON, SEARCH_ICON } from '../../utils/UiIcons';



const Alarms = props => {
  //React Table V8 Columns
  const columns = [
    {
      header: () => <div>{ID_HEADER()}</div>,
      accessorKey: 'id',
      id: 'id'
    },
    {
      header: () => <div>{TYPE_LABEL()}</div>,
      accessorKey: 'type',
      id: 'type',
      cell: info => <span className='number'>{props.t(info.getValue())}</span>
    },
    {
      header: () => <div>{alarmsMessages().SUMMARY_HEADER}</div>,
      accessorKey: 'summary',
      id: 'summary',
      cell: info => {
        const summary = info.getValue().split(" ");
        const slicedSummary = summary.slice(-2).join(" ");
        return(
        <span className='number'>{summary[0]} {alarmsMessages(slicedSummary).SUMMARY_COLUMN_VALUE}</span>
        )
      }
    },
    {
      header: () => <div>{EVENT_TIME_HEADER()}</div>,
      accessorKey: 'eventTime',
      id: 'eventTime',
      cell: info => (<span className='number'>{moment(info.getValue())?.format(calendarDateFormat)}</span>)
    },
    {
      header: () => <div>{alarmsMessages().UPDATE_TIME_HEADER}</div>,
      accessorKey: 'updateTime',
      id: 'updateTime',
      cell: info => (<span className='number'>{moment(info.getValue())?.format(calendarDateFormat)}</span>)
    },
    {
      header: () => <div>{alarmsMessages().UPDATED_BY_HEADER}</div>,
      accessorKey: 'updatedBy',
      id: 'updatedBy',
      cell: info => <span className='number'>{props.t(info.getValue())}</span>
    },
    {
      header: () => <div>{STATUS_HEADER()}</div>,
      accessorKey: 'status',
      id: 'status',
      cell: info => <span className='number' style={{ color: info.row.original.status === 'CLOSED' ? 'red' : 'green' }}>{props.t(info.getValue())}</span>
    },
    {
      header: () => <div>{alarmsMessages().ALARM_END_DATE_HEADER}</div>,
      accessorKey: 'alarmEndDate',
      id: 'alarmEndDate',
      cell: info => (<span className='number'>{info.getValue() === null ? N_A_LABEL() : moment(info.getValue())?.format(calendarDateFormat)}</span>)
    },
    {
      header: () => <div>{EDIT_BUTTON()}</div>,
      accessorKey: 'edit',
      id: 'edit',
      cell: info => {
        return (
          <Button
            id='alarms-edit-button'
            icon={EDIT_ICON}
            fluid
            color='gray'
            size='tiny'
            onClick={() => {
              setOpen(!open);
              setSelectedAlarm(info.row.original);
            }}
          />
        )
      }
    }
  ];

  // Filter initialize obj
  const filterInitialVal = {
    'summary': '',
    'status':[],
    'type':[]
  };

  //Alarm hook
  const [alarms, setAlarms] = useState([]);

  //Page - Size
  const [page, setPage] = useState(DEFAULT_PAGINATION_PROPS.PAGE_INDEX);
  const [size, setSize] = useState(DEFAULT_PAGINATION_PROPS.PAGE_SIZE);

  //Loading
  const [loading, setLoading] = useState(false);

  //Date hooks
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  //Modal hooks
  const [modalStatus, setModalStatus] = useState(null);
  const [open, setOpen] = useState(false);

  //This hook helps grabbing the alarm
  const [selectedAlarm, setSelectedAlarm] = useState({});

  const [totalPages, setTotalPages] = useState(-1);

  const [filter, setFilter] = useState(filterInitialVal);

  //Alarm Status Options Hook
  const [alarmStatusList, setAlarmStatusList] = useState([]);

  //Checkbox state
  const [isChecked, setIsChecked] = useState(false);
  
  const initialRender = useRef(true);
  const prevFilter = useRef(filter);
  const prevIsChecked = useRef(isChecked);

  //Turning
  const ALARM_TYPE_OPTIONS = ALARM_TYPE.map(alarmType => {
    return {
      key: alarmType.key,
      value: alarmType.value,
      text: props.t(alarmType.text)
    }
  })

  //getAllAlarms is a method calling alarm get request on backend
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    if (prevIsChecked.current !== isChecked) {
      prevIsChecked.current = isChecked;
      getAllAlarms();
      return;
    }

    const filterChanged = JSON.stringify(prevFilter.current) !== JSON.stringify(filter);
    if (filterChanged) {
      prevFilter.current = filter;
      getAllAlarms();
    }
  }, [filter, isChecked]);

  useEffect(() => {
    getAlarmStatusList().
      then(res => {
        const data = res.data.map(alarmStatus => {
          return {
            key: alarmStatus.key,
            value: alarmStatus.value,
            text: props.t(alarmStatus.text)
          }
        })
        setAlarmStatusList(data);
      }).catch(err => {
        showError(`${alarmsMessages().NOT_GET_ALARM_STATUS_LIST} ${err?.response?.data?.message ?? err.toString()}`);
      });
  }, [localStorage.getItem('lang') || 'en']);

  const handleDropdown = (_e, { value, name }) => {
    setFilter(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const onChangeStartDate = date => {
    setStartDate(date);
    date = moment(date).format('YYYY-MM-DDTHH:mm:ss');

    setFilter(prevState => ({
      ...prevState,
      'eventTime': date
    }));
  }

  const onChangeEndDate = date => {
    setEndDate(date);
    date = moment(date).format('YYYY-MM-DDTHH:mm:ss');

    setFilter(prevState => ({
      ...prevState,
      'alarmEndDate': date
    }));
  }

  const modalChangeStatus = (_event, data) => {
    setModalStatus(data.value);
  }

  const getAllAlarms = (pageValue, sizeValue) => {
    const body = {
      page: page === undefined ? page : pageValue,
      size: sizeValue || size,
      filterNA: isChecked
    }

    Object.keys(filter).forEach(key => {
      setLoading(true);

      if (filter[key]?.length) {
        if (key === 'status' || key === 'type') {
          body[key] = 'in:' + filter[key].toString()
        } else if (key === 'summary') {
          body[key] = 'like:' + filter[key].toString()
        } else if (key === 'eventTime') {
          body[key] = 'gte:' + filter[key]
        } else if (key === 'alarmEndDate') {
          body[key] = 'lte:' + filter[key]
        } else {
          //nothing to do
        }
      }
    })

    getAlarmList(body)
      .then(res => {
        //Setting pageable: page size and page number
        setSize(res.data.size);
        setPage(res.data.number);
        setTotalPages(res.data.totalPages);

        //Capturing alarms from promise
        setAlarms(res.data.content);
      })
      .catch(err => {
        showError(` ${alarmsMessages().NOT_FETCH_ALARM_LIST} : ${err?.response?.data?.message ?? err.toString()} `);
      })
      .finally(() => setLoading(false));
  };

  const handleResetButton = event => {
    event.preventDefault();
    setFilter(filterInitialVal);
    setStartDate(null);
    setEndDate(null);
    setIsChecked(false);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };


  const alarmTable = useCallback(() =>
    <ReactTableV8
      columns={columns}
      data={alarms}
      loadingProp={loading}

      //Manual Pagination
      manualPaginationProp={true}
      pageIndexProp={page}
      pageSizeProp={size}
      onPaginationChangeProp={(pageValue, sizeValue) => {
        getAllAlarms(pageValue, sizeValue);
      }}
      totalPagesProp={totalPages}
    />
    , [alarms, columns, loading, size, page]
  )

  const onClickSaveUpdatedAlarm = dataParam => {
    setLoading(true);
    updateAlarm(selectedAlarm.id, dataParam)
      .then(() => {
        showSuccess(SUCCESS_MESSAGE());
        getAllAlarms();
        setModalStatus(null);
        setOpen(false);
      })
      .catch(err => {
        showError(` ${alarmsMessages().NOT_UPDATED_ALARM} : ${err?.response?.data?.message ?? err.toString()} `);
      })
      .finally(() => setLoading(false));
  }

  return (
    <>
      <div className='main-container'>
        <div className='main-left-container'>
          <div className='alarms-filter'>
            <Form>
              <h1>{ALARMS_HEADER()}</h1>
              <Form.Field>
                <label>{alarmsMessages().SUMMARY_HEADER}</label>
                <DebouncedInput
                  iconProp={true}
                  name={'summary'}
                  valueProp={filter?.summary}
                  onChangeProp={value => setFilter(prevState => ({
                    ...prevState,
                    summary: value
                  }))}
                  fluidProp={true}
                  className='debounce'
                  debounceProp={DEBOUNCE_WAIT.LONG}
                  placeHolderProp={alarmsMessages().ALARM_SUMMARY_PLACEHOLDER}
                />
              </Form.Field>
              <Form.Field>
                <label>{STATUS_HEADER()}</label>
                <Dropdown
                  style={{ wordBreak: 'break-all' }}
                  fluid
                  search
                  multiple
                  selection
                  clearable
                  value={filter?.status}
                  placeholder={alarmsMessages().ALARM_STATUS_PLACEHOLDER}
                  name={'status'}
                  options={alarmStatusList}
                  onChange={handleDropdown}
                />
              </Form.Field>

              <Form.Field>
                <label>{TYPE_LABEL()}</label>
                <Dropdown
                  style={{ wordBreak: 'break-all' }}
                  fluid
                  search
                  multiple
                  selection
                  clearable
                  value={filter?.type}
                  placeholder={alarmsMessages().ALARM_TYPE_PLACEHOLDER}
                  name={'type'}
                  options={ALARM_TYPE_OPTIONS}
                  onChange={handleDropdown}
                />
              </Form.Field>

              <Form.Field inline>
                <label>{START_DATE_LABEL()}</label>
                <DatePicker
                  id='startDate'
                  onKeyDown={e => {
                    e.preventDefault();
                  }}
                  selected={startDate}
                  onChange={date => {
                    onChangeStartDate(date);
                  }}
                  dateFormat='yyyy/MM/dd HH:mm:ss'
                  showTimeSelect
                  timeFormat='HH:mm:ss'
                  timeIntervals={15}
                  startDate={startDate}
                  endDate={endDate}
                  name='startDate'
                  maxDate={new Date()}
                  //TODO: maxTime
                />
              </Form.Field>

              <Form.Field inline>
                <label>{END_DATE_LABEL()}</label>
                <DatePicker
                  selected={endDate}
                  onChange={date => {
                    onChangeEndDate(date);
                  }}
                  onKeyDown={e => {
                    e.preventDefault();
                  }}
                  dateFormat='yyyy/MM/dd HH:mm:ss'
                  showTimeSelect
                  timeFormat='HH:mm:ss'
                  timeIntervals={15}
                  startDate={startDate}
                  endDate={endDate}
                  name='endDate'
                  minDate={startDate}
                  //TODO: minTime
                  //TODO: maxTime

                />
              </Form.Field>

              <Segment id='filter-na-segment'>
                <Form.Field>
                  <Form.Checkbox label={alarmsMessages().FILTER_NA_VALUES} onChange={handleCheckboxChange} checked={isChecked} fluid name='filterNa'/>
                </Form.Field>
              </Segment>
            </Form>
            <Button
                className='alarm-filter-reset'
                style={{ textAlign: 'center', marginTop: '1rem' }}
                icon={SEARCH_ICON}
                fluid
                color='gray'
                size='medium'
                onClick={handleResetButton}
              >
                {RESET_TABLE_BUTTON()}
              </Button>
          </div>
        </div>

        <div className='main-right-container'>
          <div className='main-right-header'>
            <h2> {ALARMS_HEADER()}</h2>
          </div>
          {alarmTable()}
        </div>

        <div>
          <Modal
            closeIcon
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
          >
            <Header icon={ARCHIVE_ICON} content={alarmsMessages().EDIT_ALARM_HEADER} />
            <Modal.Content>
              <Form.Field>
                <label>{alarmsMessages().ALARM_STATUS_LABEL}</label>
                <Dropdown
                  style={{ wordBreak: 'break-all' }}
                  fluid
                  search
                  selection
                  loading={loading}
                  disabled={loading}
                  clearable
                  placeholder={alarmsMessages().CHANGE_STATUS_PLACEHOLDER}
                  name={'modalStatus'}
                  options={alarmStatusList}
                  onChange={modalChangeStatus}
                />
              </Form.Field>
            </Modal.Content>
            <Modal.Actions>
              <Button
                color='red'
                disabled={loading}
                onClick={() => {
                  setOpen(false);
                  setModalStatus(null);
                }}
              >
                <Icon name={REMOVE_ICON} /> {CANCEL_BUTTON()}
              </Button>
              <Button
                disabled={!modalStatus || loading}
                loading={loading}
                color='green'
                onClick={() => onClickSaveUpdatedAlarm({ status: modalStatus })}
              >
                <Icon name={CHECKMARK_ICON} /> {SAVE_BUTTON()}
              </Button>
            </Modal.Actions>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default withTranslation()(Alarms);
