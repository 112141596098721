import React, { Component } from 'react';
import { Button, Dropdown, Form, Header, Modal } from 'semantic-ui-react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import { extendMoment } from 'moment-range';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../styles.css';
import axios from 'axios';
import 'moment/locale/en-gb';
import 'moment/locale/tr';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { showError, showSuccess } from '../utils/ToastHelpers';
import CancelReservationModal from './CancelReservationModal';
import {getUserSummaryList, getReservations, getReservationTypeList} from '../api/apiCalls';
import {getUserDetails} from '../utils/Methods';
import {
  CONFIRM_BUTTON,
  DAY_LABEL,
  ERROR_CODE_MESSAGE,
  GROUP_LABEL,
  MONTH_LABEL,
  NEXT_LABEL,
  NOT_FETCH_USERS,
  OFFLINE_LABEL,
  PREVIOUS_LABEL,
  reservationMessages,
  RESERVATION_LABEL,
  SUCCESS_MESSAGE,
  TODAY_LABEL,
  WEEK_LABEL
} from '../utils/UIMessages';
import { CHECK_ICON } from '../utils/UiIcons';
import { PrivilegeConstants } from '../utils/PrivilegeConstants';

const localizer = momentLocalizer(moment);
const momentRange = extendMoment(moment);

class ReservationModal extends Component {
  state = {
    events: [],
    dropdownReasons: [],
    secondModalShow: false,
    selectedReason: 'WRONG',
    selectedReservation: '',
    reservationTypeModalOpen: false,
    reservationTypes: [],
    selectedType: 'NORMAL',
    selectedTypeValue: 'Normal',
    startTime: '',
    endTime: '',
    dropdownUsers: [],
    selectedUsername: null,
    reservableGroupList: [],
    selectedGroup: null,
    reservedBy: 'user'
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.device !== this.props.device && !this.props.showModalEdit) {
      this.setState({ events: [] });
      this.fetchReservationData(this.props.device);
    }
    if (prevProps.showModal !== this.props.showModal && prevProps.device === this.props.device) {
      this.setState({ events: prevState.events });
    }
    // fetch reservation types first time modal is opened
    if (this.props.showModal && this.props.showModal !== prevProps.showModal) {
      this.getReservationTypes().then(() => {
        this.state.reservationTypes.map((reservationType) => {
          if (reservationType.key === "OFFLINE") {
            this.getUserListDropdown()
          }
        })
      });
    }

    if (this.props.privileges.includes(PrivilegeConstants.RESERVATION.NORMAL) && this.props.showModal !== prevProps.showModal && this.props.showModal) {
      this.getReservableGroups();
    }

    if (
      this.state.reservationTypeModalOpen !== prevState.reservationTypeModalOpen &&
      !this.state.reservationTypeModalOpen
    ) {
      this.setState({ selectedGroup: null });
    }
  }

  componentDidMount() {
    if (this.props.isInDeviceManage) {
      this.fetchReservationData(this.props.device);
    }
  }

  getReservationTypes = async () => {
    try {
      const res = await getReservationTypeList(this.props.device.deviceId);
      const reservationTypes = Object.entries(res.data)
          .map(([k, v]) => {
            return {
              key: k,
              value: v,
              text: this.props.t(v)
            };
          });
      this.setState({reservationTypes});
    } catch (err) {
      showError(`${reservationMessages().RESERVATION_TYPE_ERROR} ${err?.response?.status}`);
    }
  };

  getUserListDropdown = async () => {
    try {
      const res = await getUserSummaryList();
      const dropdownUsers = res.data.map((user) => ({
        key: user.userName,
        value: user.userName,
        text: user.userName
      }));
      this.setState({ dropdownUsers });
    } catch (err) {
      showError(`${NOT_FETCH_USERS()} ${err?.response?.status}`);
    }
  };

  getReservableGroups = () => {
    const userDetails = getUserDetails();
    const activeGroup = parseInt(localStorage.getItem('activeGroup'));
    if (this.props.getReservableGroupList) {
      this.props.getReservableGroupList().then(() => {
        const reservableGroupList = this.props.reservableGroupList
          ?.filter(
            (group) =>
              this.props.device.reservableGroups.includes(group.id) &&
              (userDetails.userName === 'admin' || group.reservationMemberIdSet.some((id) => id === userDetails.id))
              && group.id === activeGroup
          )
          .map((g) => {
            return {
              key: g.id,
              value: g.id,
              text: g.name
            };
          });
        this.setState({ reservableGroupList });
      });
    }
  };

  getReservationTitle = (groupName, username, updateReason, type) => {
    let title = groupName ? `Group : ${groupName}` : username;
    if (type === 'OFFLINE') {
      title += ` (${OFFLINE_LABEL()}`;
    }
    if (updateReason) {
      title += `, ${reservationMessages().DELETED_LABEL}, ${reservationMessages().REASON_LABEL}: ${
        reservationMessages(updateReason).UPDATE_REASON_LABEL
      }`;
    }
    return title;
  };

  fetchReservationData = (device) => {
    if (device.deviceId) {
      const params = {
        device: device.deviceId
      };
      getReservations(params)
        .then((res) => {
          res.data?.map((reservationDetails) => {
            let endTime;
            if (reservationDetails.updateTime) {
              endTime = reservationDetails.updateTime;
            } else {
              endTime = reservationDetails.endTime;
            }
            const addOneSecond = new Date(endTime);
            addOneSecond.setSeconds(addOneSecond.getSeconds() + 1);
            this.setState({
              events: [
                ...this.state.events,
                {
                  start: new Date(reservationDetails.startTime),
                  end: addOneSecond,
                  title: this.getReservationTitle(
                    reservationDetails.groupName,
                    reservationDetails.username,
                    reservationDetails.updateReason,
                    reservationDetails.type
                  ),
                  updateReason: reservationDetails.updateReason,
                  id: reservationDetails.id,
                  type: reservationDetails.type
                }
              ]
            });
          });
        })
        .catch((err) => {
          showError(
            `${reservationMessages().RESERVATION_DETAILS_FETCH_ERROR} ${err.response && err?.response?.status}`
          );
        });
    }
  };
  // var olan reservasyonda değişiklik yaparken
  onSelectEvent = (e) => {
    if (!e.updateReason) {
      this.setState({
        secondModalShow: true,
        selectedReservation: e
      });
    }
  };

  // yeni reservasyon yaparken
  checkOverlap = ({ start, end }) => {
    let { events } = this.state;
    events = events.filter((e) => !e.updateReason);
    let isOverlap = false;
    const range1 = momentRange.range(start, end);
    events.map((el) => {
      const range2 = momentRange.range(el.start, el.end);
      if (range1.overlaps(range2, { adjacent: false })) {
        isOverlap = true;
      }
    });
    if (!isOverlap) {
      if(this.state.reservationTypes?.length !== 0){
        this.setState({
          startTime: start,
          endTime: end,
          reservationTypeModalOpen: true
        });
      } else {
        this.onSelectSlot(start, end, 'NORMAL');
      }
    }
  };

  confirmReservationType = () => {
    this.onSelectSlot(this.state.startTime, this.state.endTime, this.state.selectedType);
  };

  onSelectSlot = (start, end, type) => {
    const { deviceId } = this.props.device;

    let startTime;
    let endTime;
    if (start === end) {
      startTime = moment(start).format();
      endTime = moment(end).add(1, 'day').subtract(1, 'seconds').format();
    } else if (start <= Date.now() && end > Date.now()) {
      startTime = moment(Date.now()).add(3, 'seconds').format();
      endTime = moment(end).subtract(1, 'seconds').format();
    } else {
      startTime = moment(start).format();
      endTime = moment(end).subtract(1, 'seconds').format();
    }
    let data = {
      startTime,
      endTime,
      deviceId
    };
    if (this.state.selectedUsername) {
      data.username = this.state.selectedUsername;
    }
    if (this.state.selectedGroup) {
      data.groupName = this.state.selectedGroup?.text;
    }
    data = JSON.stringify(data);
    const headers = { 'Content-Type': 'application/json' };
    let requestUrl = '/api/v1/devices/reservation';
    if (type === 'OFFLINE') {
      requestUrl += '/offline';
    } else if (type === 'GROUP') {
      requestUrl += '/group';
    }
    axios
      .post(requestUrl, data, { headers })
      .then((response) => {
        this.setState(
          {
            events: [
              ...this.state.events,
              {
                start: new Date(startTime),
                end: new Date(endTime),
                title: response.data.groupName
                  ? `${GROUP_LABEL()} : ${response.data.groupName}`
                  : response.data.username,
                id: response.data.id,
                type: response.data.type
              }
            ],
            reservationTypeModalOpen: false
          },
          () => {
            showSuccess(SUCCESS_MESSAGE());
          }
        );
      })
      .catch((err) => {
        if (err.response.data.message.includes('ileri')) {
          showError(`${reservationMessages().FUTURE_DATE_ERROR} : ${err.response && err?.response?.status}`);
        } else {
          showError(`${this.props.t(err.response?.data?.message)}, ${ERROR_CODE_MESSAGE()}: ${err?.response?.status}`);
        }
      });
  };

  onChangeDropdown = (_e, { value }) => this.setState({ selectedReason: value });

  onChangeType = (_e, data) => {
    const value = data.value;
    const key = data.options.find(o => o.value === value).key;
    this.setState({
      selectedType: key,
      selectedTypeValue: value,
      selectedUsername: null,
      selectedGroup: null,
    });
  }

  onChangeUsername = (_e, { value }) => this.setState({ selectedUsername: value });

  afterDeleteReservation = () => {
    const id = this.state.selectedReservation?.id;
    this.setState(
      (prevState) => {
        const events = [...prevState.events];
        const idx = events.findIndex((ev) => ev.id === id);
        events.splice(idx, 1);
        return { events };
      },
      () => this.setState({ secondModalShow: false })
    );
  };

  calendarStyle = (event) => {
    if (event.type === 'OFFLINE') {
      return {
        style: {
          backgroundColor: '#ff0000',
          opacity: '.35'
        }
      };
    } else if (event.updateReason) {
      return {
        style: {
          backgroundColor: '#ea9905',
          opacity: '.35'
        }
      };
    } else {
      return null;
    }
  };

  onChangeGroup = (data) => {
    const selectedGroup = data.options.find((group) => group.value === data.value);
    this.setState({ selectedGroup });
  };

  render() {
    return (
      <div>
        <Modal
          open={this.props.showModal}
          onClose={() => {
            this.props.onCloseModal();
          }}
          closeOnEscape={true}
          size="large"
          closeIcon>
          {/* Agenda */}
          <Modal.Header style={{ textAlign: 'center' }}>{RESERVATION_LABEL()}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Header textAlign={'center'}>
                {this.props.device && this.props.device.deviceId} / {this.props.device && this.props.device.deviceModel}
              </Header>
              <div>
                <Calendar
                  step={15}
                  selectable
                  localizer={localizer}
                  eventPropGetter={this.calendarStyle}
                  events={this.state.events}
                  defaultView={Views.MONTH}
                  defaultDate={new Date()}
                  onSelectEvent={this.onSelectEvent}
                  onSelectSlot={this.checkOverlap}
                  culture={this.props.i18n.language}
                  messages={{
                    next: NEXT_LABEL(),
                    previous: PREVIOUS_LABEL(),
                    today: TODAY_LABEL(),
                    month: MONTH_LABEL(),
                    week: WEEK_LABEL(),
                    day: DAY_LABEL(),
                    agenda: reservationMessages().AGENDA_LABEL,
                    noEventsInRange: reservationMessages().NO_EVENT_IN_RANGE
                  }}
                />
              </div>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions></Modal.Actions>
        </Modal>

        <CancelReservationModal
          open={this.state.secondModalShow}
          onClose={() => this.setState({ secondModalShow: false })}
          afterDeleteCallback={this.afterDeleteReservation}
          id={this.state.selectedReservation?.id}
        />

        <Modal
          open={this.state.reservationTypeModalOpen}
          onClose={() => this.setState({ reservationTypeModalOpen: false })}
          size="small"
          closeOnEscape={true}
          closeIcon
          centered={false}>
          <Modal.Header>{reservationMessages().RESERVATION_TYPE_HEADER}</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Field>
                <label>{reservationMessages().RESERVATION_TYPE_PLACEHOLDER}</label>
                <Dropdown
                  selection
                  fluid
                  options={this.state.reservationTypes}
                  placeholder={reservationMessages().RESERVATION_TYPE_PLACEHOLDER}
                  value={this.state.selectedTypeValue}
                  onChange={this.onChangeType}
                />
              </Form.Field>
              {this.state.selectedType === 'GROUP' && this.state.reservableGroupList?.length > 0 &&
              <>
                <Form.Field>
                        <Dropdown
                          fluid
                          selection
                          placeholder={reservationMessages().SELECT_GROUP_PLACEHOLDER}
                          options={this.state.reservableGroupList}
                          value={this.state.selectedGroup?.value}
                          onChange={(_e, data) => this.onChangeGroup(data)}
                        />
                      </Form.Field>
                  </>
                }
              {this.state.selectedType === 'OFFLINE' && (
                <Form.Field>
                  <label>
                    {reservationMessages().SELECT_USER_LABEL} ({reservationMessages().OPTIONAL_LABEL})
                  </label>
                  <Dropdown
                    selection
                    fluid
                    options={this.state.dropdownUsers}
                    placeholder={reservationMessages().SELECT_USER_LABEL}
                    value={this.state.selectedUsername}
                    onChange={this.onChangeUsername}
                  />
                </Form.Field>
              )}
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button icon={CHECK_ICON} content={CONFIRM_BUTTON()} color="green" onClick={this.confirmReservationType} />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(ReservationModal);
