import React, {Component} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import AppSourceTree from '../../components/AppSourceTree';
import SelectedElementPanel from '../../components/SelectedElementPanel';
import ActionsPanel from '../../components/ActionsPanel';
import RecordedActions from '../../utils/client-frameworks/RecordedActions';
import {Flip, toast} from 'react-toastify';
import {withRouter} from 'react-router-dom';
import {
    Button,
    Dimmer,
    Dropdown,
    Header,
    Icon,
    Input,
    Modal,
    Placeholder,
    Popup,
    Segment,
    Table,
    Grid,
    Loader
} from 'semantic-ui-react';
import HighlighterRects from '../../components/HighlighterRects';
import copy from 'copy-to-clipboard';
import {isUnique, xmlToJSON, parseSource, downloadXML} from '../../utils/Methods';
import { switchContext, getSessionSource, getSessionSourceEmbedded, getSync, getElementId, getRect } from '../../api/apiCalls';
import {
    fetchScreenImageViaAppium,
    getElementLocation,
    getElementSize,
    sendKeysToElement
} from '../../utils/AppiumClient'
import {connect} from 'react-redux'
import {endFullLoad, startFullLoad, setChosenAppMode} from '../../actions'
import {withTranslation} from 'react-i18next';
import {
    APPIUM_REQUEST_PREFIX,
    APPIUM_SESSION_ID,
    DEVICEOS,
    INSPECTOR_MODES,
    SELECTION_STRATEGIES
} from '../../utils/Constants'
import {showError, showSuccess} from '../../utils/ToastHelpers';
import { ACTIONS_HEADER, BACK_BUTTON, CANCEL_BUTTON, CLEAR_BUTTON, DETAILS_LABEL, DONE_BUTTON, INSPECTOR_HEADER, manageInspectorMessages, NO_LABEL, SEARCH_BUTTON, SELECTOR_LABEL, SEND_KEYS_BUTTON, TAKING_SCREENSHOTS_LOADER, YES_LABEL } from '../../utils/UIMessages';
import { EYE_ICON, FILE_CODE_ICON, KEY_ICON, LIST_ICON, PAUSE_ICON, REFRESH_ICON, SEARCH_ICON, COPY_ICON, DOWNLOAD_ICON} from '../../utils/UiIcons';
import { htmlToJSON } from '../manage/util';


class ManageInspector extends Component {

    state = {
        sessionId: '',
        desiredCapabilities: {},
        sourceJSON: [],
        selectedElement: {},
        test_type: 'Manual',
        refresh_loading: true,
        element_selected: false,
        element_id: '',
        send_key_mode: false,
        element_search_mode: false,
        quit_inspector_mode: false,
        key_to_send: '',
        elementTapLoading: false,
        elementSendKeysLoading: false,
        elementClearLoading: false,
        inspectorStarted: false,
        recorderStarted: false,
        isRecording: false,
        includeBoilerPlate: false,
        elementSearchStrategy: 'id',
        elementSearchLocator: '',
        elementSearchLoading: false,
        searchElementResult: [],
        elementSearchFinished: false,
        selectedSearchElementID: '',
        foundElementPath: '',
        //tapByElementsActive: false
        inspectorUpToScreenDirection: 1,
        applicationMode: 'native',
        keepAutomationAliveInterval: ''
    };

    componentDidMount() {
        this.props.onRef(this);
        this.setState({ frozenInspectorScreenShoot: this.props.icon });
        this.setState({ actionOrSourceDivisionShow: "Source" });
        this.setState({applicationMode: 'native'});
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
        this.killSession(this.state.sessionId).then(() => {
            this.setInspectorClosed();
        })
    }

    startInspector() {
        this.props.startFullLoad(` ${INSPECTOR_HEADER()}`)
        this.init();
        this.props.inspectorStarted()
    }

    setInspectorClosed = () => {
        this.setState({inspectorStarted: false, test_type: 'Manual', sessionId: ''});
        localStorage.removeItem(APPIUM_SESSION_ID)
        this.props.clearAppiumSessionId();
        clearInterval(this.state.keepAutomationAliveInterval);
    }

    init = async() =>{
        let promiseObject;
        if (this.props.embedMode){
            const token = this.props.match.params;
            const headers = {'Embedded-Screen-Key': `${token.token}`}
            promiseObject = axios.get(`/api/devices/${this.props.id}`,{headers})
        } else {
            promiseObject = axios.get(`/api/devices/${this.props.id}`)
        }
        
        try{
            const response = await promiseObject;
            this.setState({
                capabilities: {
                    platformName: response.data.os,
                    "appium:platformVersion": response.data.oSVersion,
                    "appium:version": response.data.oSVersion,
                    "appium:udid": response.data.deviceId,
                    "appium:deviceName": response.data.deviceId,
                    "appium:noReset": true,
                    "appium:newCommandTimeout": 120,
                    "appium:automationName": response.data.os.toLowerCase() === "android" ? "uiautomator2" : "xcuitest"
                }
            });

            this.setState({desiredCapabilities: this.state.capabilities});

            if(this.state.test_type === "Automation"){
                if(localStorage.getItem(APPIUM_SESSION_ID) !== null){
                    // device test type is Automation and we have a not killed Appium session
                    this.setState({sessionId: localStorage.getItem(APPIUM_SESSION_ID)});
                    this.initializeInspector();
                }
                else{
                    showError(manageInspectorMessages().NOT_START_INSPECTOR_MESSAGE);
                    this.setState({refresh_loading: false});
                }
            }
            else{
                this.initializeInspector();
            }
        }catch(error){
            showError(`${manageInspectorMessages().DEVICE_SPECS_NOT_FETCH} ${error.response?.data?.message ?? error.toString()}`);
            this.props.endFullLoad()
        }
    }

    async initializeInspector(){
        let desiredCaps = this.state.desiredCapabilities
        if(desiredCaps !== {}){
            if(this.state.sessionId === ""){
                toast.info(manageInspectorMessages().NEW_SESSION_START_INFO)
                this.initDriver().catch(err => {
                        showError(`${manageInspectorMessages().NOT_START_NEW_APPIUM_SESSION_ERROR_MESSAGE} ${err.toString()}`);
                        this.props.endFullLoad()
                    })
            }
            else{
                // fetch source with given session Id
                this.getSource(this.state.sessionId, "fetchSource");
                this.setState({inspectorStarted: true});
            }
        }
        else{
            showError(manageInspectorMessages().NOT_START_APPIUM_WITH_THIS_DEVICE_PROPERTY_ERROR_MESSAGE, {
                autoClose: false,
                closeOnClick: false,
                draggable: false,
                transition: Flip
            });
            this.props.endFullLoad()
        }
    }

      initDriver = async() =>{
        let promiseObject;
        let desiredCapabilities = this.state.desiredCapabilities
        const contentType = {"Content-Type": "application/json;charset=UTF-8"};
        if (this.props.embedMode){
            const token = this.props.match.params;
            const headers = {'Embedded-Screen-Key': `${token.token}`, ...contentType}
            promiseObject = axios.post(APPIUM_REQUEST_PREFIX, {desiredCapabilities, capabilities: {alwaysMatch: this.state.capabilities, firstMatch: [{}]}}, {headers});
        } else {
            promiseObject = axios.post(APPIUM_REQUEST_PREFIX, {desiredCapabilities, capabilities: {alwaysMatch: this.state.capabilities, firstMatch: [{}]}}, { headers: contentType })
        }
     
        try{
            const res = await promiseObject;
            let screenSize = res.data.value.capabilities.deviceScreenSize
            if(screenSize){
                //Android Devices
                const orientation = await this.getDeviceOrientation(res.data.value.sessionId);
                const [w, h] = screenSize.split('x');
                if (orientation === 'LANDSCAPE') { //PROMISE: Android Landscape Mode Inspector
                    this.sendScreenResolutionToManagePage([h, w]);
                }else{
                    this.sendScreenResolutionToManagePage([w, h]);
                }
            } else {
                // IOS Devices
                const orientationIOS = await this.getDeviceOrientation(res.data.value.sessionId);
                await this.getScreenSize(res.data.value.sessionId).then(res2 => {
                    const [w, h] = [res2?.data?.value?.width, res2?.data?.value?.height];
                    if (orientationIOS==='LANDSCAPE') { //PROMISE: IOS LANDSCAPE MODE INSPECTOR 
                        this.sendScreenResolutionToManagePage([h, w]);
                    } else {
                        this.sendScreenResolutionToManagePage([w, h]);
                    }                   
                }).catch(err => {
                    showError(manageInspectorMessages().NOT_GETTING_DEVICE_ORIENTATION_VIA_APPIUM_ERROR_MESSAGE)
                    console.log(`Error in getting IOS DEVICE screen size via Appium: ${err}`)
                })
            }
            console.log(`New Appium session started with sessionId: ${res.data.value.sessionId}`)
            this.props.setAppiumSessionId(res.data.value.sessionId);

            //Keep session alive
            const keepAutomationAliveInterval = setInterval(() => this.getDeviceOrientation(res.data.value.sessionId), 15000);
            this.setState({keepAutomationAliveInterval: keepAutomationAliveInterval});

            this.setState({ sessionId: res.data.value.sessionId }, () => {
                localStorage.setItem(APPIUM_SESSION_ID, res.data.value.sessionId)
                this.refreshAppSource('fetchSource');
            });
            }catch(err){
                let errorMessage = manageInspectorMessages().APPIUM_DRIVER_NOT_INITIALIZED_ERROR_MESSAGE
                if(err.response){
                    let msg = err?.response?.data?.value?.message
                    let httpStatus = err?.response?.status
                    let statusText = err?.response?.statusText
                    errorMessage += `${DETAILS_LABEL()}: ${statusText} ${httpStatus}. ${msg}.`
                }
                else{
                    errorMessage += `${DETAILS_LABEL()}: ${err}.`
                }
                showError(errorMessage);
                this.props.endFullLoad()
            }
    }



    async getScreenSize(sessionId){
        return axios.get(`${APPIUM_REQUEST_PREFIX}/${sessionId}/window/current/size`)           
    }

    async getDeviceOrientation(sessionId) {
      try {
        const res = await axios.get(`${APPIUM_REQUEST_PREFIX}/${sessionId}/orientation`);
        return res.data?.value;
      } catch (err) {
        showError(manageInspectorMessages().NOT_GETTING_DEVICE_ORIENTATION_VIA_APPIUM_ERROR_MESSAGE + `: ${err}`);
        clearInterval(this.state.keepAutomationAliveInterval);
        return null;
      }
    }

    async killSession(sessionId){
        return axios.delete(`${APPIUM_REQUEST_PREFIX}/${sessionId}`)
        .then(() => {
            console.log(`Appium session with sessionId ${sessionId} is killed.`)
            /*
            if(this.props.inspectorMode !== INSPECTOR_MODES.MANUAL){
                // starts a new web socket
                this.props.handleChangeInspectorMode(INSPECTOR_MODES.MANUAL)
            }
            else{
                 // reset (kill current and start a new one) web socket before leaving Inspector
                // this is required in order to set device status to "manual" instead of "automation".
                this.props.resetWebSocket()
            }*/
        })
        .catch(() => {
            console.log(`Appium session with sessionId ${sessionId} could not be killed.`)
        }).finally(() => {
            clearInterval(this.state.keepAutomationAliveInterval);
        })
    }

    async getSyncWebView(sessionId, body) {
        return await getSync(sessionId, body);
    }

    async getElementIdWebView(sessionId) {
        let requestBody = {
            using: "class name",
            value: "android.webkit.WebView"
        }
        try {
            const elementResponse = await getElementId(sessionId, requestBody);
            return elementResponse?.data?.value?.ELEMENT;
        } catch (err) {
            console.log(err)
        }
    }

    async getRectWebView(sessionId, elementId) {
        try {
            const rectReponse = await getRect(sessionId, elementId);
            return rectReponse?.data?.value;
        } catch (err) {
            console.log(err)
        }
    }

    async getWebviewElement(sessionId) {
        await switchContext(sessionId, 'NATIVE_APP')
        let syncBody = {
            "args": [],
            "script": "mobile:getContexts"
        }
        await this.getSyncWebView(sessionId, syncBody);
        const elementId = await this.getElementIdWebView(sessionId);
        let xOffsetValue;
        let yOffsetValue;
        const res = await this.getRectWebView(sessionId, elementId);
        await switchContext(sessionId, 'WEBVIEW_chrome')
        xOffsetValue = res?.x;
        yOffsetValue = res?.y;
        let body = {
            "args": [
                {
                    "isAndroid": this.props.deviceOs === 'ANDROID',
                    "webviewLeftOffset": xOffsetValue ?? 0,
                    "webviewTopOffset": yOffsetValue ?? 0
                }
            ],
            "script": "return (function setHtmlElementAttributes(obj) {\n  var isAndroid = obj.isAndroid,\n    webviewTopOffset = obj.webviewTopOffset,\n    webviewLeftOffset = obj.webviewLeftOffset;\n  var htmlElements = document.body.getElementsByTagName('*');\n  // iOS uses CSS sizes for elements and screenshots, Android sizes times DRP\n  // for other platforms, use default DRP of 1\n  var dpr = isAndroid ? window.devicePixelRatio : 1;\n  Array.from(htmlElements).forEach(function (el) {\n    var rect = el.getBoundingClientRect();\n    el.setAttribute('data-appium-inspector-width', Math.round(rect.width * dpr));\n    el.setAttribute('data-appium-inspector-height', Math.round(rect.height * dpr));\n    el.setAttribute('data-appium-inspector-x', Math.round(webviewLeftOffset + rect.left * dpr));\n    el.setAttribute('data-appium-inspector-y', Math.round(webviewTopOffset + rect.top * dpr));\n  });\n}).apply(null, arguments)"
        }
       await this.getSyncWebView(sessionId, body);
    }

    async getSource(sessionId, actionType) {
        let promiseObject;
        
        if(this.state.applicationMode === 'web_hybrid'){
            await this.getWebviewElement(sessionId);
        }
        if (this.props.embedMode) {
            const token = this.props.match.params;
            const headers = { 'Embedded-Screen-Key': `${token.token}` }
            promiseObject = getSessionSourceEmbedded(sessionId, headers);
        } else {
            promiseObject = getSessionSource(sessionId);
        }

        try {
            let res = await promiseObject;
            try {
                // source as JSON will be used in Tree View and Selected Element Panel
                this.setState({
                    sourceXML: res.data.value,
                    sourceJSON: this.state.applicationMode === 'native' ? xmlToJSON(res.data.value) : htmlToJSON(parseSource(res.data.value)),
                    refresh_loading: false,
                    inspectorStarted: true
                });

                if (actionType === 'fetchSource') {
                    showSuccess(manageInspectorMessages().SOURCE_FETCH_SUCCESSFUL);
                }
                this.props.endFullLoad()
            }
            catch (convertingError) {
                showError(`${manageInspectorMessages().APP_SOURCE_NOT_FETCH_BECAUSE_ERROR_MESSAGE} ${convertingError}`);
                this.setState({ refresh_loading: false });
                this.props.endFullLoad()
            }

        } catch (err) {
            let errorMessage = manageInspectorMessages().APP_SOURCE_NOT_FETCH
            if (err.response) {
                let msg = err?.response?.data?.value?.message
                let httpStatus = err?.response?.status
                let statusText = err?.response?.statusText
                errorMessage += `${DETAILS_LABEL()}: ${statusText} ${httpStatus}. ${msg}.`
            }
            else {
                errorMessage += `${DETAILS_LABEL()}: ${err}.`
            }
            showError(errorMessage);
            this.setState({ refresh_loading: false });
            this.props.endFullLoad()
        }
    }

    refreshAppSource = (actionType) => {
        //Frozen Inspector Image State
        this.setState({ frozenInspectorScreenShoot: this.props.icon });
        this.setState({ refresh_loading: true, element_selected: false });
        // Check if there is an alive Appium Session with given session id.
        // if there is an Appium Session alive
        this.getSource(this.state.sessionId, actionType).then(() => {
            this.sendAppSourceToManagePage(this.state.sourceJSON);
            if (actionType === 'appiumAction') {
                this.fetchScreenImageAppium()
            }
        }).catch(() => {
            // if there is no Appium Session alive with given session id.
            toast.info(manageInspectorMessages().NEW_SESSION_START_INFO)
            this.initDriver().then(() => {
                toast.info(manageInspectorMessages().NEW_SESSION_STARTED_MESSAGE)
                let mode = this.state.applicationMode === 'native' ? 'NATIVE_APP' : `WEBVIEW_chrome`; //This needs to change in the futu
                switchContext(this.state.sessionId, mode);
                this.getSource(this.state.sessionId, actionType).then(() => {
                    this.sendAppSourceToManagePage(this.state.sourceJSON);
                    if (actionType === 'appiumAction') {
                        this.fetchScreenImageAppium()
                    }
                })
            }).catch(e => {
                let errorMessage = manageInspectorMessages().SESSION_NOT_STARTED_MESSAGE
                if (e.response) {
                    let msg = e?.response?.data?.value?.message
                    let httpStatus = e?.response?.status
                    let statusText = e?.response?.statusText
                    errorMessage += `${DETAILS_LABEL()}: ${statusText} ${httpStatus}. ${msg}.`
                }
                else {
                    errorMessage += `${DETAILS_LABEL()}: ${e}.`
                }
                showError(errorMessage);
            });
        })
    }

    findSelectedElementByPath (sourceJSON, path) {
        if (!sourceJSON) {return [];}
        if (sourceJSON.path && sourceJSON.path === path){
            this.setState({selectedElement: sourceJSON});
            return sourceJSON;
        }
        else{
            if (sourceJSON.children.length === 0) {
                return [];
            }
            else{
                sourceJSON.children.map(element => this.findSelectedElementByPath(element, path));
            }
        }
    }

    handleElementSelect = (selectedElementKey) => {
        if(typeof(selectedElementKey) === 'string'){
            if(selectedElementKey.length > 0){
                // replace hypens with dots
                var path = selectedElementKey.replace(/-/g, '.');
                // remove initial zero and dot which are required for Tree View
                path = path.slice(2);
                this.findSelectedElementByPath(this.state.sourceJSON, path);
                this.props.handleSelectedElement(path)
                this.setState({element_selected: true});
            }
        }
    };

    async getElementID(){
        // finds selected element's id for actions
        if(this.state.selectedElement !== {}){
            let using = "xpath"
            let value = this.state.selectedElement['xpath']
            await axios.post(`${APPIUM_REQUEST_PREFIX}/${this.state.sessionId}/elements`, {
                using, value
            }).then(res => {
                let values = res.data.value
                if(values !== undefined && values.length > 0){
                    this.setState({element_id:  values[0].ELEMENT});
                }
            }).catch(err => {
                console.log(`Element id could not be found because ${err}`)
            })
        }
    }

    async getElementIDByStrategy(strategy, locator){
        let using = strategy
        let value = locator
        return axios.post(`${APPIUM_REQUEST_PREFIX}/${this.state.sessionId}/elements`, {
            using, value
        })
    }

    sendKeyToSelectedElement = () => {
        this.setState({elementSendKeysLoading: true})
        this.getElementID().then(() => {
            if(this.state.element_id !== ""){
                if(this.state.key_to_send !== ""){
                    let keyToSend = this.state.key_to_send
                    this.setState({send_key_mode: false});
                    let value = keyToSend
                    axios.post(`${APPIUM_REQUEST_PREFIX}/${this.state.sessionId}/element/${this.state.element_id}/value`, {
                        value
                    }).then(() => {
                        console.log(`Keys were sent successfully.`)
                        this.refreshAppSource('appiumAction')
                        if(this.props.isRecording){
                            let [optimalLocator, optimalStrategy] = this.findOptimalSelectionStrategy()
                            let currentActions = this.props.recordedActions
                            let updatedList = currentActions.concat({action: 'findElement', params: {strategy: optimalStrategy, locator: optimalLocator}},
                            {action: 'sendKeys', params: {strategy: optimalStrategy, locator: optimalLocator, text: value}})
                            this.setState({elementSendKeysLoading: false}, ()=>{
                                this.props.updateRecordedActions(updatedList)
                            })
                        }
                        else{
                            this.setState({elementSendKeysLoading: false})
                        }
                    }).catch(err => {
                        let errorMessage = manageInspectorMessages().KEY_SENT_FAILED_MESSAGE
                        if(err.response){
                            let msg = err?.response?.data?.value?.message
                            let httpStatus = err?.response?.status
                            let statusText = err?.response?.statusText
                            errorMessage += `${DETAILS_LABEL()}: ${statusText} ${httpStatus}. ${msg}.`
                        }
                        else{
                            errorMessage += `${DETAILS_LABEL()}: ${err}.`
                        }
                        showError(errorMessage);
                        this.setState({elementSendKeysLoading: false})
                    })
                }
            }
        })
    };

    handleTextChange = (event) => this.setState({key_to_send: event.target.value})
    sendKeysDialogBox = () => (
        <Modal size={'mini'} open={this.state.send_key_mode} onClose={this.close}>
        <Header icon={KEY_ICON} content={SEND_KEYS_BUTTON()} />
        <Modal.Content>
            <Input fluid focus type={"text"}  placeholder={manageInspectorMessages().ENTER_TEXT_PLACEHOLDER} onChange={this.handleTextChange}/>
        </Modal.Content>
        <Modal.Actions>
                <Button  negative onClick={() => {
                    this.setState({send_key_mode: false});
                }}>{CANCEL_BUTTON()}</Button>
                <Button color="blue" onClick={this.sendKeyToSelectedElement} loading={this.state.elementSendKeysLoading} disabled={this.state.elementSendKeysLoading}>{SEND_KEYS_BUTTON()}</Button>
            </Modal.Actions>
      </Modal>
      )

    handleOnChangeSearchElementStrategy = (e, data) => {
        this.setState({elementSearchStrategy: this.getLocatorStrategiesForElementSearch()[data.value - 1]?.text.toLowerCase()})
    }

    handleTextChangeElementSearchLocator = (event) => {
        this.setState({elementSearchLocator: event.target.value})
    }

    getLocatorStrategiesForElementSearch = () => {
        const locatorStrategies = [
            { key: 1, text: 'Id', value: 1 },
            { key: 2, text: 'XPath', value: 2 },
            { key: 3, text: 'Name', value: 3 },
            { key: 4, text: 'Class Name', value: 4 },
            { key: 5, text: 'Accessibility ID', value: 5 }
        ]
        return locatorStrategies
    }

    handleSelectFoundElement = (elementID) => {
        this.setState({selectedSearchElementID: elementID, foundElementPath: ""})
        getElementLocation(this.state.sessionId, elementID).then(locationRes => {
            getElementSize(this.state.sessionId, elementID).then(sizeRes => {
                // change inspector mode to tap elements so that found element can be shown in device screen
                this.sendAppSourceToManagePage(this.state.sourceJSON)
                //this.props.handleChangeInspectorMode(INSPECTOR_MODES.TAPELEMENTS)
                const x1 = locationRes?.data?.value?.x
                const y1 = locationRes?.data?.value?.y
                const x2 = x1 + sizeRes?.data?.value?.width
                const y2 = y1 + sizeRes?.data?.value?.height
                const bounds = `[${x1},${y1}][${x2},${y2}]`
                // find path of found element
                if(this.state.desiredCapabilities.platformName.toLowerCase() === DEVICEOS.IOS){
                    this.findFoundElementPathIOS(this.state.sourceJSON, x1, y1, (x2-x1), (y2-y1))
                }else{
                    //this.findFoundElementPath(this.state.sourceJSON, bounds)
                    this.findFoundElementPath(this.state.sourceJSON, bounds);
                }
                // sets selected element to found element using path
                this.findSelectedElementByPath(this.state.sourceJSON, this.state.foundElementPath);
                // set selected element ID
                this.setState({element_id: elementID})
            }).catch(err => {
                console.log("Error in finding element size: ", err)
            })
        }).catch(err => {
            console.log("Error in finding element Location: ", err)
        })
    }

    findFoundElementPath (sourceJSON, bounds){
        if (!sourceJSON) {return "";}
        if (sourceJSON?.attributes?.bounds === bounds){
            this.props.handleSelectedElement(sourceJSON?.path)
            this.setState({foundElementPath: sourceJSON?.path})
            return [];
        }
        else{
            sourceJSON.children.map(element => this.findFoundElementPath(element, bounds));
        }
    }

    findFoundElementPathIOS (sourceJSON, x, y, width, height){
        if (!sourceJSON) {return "";}
        if (sourceJSON?.attributes?.x === String(x) && sourceJSON?.attributes?.y === String(y) && sourceJSON?.attributes?.width === String(width) && sourceJSON?.attributes?.height === String(height)){
            this.props.handleSelectedElement(sourceJSON?.path)
            this.setState({foundElementPath: sourceJSON?.path})
            return [];
        }
        else{
            sourceJSON.children.map(element => this.findFoundElementPathIOS(element, x, y, width, height));
        }
    }

      searchElementDialogBox = () => (
        <div>
            <div>
                {!this.state.elementSearchFinished &&
                    <div className={"searchSegment"}>
                        <Segment color='grey' className={"searchSegment"} compact onClose={this.close}>
                        <Header as='h5' icon='search' content={manageInspectorMessages().SEARCH_FOR_ELEMENT_HEADER} />
                        <div>
                            <div>{manageInspectorMessages().LOCATOR_STRATEGY_LABEL}:</div>
                            <Dropdown
                                placeholder='Id'
                                fluid
                                selection
                                options={this.getLocatorStrategiesForElementSearch()}
                                onChange={this.handleOnChangeSearchElementStrategy}/>
                            <div style={{"marginTop": "5px"}}>
                                <div>{SELECTOR_LABEL()}:</div>
                                <Input fluid focus type={"text"}  placeholder={manageInspectorMessages().LOCATOR_PLACEHOLDER} onChange={this.handleTextChangeElementSearchLocator}/>
                            </div>
                        </div>
                        <div style={{"marginTop": "10px"}}>
                            <Button floated='left'  negative onClick={() => {
                                    this.setState({element_search_mode: false});
                                }}>{CANCEL_BUTTON()}</Button>
                            <Button floated='right' color="blue" onClick={this.handleSearchElement} loading={this.state.elementSearchLoading} disabled={this.state.elementSearchLoading}>{SEARCH_BUTTON()}</Button>
                        </div>
                    </Segment>
                    </div>

                }
            </div>
            <div>
                {this.state.elementSearchFinished &&
                          <div className={"searchSegment2"}>
                            <Segment color='grey' className={"searchSegment"} compact onClose={this.close}>
                            <Header as='h5' icon={SEARCH_ICON} content={manageInspectorMessages().SEARCH_FOR_ELEMENT_HEADER} />
                            <div>{`${manageInspectorMessages().ELEMENTS_LABEL} (${this.state.searchElementResult.length})`}</div>
                            <div style={{height: "100px", overflowY: "auto", marginTop: "5px"}}>
                                <Table basic selectable size="small">
                                    <Table.Header></Table.Header>
                                    <Table.Body>
                                        {this.state.searchElementResult.map((element) => (
                                            <Table.Row key={element.ELEMENT} active={this.state.selectedSearchElementID === element.ELEMENT}>
                                                <Table.Cell onClick={()=> {
                                                    this.handleSelectFoundElement(element.ELEMENT)
                                                }}>{element.ELEMENT}</Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                            </div>
                            {this.state.foundElementPath !== "" && <div>
                            <div style={{"marginTop": "15px"}}>
                            <Button.Group size='mini' widths='3'>
                                <Button size='mini' basic color='grey' onClick={()=>{
                                     copy(this.state.selectedSearchElementID);
                                }}>{manageInspectorMessages().COPY_ID_BUTTON}</Button>
                                <Button size='mini' style={{"marginLeft":"5px"}} loading={this.state.elementTapLoading} disabled={this.state.elementTapLoading} basic color='grey' onClick={()=>{
                                    this.handleSelectedElementTap()
                                }}>{manageInspectorMessages().TAP_ELEMENT_BUTTON}</Button>
                                <Button size='mini' style={{"marginLeft":"5px"}} loading={this.state.elementClearLoading} disabled={this.state.elementClearLoading} basic color='grey' onClick={()=>{
                                    this.handleSelectedElementClear()
                                }}>{CLEAR_BUTTON()}</Button>
                            </Button.Group>
                            </div>
                            <div style={{"marginTop": "10px"}}>
                                <Input size='mini' style={{"width": "70%"}} name={"key"} focus type={"text"} placeholder={manageInspectorMessages().ENTER_KEYS_PLACEHOLDER} onChange={(event)=>{
                                    this.setState({key_to_send: event.target.value})
                                }}/>
                                <Button size='mini' style={{"width": "20%", "marginLeft":"6%"}} loading={this.state.elementSendKeysLoading} disabled={this.state.elementSendKeysLoading} basic color='grey' onClick={()=>{
                                    this.setState({elementSendKeysLoading: true})
                                    sendKeysToElement(this.state.sessionId, this.state.selectedSearchElementID, this.state.key_to_send).then(()=>{
                                        this.refreshAppSource('appiumAction')
                                        this.setState({elementSendKeysLoading: false})
                                    }).catch(()=>{
                                        console.log("Error in sending keys to element with id: ", this.state.selectedSearchElementID)
                                    })
                                }}>{SEND_KEYS_BUTTON()}</Button>
                            </div>
                            </div>}
                            <div style={{"marginTop": "10px"}}>
                            <Button  floated='left' onClick={() => {
                                    this.setState({elementSearchFinished: false, foundElementPath: ""});
                                }}>{BACK_BUTTON()}</Button>
                            <Button floated='right' color="blue" onClick={()=>{
                                    this.setState({element_search_mode: false, foundElementPath: ""});
                            }}>{DONE_BUTTON()}</Button>
                            </div>
                            </Segment>
                          </div>
            }
            </div>
        </div>
      )

    handleSearchElement = () => {
        this.setState({elementSearchLoading: true})
        this.getElementIDByStrategy(this.state.elementSearchStrategy, this.state.elementSearchLocator).then(res => {
            this.setState({searchElementResult: res.data.value, elementSearchLoading: false}, () => {
                this.showSearchResults()
            })
        }).catch(() => {
            this.setState({searchElementResult: [], elementSearchLoading: false}, () => {
                this.showSearchResults()
            })
        })
      }

    showSearchResults = () => {
        this.setState({elementSearchFinished: true})
      }

      closeInspectorDialogBox = () => (
        <Modal size={'mini'} open={this.state.quit_inspector_mode} onClose={this.close}>
        <Header icon='key' content={manageInspectorMessages().QUIT_INSPECTOR_HEADER} />
        <Modal.Content>
            <p>{manageInspectorMessages().QUIT_INSPECTOR_CONTENT_MESSAGE}</p>
        </Modal.Content>
        <Modal.Actions>
                <Button  negative onClick={() => {
                    this.setState({quit_inspector_mode: false});
                }}>{NO_LABEL()}</Button>
                <Button color="blue" onClick={this.sendKeyToSelectedElement} loading={this.state.elementSendKeysLoading} disabled={this.state.elementSendKeysLoading} >{YES_LABEL()}</Button>
            </Modal.Actions>
      </Modal>
      )

    handleSelectedElementTap = () => {
        this.setState({elementTapLoading: true})
        this.getElementID().then(() => {
            if(this.state.element_id !== ""){
                axios.post(`${APPIUM_REQUEST_PREFIX}/${this.state.sessionId}/element/${this.state.element_id}/click`, {
                }).then(() => {
                    console.log(`Clicked to the element with id: ${this.state.element_id}`)
                    this.refreshAppSource('appiumAction')
                   if(this.props.isRecording){
                        let [optimalLocator, optimalStrategy] = this.findOptimalSelectionStrategy()
                        let currentActions = this.props.recordedActions
                        let updatedList = currentActions.concat({action: 'findElement', params: {strategy: optimalStrategy, locator: optimalLocator}},
                                                                        {action: 'click', params: {strategy: optimalStrategy, locator: optimalLocator}})
                        this.setState({elementTapLoading: false}, ()=>{
                            this.props.updateRecordedActions(updatedList)
                        })
                   }
                   else{
                    this.setState({elementTapLoading: false})
                   }

                }).catch(err => {
                    let errorMessage = manageInspectorMessages(this.state.element_id).CLICK_ELEMENT_WITH_ID_FAILED_MESSAGE
                    if(err.response){
                        let msg = err?.response?.data?.value?.message
                        let httpStatus = err?.response?.status
                        let statusText = err?.response?.statusText
                        errorMessage += `${DETAILS_LABEL()}: ${statusText} ${httpStatus}. ${msg}.`
                    }
                    else{
                        errorMessage += `${DETAILS_LABEL()}: ${err}.`
                    }
                    showError(errorMessage);
                    this.setState({elementTapLoading: false})
                })
            }
        })
    };

    findOptimalSelectionStrategy(){
        //    Priority Order for strategy: Name - Content Description - Resource ID - XPath
        //    Found strategy - locator pair should be unique. If it's unique, use it.
        //    Otherwise, the next strategy in priority order will be used.
        let strategy = SELECTION_STRATEGIES.XPATH
        let locator = this.state.selectedElement[SELECTION_STRATEGIES.XPATH]
        let attributes = this.state.selectedElement.attributes

        if(attributes['name']){ // for ios devices
            let isUniqueResult = isUnique('name', attributes['name'], this.state.sourceJSON)
            if(isUniqueResult){
                strategy = SELECTION_STRATEGIES.ACCESSIBILITYID
                locator = attributes['name']
            }
        }
        else if(attributes['content-desc']){ // if content-desc is existed
            let isUniqueResult = isUnique('content-desc', attributes['content-desc'], this.state.sourceJSON)
            if(isUniqueResult){
                strategy = SELECTION_STRATEGIES.ACCESSIBILITYID
                locator = attributes['content-desc']
            }
        }
        else{
            let resource_id = attributes['resource-id']
            if (resource_id){
                let isUniqueResult = isUnique('resource-id', resource_id, this.state.sourceJSON)
                if(isUniqueResult){
                    strategy = SELECTION_STRATEGIES.ID
                    locator = resource_id
                }
            }
        }
        return [locator, strategy]
    }

    handleSelectedElementSendKeys = () => {
        this.setState({send_key_mode: true});
    };

    handleElementSearch = () => {
        this.refreshAppSource("appiumAction")
        this.setState({element_search_mode: true})
    }

    handleSelectedElementClear = () => {
        this.setState({elementClearLoading: true})
        this.getElementID().then(() => {
            if(this.state.element_id !== ""){
                axios.post(`${APPIUM_REQUEST_PREFIX}/${this.state.sessionId}/element/${this.state.element_id}/clear`, {
                }).then(() => {
                    console.log(`Clear successful for the element with id: ${this.state.element_id}`)
                    this.refreshAppSource('appiumAction')
                    if(this.props.isRecording){
                        let [optimalLocator, optimalStrategy] = this.findOptimalSelectionStrategy()
                        let currentActions = this.props.recordedActions
                        let updatedList = currentActions.concat({action: 'findElement', params: {strategy: optimalStrategy, locator: optimalLocator}},
                                                                            {action: 'clear', params: {strategy: optimalStrategy, locator: optimalLocator}})
                        this.setState({elementClearLoading: false}, ()=>{
                            this.props.updateRecordedActions(updatedList)
                        })
                    }
                    else{
                        this.setState({elementClearLoading: false})
                    }
                }).catch(err => {
                    let errorMessage = manageInspectorMessages(this.state.element_id).CLEAR_TEXT__FOR_ELEMENT_WITH_ID_FAILED_MESSAGE
                    if(err.response){
                        let msg = err?.response?.data?.value?.message
                        let httpStatus = err?.response?.status
                        let statusText = err?.response?.statusText
                        errorMessage += `${DETAILS_LABEL()}: ${statusText} ${httpStatus}. ${msg}.`
                    }
                    else{
                        errorMessage += `${DETAILS_LABEL()}: ${err}.`
                    }
                    showError(errorMessage);
                    this.setState({elementClearLoading: false})
                })
            }
        })
    };

    handleSelectedElementCopy = () => {
        let attributes = this.state.selectedElement['attributes']
        copy(JSON.stringify(attributes));
    };

    // fetch screen image via Appium
    fetchScreenImageAppium=()=>{
        fetchScreenImageViaAppium(this.state.sessionId).then(res=>{
            this.props.sendScreenshotToManagePage(res?.data?.value)
        }).catch(err => {
            console.log(`Error in fetching screenshot from Appium: ${err}`)
        })
    }

    handleChangeInspectorMode = (inspectorMode) => {
        this.props.handleChangeInspectorMode(inspectorMode)
    }

    handleSwipeByCoordinates = () => {
        if(this.props.inspectorMode === INSPECTOR_MODES.SWIPE){
            this.handleChangeInspectorMode(INSPECTOR_MODES.MANUAL)
        }
        else{
            this.handleChangeInspectorMode(INSPECTOR_MODES.SWIPE)
        }
    }

    sendAppSourceToManagePage = (sourceJSON) => {
        this.props.sendAppSourceToManagePage(sourceJSON)
    }

    sendScreenResolutionToManagePage = (screenResolution) => {
        this.props.sendScreenResolutionToManagePage(screenResolution)
    }

    handleCloseRecoder = () => {
        this.setState({recorderStarted: false}, ()=>{
            this.props.clearActions()
        })
    }

    handleBoilerPlate = () => {
        this.setState({includeBoilerPlate: !this.state.includeBoilerPlate})
    }

    switchWebContext = async (sessionId) => {
        await switchContext(sessionId, 'WEBVIEW_chrome').then(() => {
            this.setState({ applicationMode: 'web_hybrid' });
        }).catch(() => {
            showError(manageInspectorMessages().WEB_CONTEXT_NOT_SWITCHED_MESSAGE);
        })
    }

    switchNativeContext = async sessionId => {
        await switchContext(sessionId, 'NATIVE_APP').then(() => {
            this.setState({ applicationMode: 'native' });
        }).catch(() => showError(manageInspectorMessages().NATIVE_CONTEXT_NOT_SWITCHED_MESSAGE));  //Switching to native source
    }

    handleNativeMode = async () => {
        if(this.state.applicationMode === 'native'){
            toast.info(manageInspectorMessages().NATIVE_IS_UP_MESSAGE);
        }else{
           await this.switchNativeContext(this.state.sessionId);//    Switching context here
        }
    }

    handleWebMode = async () => {
        if(this.state.applicationMode === 'web_hybrid'){
            toast.info(manageInspectorMessages().WEB_HYBRID_UP_RUNNING_MESSAGE);
        }else{
            await this.switchWebContext(this.state.sessionId);//    Switching context here
        }
    }

    treeViewingOptions = [
        { key: "Native", text: "Native", value: "Native" },
        { key: "Webview", text: "Webview", value: "Webview" },
      ];
    
    handleDropdownChange = (event, data) => {
        if (data.value === "Native") {
          // Calling Native Method
          this.handleNativeMode()
            .then(() => this.refreshAppSource('appiumAction'))
            .catch(() => {
                showError(manageInspectorMessages().NATIVE_CONTEXT_NOT_SWITCHED_MESSAGE)
            });
        } else if (data.value === "Webview") {
          // Calling Web Method
          this.handleWebMode()
            .then(() => this.refreshAppSource('appiumAction'))
            .catch(() => {
                showError(manageInspectorMessages().WEB_CONTEXT_NOT_SWITCHED_MESSAGE);
            });
        }
    };

    render() {
        return (
        <div>
            <div>
                {this.state.inspectorStarted &&
                    <div style={{marginBottom: "10px"}}>
                        <div>{this.sendKeysDialogBox()}</div>
                        <div>{this.closeInspectorDialogBox()}</div>

                        <Popup content={manageInspectorMessages().REFRESH_SOURCE_POPUP}
                            trigger={
                            <Button icon={REFRESH_ICON}
                                onClick={() => this.refreshAppSource('appiumAction')}
                                disabled={this.state.refresh_loading}
                                loading={this.state.refresh_loading}/>
                            }
                        />
                        <Popup content={this.props.isRecording? manageInspectorMessages().PAUSE_RECORDING_LABEL : manageInspectorMessages().START_RECORDING_LABEL}
                            trigger={
                            <Button icon={this.props.isRecording? PAUSE_ICON : EYE_ICON}
                                    color={this.props.isRecording? 'red' : 'default'}
                                    onClick={()=>{
                                        this.props.handleRecordingStatus()
                                        if(!this.state.recorderStarted){
                                            this.setState({recorderStarted: true})
                                        }
                                    }}
                                    disabled={this.state.refresh_loading}
                                    active={this.state.refresh_loading}
                                    loading={this.state.refresh_loading}/>
                            }
                        />
                        <Popup content={manageInspectorMessages().SEARCH_FOR_ELEMENT_HEADER}
                               trigger={
                                   <Button icon={SEARCH_ICON}
                                        onClick={()=>{
                                            this.handleElementSearch()
                                        }}
                                        disabled={this.state.refresh_loading}
                                        loading={this.state.refresh_loading}
                                   />
                               }
                            />
                            <Popup content={manageInspectorMessages().SOURCE_POPUP}
                                trigger={
                                    <Button icon={FILE_CODE_ICON}
                                        onClick={() => { this.setState({ actionOrSourceDivisionShow: "Source" }) }}
                                        disabled={this.state.refresh_loading}
                                        loading={this.state.refresh_loading}
                                    />
                                }
                            />
                            <Popup content={ACTIONS_HEADER()}
                                trigger={
                                    <Button icon={LIST_ICON}
                                        onClick={() => { this.setState({ actionOrSourceDivisionShow: "Actions" }) }}
                                        disabled={this.state.refresh_loading}
                                        loading={this.state.refresh_loading}
                                    />
                                }
                            />

                            <Popup content={manageInspectorMessages().DOWNLOAD_SOURCE_TREE}
                            trigger={
                                <Button icon={DOWNLOAD_ICON}
                                    onClick={() => {
                                        downloadXML(this.state.sourceXML);
                                    }}
                                    disabled={this.state.refresh_loading}
                                    loading={this.state.refresh_loading}
                                />
                            }/>

                            <Popup content={manageInspectorMessages().COPY_SOURCE_TREE}
                            trigger={
                            <Button icon={COPY_ICON}
                                onClick={()=>{
                                    copy(JSON.stringify(this.state.sourceXML))
                                }}
                                disabled={this.state.refresh_loading}
                                loading={this.state.refresh_loading}
                            />
                            }/>
                        </div>
                    }
                </div>
                {/* BELOW-------LANDSCAPE MODE Stable Frozen Inspector standstills here on ---------------------------------*/}
                {
                    this.props.deviceScreenState === "1" && this.state.inspectorStarted ?
                        <div id='landscape-div-for-frozen-inspector'>
                            <Grid.Column style={{
                                justifyContent: 'center'
                            }}>
                                <div>
                                    <Dimmer.Dimmable dimmed={this.props.smartScreenshotLoading}>
                                        <Dimmer active={this.props.smartScreenshotLoading} inverted>
                                            <Loader>{TAKING_SCREENSHOTS_LOADER()}</Loader>
                                        </Dimmer>
                                        <div style={{display: 'flex'}}
                                            draggable={true} data-id={this.props.id}
                                        >
                                            <img className="deviceImage" onLoad={(event) => this.props.onLoadImage(event)}
                                                style={{ height: "calc(26.8vw - 177px)"}}
                                                src={"data:image/png;base64," + this.state.frozenInspectorScreenShoot} alt="phone"
                                                onKeyDown={this.props.handleKeyEvents} tabIndex="0"
                                            />
                                            <HighlighterRects
                                                hoveredElement={this.props.hoveredElement}
                                                selectedElement={this.props.selectedElement}
                                                selectHoveredElement={this.props.selectHoveredElement}
                                                selectSelectedElement={this.props.selectSelectedElement}
                                                source={this.props.source}
                                                ratio={this.props.ratio}
                                            />
                                        </div>
                                    </Dimmer.Dimmable>
                                </div>
                            </Grid.Column>
                        </div>
                        : null
                }
                {/* ABOVE-------LANDSCAPE MODE Stable Frozen Inspector standstills here  --------------------------------------*/}

                <div id={this.props.deviceScreenState === '1' ? 'landscape-inspector-page-outmost-container' : 'portrait-inspector-page-outmost-container' }>
                    {!this.state.inspectorStarted &&
                        <Segment textAlign={'center'} id='inspector-page-parent-segment'>
                                                    
                            <Button animated={"vertical"} style={{ marginBottom: "10px" }} onClick={() => {
                                this.startInspector()
                            }}>
                                <Button.Content visible>{manageInspectorMessages().START_INSPECTOR_BUTTON}</Button.Content>
                                <Button.Content hidden>
                                    <Icon name={SEARCH_ICON} />
                                </Button.Content>
                            </Button>
                            <Placeholder fluid style={{ marginTop: "15" }}>
                                {Array.apply(null, Array(5)).map((p, index) => {
                                    return (
                                        <Placeholder.Paragraph key={index}>
                                            <Placeholder.Line />
                                            <Placeholder.Line />
                                            <Placeholder.Line />
                                            <Placeholder.Line />
                                            <Placeholder.Line />
                                            <Placeholder.Line />
                                            <Placeholder.Line />
                                        </Placeholder.Paragraph>
                                    )
                                })}
                            </Placeholder>
                        </Segment>
                    }
                    <div>{this.state.element_search_mode && this.searchElementDialogBox()}</div>
                    
                    {this.state.inspectorStarted &&
                        <div id={this.props.deviceScreenState === '1' ? 'landscape-inspector-page-inner-container' : 'portrait-inspector-page-inner-container'}>
                            <div style={ this.props.deviceScreenState === '1' ? { width: '98%'}:  { width: '64%' }}>
                                {/* BELOW----APP TREE OR ACTIONS PANEL or RECORDING PANEL WILL BE SHOWN ADAPTIVELY (LEFT IN PORTRAIT,CENTERED IN LANDSCAPE MODE---*/}
                                {this.state.inspectorStarted && this.state.recorderStarted &&
                                    <RecordedActions
                                        recordedActions={this.props.recordedActions}
                                        clearActions={this.props.clearActions}
                                        closeRecoder={this.handleCloseRecoder}
                                        showBoilerplate={this.state.includeBoilerPlate}
                                        handleBoilerPlate={this.handleBoilerPlate}
                                        sessionDetails={
                                            {
                                                host: '127.0.0.1',
                                                port: 8080,
                                                path: '',
                                                https: true,
                                                desiredCapabilities: this.state.desiredCapabilities
                                            }
                                        }
                                    />
                                }

                                <div style={this.state.actionOrSourceDivisionShow === 'Source' ? null : { display: 'none'}}>
                                    <Dimmer.Dimmable className='inspector-tree-div'  as={Segment.Group} vertical blurring dimmed={this.state.element_search_mode}>
                                        <Dimmer className={'searchDimmer'} active={this.state.element_search_mode} inverted />
                                        <div>
                                            <div>
                                                {this.state.refresh_loading && <i> {manageInspectorMessages().APP_SOURCE_FETCHED_PLEASE_WAIT_MESSAGE} </i>}
                                            </div>
                                        </div>
                                        <div>
                                            {!this.state.refresh_loading && 
                                                <AppSourceTree
                                                    chosenAppMode={this.state.applicationMode}
                                                    sourceJSON={this.state.sourceJSON}
                                                    onSelect={this.handleElementSelect}
                                                    handleElementSelect={this.handleElementSelect}
                                                    selectedElementPath={this.props.selectedElementPath}
                                                />
                                            }
                                        </div>
                                    </Dimmer.Dimmable>
                                </div>
                                <div style={this.state.actionOrSourceDivisionShow === 'Source' ? { display: 'none' } : null}>
                                    <ActionsPanel
                                        recordedActions={this.props.recordedActions}
                                        isRecording={this.props.isRecording}
                                        updateRecordedActions={this.props.updateRecordedActions}
                                        sessionId={this.state.sessionId}
                                    />
                                </div>

                                {/*---BELOW-------Selected Element TABLE----------------------------------*/}
                                <div style={this.state.actionOrSourceDivisionShow === "Source" ? null : { display: "none", marginTop: "20px" }}>
                                    {
                                        <React.Fragment >
                                            <Dimmer.Dimmable as={Segment.Group} vertical blurring dimmed={this.state.element_search_mode}>
                                                <Dimmer className={"searchDimmer"} active={this.state.element_search_mode} inverted />
                                                <Segment>
                                                    <div>
                                                        <Header as='h4' icon='tags' content={manageInspectorMessages().SELECTED_ELEMENT_HEADER} />

                                                        <Dropdown
                                                            placeholder={manageInspectorMessages().SELECT_INSPECTOR_MODE}
                                                            selection
                                                            options={this.treeViewingOptions}
                                                            onChange={this.handleDropdownChange}
                                                        />
                                                        <div>
                                                            {!this.state.element_selected && <i> {manageInspectorMessages().SELECT_ELEMENT_TO_BEGIN_MESSAGE} </i>}
                                                        </div>
                                                        <div>
                                                            {this.state.element_selected &&
                                                                <SelectedElementPanel
                                                                    isDeviceIOS={this.state.desiredCapabilities.platformName.toLowerCase() === DEVICEOS.IOS}
                                                                    selectedElement={this.state.selectedElement}
                                                                    handleSelectedElementTap={this.handleSelectedElementTap}
                                                                    handleSelectedElementSendKeys={this.handleSelectedElementSendKeys}
                                                                    handleSelectedElementClear={this.handleSelectedElementClear}
                                                                    handleSelectedElementCopy={this.handleSelectedElementCopy}
                                                                    elementTapLoading={this.state.elementTapLoading}
                                                                    elementClearLoading={this.state.elementClearLoading}
                                                                    renewInspectorScreenSource={this.refreshAppSource}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                </Segment>
                                            </Dimmer.Dimmable>
                                        </React.Fragment>
                                    }
                                </div>
                                {/*---ABOVE-------Selected Element TABLE----------------------------------*/}
                            </div>

                            {/* BELOW------- PORTRAIT MODE - Stable Frozen Inspector standstills here on ---------------------------------*/}
                            <div name={this.props.deviceScreenState === '1' ? 'display-hide' : 'display-show'} id='portrait-div-for-frozen-inspector-screen'>
                                <Grid.Column id='inspector-container-grid'>
                                    <div>
                                        <Dimmer.Dimmable dimmed={this.props.smartScreenshotLoading}>
                                            <Dimmer active={this.props.smartScreenshotLoading} inverted>
                                                <Loader>{TAKING_SCREENSHOTS_LOADER()}</Loader>
                                            </Dimmer>
                                            <div style={{ display: 'flex' }}
                                                draggable={true} data-id={this.props.id}
                                            >
                                                <img className="deviceImage" onLoad={(event) => this.props.onLoadImage(event)}
                                                    style={{ height: "calc(70vh - 120px)" }}
                                                    src={"data:image/png;base64," + this.state.frozenInspectorScreenShoot} alt="phone"
                                                    onKeyDown={this.props.handleKeyEvents} tabIndex="0"
                                                />
                                                <HighlighterRects
                                                    hoveredElement={this.props.hoveredElement}
                                                    selectedElement={this.props.selectedElement}
                                                    selectHoveredElement={this.props.selectHoveredElement}
                                                    selectSelectedElement={this.props.selectSelectedElement}
                                                    source={this.props.source}
                                                    ratio={this.props.ratio}
                                                    style={{
                                                        width: 50, height: 100
                                                    }}
                                                />
                                            </div>
                                        </Dimmer.Dimmable>
                                    </div>
                                </Grid.Column>
                            </div>
                            {/* ABOVE------- PORTRAIT MODE - Stable Frozen Inspector standstills here  --------------------------------------*/}
                        </div>
                    }
                </div>
            </div>
        )
    }
}

const mapStateProps = (state) => {
    return {
        loading: state.notification.loading,
        loadingContent: state.notification,
        appMode: state.inspector.appMode
    }
}
export default withTranslation()(connect(mapStateProps, {startFullLoad,endFullLoad,setChosenAppMode})(withRouter(ManageInspector)));
