import React, {Component} from 'react';
import {Button, Confirm, Divider, Dropdown, Form, Header, Icon, Input, Modal, Segment} from 'semantic-ui-react';
import {withTranslation} from 'react-i18next';
import axios from 'axios';
import {
    formatValuesForSysParameterKeys,
    formatValuesForSysParameterValues,
    dataRefresher
} from '../../../utils/Methods';
import {showError, showSuccess} from '../../../utils/ToastHelpers';
import {
    getSystemParameters,
    getUserSummaryList,
    updateSystemParameter
} from '../../../api/apiCalls'
import ReactTableV8 from '../../../components/ReactTableV8/ReactTableV8';
import { ACTIONS_HEADER, ACTIVE_LABEL, CANCEL_BUTTON, CONFIRM_BUTTON, DESCRIPTION_HEADER, FAILED_ERROR_MESSAGE, 
    NOT_FETCH_SYSTEM_PARAMETERS_ERROR_MESSAGE, N_A_LABEL, PASSIVE_LABEL, SUBMIT_BUTTON, SUCCESS_MESSAGE, 
    systemParametersMessages, SYSTEM_PARAMETERS_HEADER, VALUE_LABEL,YES_LABEL, NO_LABEL,GROUP_ONLY, ASK_LABEL, NOT_FETCH_USERS } from '../../../utils/UIMessages';
import { EDIT_ICON, INFO_ICON, KEY_ICON, PENCIL_ALTERNATE_ICON, TAG_ICON } from '../../../utils/UiIcons';

class SystemParametersPage extends Component {

    //New React Table V8 Columns
    columnsRender = () => [
        {
            header: () => <div>{systemParametersMessages().PARAMETER_NAME_LABEL}</div>,
            accessorFn: row => formatValuesForSysParameterKeys(row.parameterKey),
            id: 'parameterKey',
            filterType: 'text',
            cell: info => (<span>{info.getValue() === null ? N_A_LABEL() : this.props.t(info.getValue())}</span>)
        },
        {
            header: () => <div>{systemParametersMessages().CURRENT_VALUE_HEADER}</div>,
            accessorKey: 'parameterValue',
            id: 'parameterValue',
            enableColumnFilter: false,
            cell: info => (<span>{info.getValue() === null ? N_A_LABEL() : this.props.t(formatValuesForSysParameterValues(info.row.original))}</span>)
        },
        {
            header: () => <div>{DESCRIPTION_HEADER()}</div>,
            accessorFn: row => this.props.t(row.description),
            id: 'description',
            filterType: 'text',
            cell: info => (<p>{info.getValue() === null ? N_A_LABEL() : this.props.t(info.getValue())}</p>)
        },
        {
            header: () => <div>{ACTIONS_HEADER()}</div>,
            accessorKey: 'id',
            id: 'id',
            enableColumnFilter: false,
            enableSorting: false,
            cell: info => {
                return <span>
                    <Button
                        size='tiny'
                        onClick={() => this.openFormModal(info.row.original)}
                        icon={<Icon name={EDIT_ICON} />} />
                </span>
            }
        }
    ];

    state = {
        systemParameterList: [],
        allUsers: [],
        modalVisibility: false,
        confirmVisibility: false,
        parameterId: -1,
        parameterKey: "",
        parameterValue: "",
        parameterType: "",
        description: "",
        minValue:"",
        maxValue:"",
        loadingSystemParameterList: false,
        language: localStorage.getItem('lang') || 'en'
    }

    componentDidMount() {
        this.getSystemParameters();
        this.setState({ language: localStorage.getItem('lang') || 'en' });
        getUserSummaryList().then(res => {
            const allUsers = res.data.map(user => {
              return {
                key: user.userName,
                value: user.userName,
                text:
                  user.fullName === null
                    ? user.userName
                    : user.fullName
              };
            });
            this.setState({allUsers});
          }).catch(err => {
              showError(
                NOT_FETCH_USERS() +
                err?.response?.data?.message ?? err.toString()
              );
            });
    }

    componentDidUpdate() {
        // When language is changed, table data will be renewed.
        dataRefresher(
            this.state.language,
            () => this.setState({ language: localStorage.getItem('lang') },
                () => this.getSystemParameters())
        )
    }

    getSystemParameters = () => {
        this.setState({loadingSystemParameterList: true});
        //axios.get("/api/system-parameters")
        getSystemParameters().then(res => {
            this.setState({systemParameterList: res.data})
        }).catch(err => {
            showError(NOT_FETCH_SYSTEM_PARAMETERS_ERROR_MESSAGE() + err?.response?.data?.message ?? err.toString())
        }).finally(() => this.setState({loadingSystemParameterList: false}));
    }

    openFormModal = (fields) => {
        this.setState({modalVisibility: true}, () => {
            if (fields) {
                this.setState({
                    parameterId: fields.id,
                    parameterKey: fields.parameterKey,
                    parameterValue: fields.parameterValue,
                    parameterType: fields.parameterType,
                    description: fields.description,
                    minValue: fields.minValue,
                    maxValue: fields.maxValue,
                })
            } else {
                this.setState({
                    parameterId: -1,
                    parameterKey: '',
                    parameterValue: '',
                    parameterType: '',
                    minValue: '',
                    maxValue: ''
                })
            }
        })
    }

    openConfirm = (parameterId) => {
        this.setState({parameterId}, () => {
            this.setState({confirmVisibility: true})
        })
    }

    onDeleteParameter = () => {
        axios.delete('/api/system-parameter/' + this.state.parameterId).then(() => {
            showSuccess(SUCCESS_MESSAGE());
            this.setState({confirmVisibility: false}, () => {
                this.getSystemParameters()
            })
        }).catch(err => {
            showError(FAILED_ERROR_MESSAGE() + this.props.t(err?.response?.data?.message ?? err.toString()))
        })
        this.setState({parameterId: -1, parameterKey: "", parameterValue: "", parameterType: "", minValue:"", maxValue:""})
    }

    onHandleChange = (e, {value, name}) => this.setState(prevState => ({...prevState, [name]: value}))

    onHandleDormancyExclusionChange = (e, { value, name }) => this.setState(prevState => ({ ...prevState, [name]: value.toString() }))

    onHandleChangeCheckbox = (e, {name, checked}) => this.setState(prevState => ({
        ...prevState,
        [name]: checked.toString()
    }))

    onSubmitParameter = () => {
        let data = {
            'parameterValue': (this.state.parameterType === 'boolean' && this.state.parameterValue === '') ? 'false' : this.state.parameterValue
        }

        updateSystemParameter(this.state.parameterId, data).then(() => {
            showSuccess(SUCCESS_MESSAGE())
            this.setState({ modalVisibility: false }, () => {
                this.getSystemParameters()
            })
        }).catch(err => {
            const errMsg = err?.response?.data?.message;
            let msg;
            if(errMsg){
                const acceptedValuesStr = 'Accepted values:';
                const acceptedValuesEndIdx = errMsg.indexOf(acceptedValuesStr) + acceptedValuesStr.length -1;
                //Value is out of limits. Accepted values: [%s-%s]
                //Value is not acceptable. Accepted values: [%s, %s]
                msg = `${this.props.t(errMsg.slice(0, acceptedValuesEndIdx))}${errMsg.slice(acceptedValuesEndIdx + 1, errMsg.length)}`;
            }else{
                msg = err.toString();
            }

            showError(msg)
        })
    }

    initializeParameterValueField = () => {
        if (this.state.parameterKey === 'captchaMode') {
            let modes = [
                {
                    key: "Arithmetic Captcha",
                    value: "0",
                    text: systemParametersMessages().ARITHMETIC_CAPTCHA_OPTION
                },
                {
                    key: "AlphaNumeric Captcha",
                    value: "1",
                    text: systemParametersMessages().ALPHANUMERIC_CAPTCHA_OPTION
                },
                {
                    key: "Gif Captcha",
                    value: "2",
                    text: systemParametersMessages().GIF_CAPTCHA_OPTION
                }
            ]
            return <Dropdown fluid selection
                             name={'parameterValue'}
                             options={modes}
                             value={this.state.parameterValue}
                             onChange={this.onHandleChange}/>
        }else if(this.state.parameterKey === 'visibilityOfBusyDevice'){
            let options = [
                {
                    key: 'allow',
                    value: 'yes',
                    text: YES_LABEL()
                },
                {
                    key: 'not allow',
                    value: 'no',
                    text: NO_LABEL()
                },
                {
                    key: 'group only',
                    value: 'group only',
                    text: GROUP_ONLY()
                },
                {
                    key: 'ask',
                    value: 'ask',
                    text: ASK_LABEL()
                }
            ]
            return <Dropdown fluid selection
                        name={'parameterValue'}
                        options={options}
                        value={this.state.parameterValue}
                        onChange={this.onHandleChange}/>
        } else if (this.state.parameterKey === 'exterminateUser') {
            let modes = [
                {
                    key: "Do nothing",
                    value: "0",
                    text: systemParametersMessages().DO_NOTHING
                },
                {
                    key: "Disable User",
                    value: "1",
                    text: systemParametersMessages().DISABLE_USER
                },
                {
                    key: "Delete User",
                    value: "2",
                    text: systemParametersMessages().DELETE_USER
                }
            ]
            return <Dropdown fluid selection
                             name={'parameterValue'}
                             options={modes}
                             value={this.state.parameterValue}
                             onChange={this.onHandleChange}/>
        } else if(this.state.parameterKey === 'dormancyCheckExclusions'){
            return <Dropdown fluid search
                selection clearable
                multiple name={'parameterValue'}
                options={this.state.allUsers}
                value={this.state.parameterValue?.split(',')} onChange={this.onHandleDormancyExclusionChange}/>
        } else if (this.state.parameterType === 'boolean') {
            return (
                <Segment>
                    <Header>
                        <Icon name={PENCIL_ALTERNATE_ICON}/>
                        <Header.Content as='h5'>
                            {<Form.Checkbox
                                toggle
                                label={this.state.parameterValue === "true" ? ACTIVE_LABEL() : PASSIVE_LABEL()}
                                onChange={this.onHandleChangeCheckbox}
                                checked={this.state.parameterValue === "true"}
                                fluid="true"
                                name="parameterValue"
                            />}
                        </Header.Content>
                    </Header>
                </Segment>)
        } else if (this.state.parameterType === 'int') {
            return (
                <Segment>
                    <Header>
                        <Icon name={PENCIL_ALTERNATE_ICON}/>
                        <Header.Content as='h5'>
                            {<Input
                                name='parameterValue'
                                type={"number"}
                                pattern='[0-9]*'
                                min={this.state.minValue}
                                max={this.state.maxValue}
                                label={VALUE_LABEL()}
                                onChange={this.onHandleChange}
                                value={this.state.parameterValue} />}
                            {this.state.parameterType === 'int' && <p id='system-params-min-max-label'>Min: {this.state.minValue} | Max: {this.state.maxValue}</p>}
                        </Header.Content>
                    </Header>
                </Segment>)
        } else if (this.state.parameterType === 'string') {
            return (
                <Segment>
                    <Header>
                        <Icon name={PENCIL_ALTERNATE_ICON}/>
                        <Header.Content as='h5'>
                            <Header.Subheader>{VALUE_LABEL()}</Header.Subheader>
                            {<Form.Input

                                name="parameterValue"
                                type={"text"}
                                onChange={this.onHandleChange}
                                value={this.state.parameterValue}/>}
                        </Header.Content>
                    </Header>
                </Segment>)
        }
    }

    render() {
        return (<div>
            <div className='main-right-header'>
                <div>
                    <h2> {SYSTEM_PARAMETERS_HEADER()}</h2>
                    <small>{systemParametersMessages().SYSTEM_PARAMETERS_SUB_HEADER}</small>
                </div>
                <div className='main-right-buttons'>

                </div>
            </div>
            <ReactTableV8
                columns={this.columnsRender()}
                data={this.state.systemParameterList}
                columnwiseFilterableProp={true}
                loadingProp={this.state.loadingSystemParameterList}
            />
            <Modal closeIcon closeOnDimmerClick={true} open={this.state.modalVisibility}
                   onClose={() => this.setState({modalVisibility: false})}
                   size='tiny'>
                <Modal.Content>
                    <Divider horizontal>
                        <Header as='h4'>
                            <Icon name={TAG_ICON}/>
                            {systemParametersMessages().PARAMETER_PROPERTIES_HEADER}
                        </Header>
                    </Divider>
                    <Form>
                        <Form.Field required>
                            <Segment>
                                <Header>
                                    <Icon name={KEY_ICON}/>
                                    <Header.Content as='h4'>
                                        <Header.Subheader as='h6'>{systemParametersMessages().PARAMETER_NAME_LABEL}</Header.Subheader>
                                        {formatValuesForSysParameterKeys(this.state.parameterKey)}
                                    </Header.Content>
                                </Header>
                            </Segment>
                        </Form.Field>
                        <Form.Field required>
                            <Segment>
                                <Header>
                                    <Icon name={INFO_ICON}/>
                                    <Header.Content as='h4'>
                                        <Header.Subheader as='h6'>{DESCRIPTION_HEADER()}</Header.Subheader>
                                        {this.props.t(this.state.description)}
                                    </Header.Content>
                                </Header>
                            </Segment>
                        </Form.Field>
                        <Form.Field>
                            {this.initializeParameterValueField()}
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        disabled={
                            (!this.state.parameterValue) ||
                            (this.state.parameterKey === 'lastUsedDeviceCount' && this.state.parameterValue < 1)
                        }
                        color="green" onClick={() => {
                        this.onSubmitParameter()
                    }}>{SUBMIT_BUTTON()}</Button>
                </Modal.Actions>
            </Modal>
            <Confirm
                open={this.state.confirmVisibility}
                content={systemParametersMessages().DELETE_CONFIRM}
                onCancel={() => {
                    this.setState({confirmVisibility: false, parameterId: -1})
                }}
                onClose={() => {
                    this.setState({confirmVisibility: false, parameterId: -1})
                }}
                onConfirm={this.onDeleteParameter}
                confirmButton={CONFIRM_BUTTON()}
                cancelButton={CANCEL_BUTTON()}
            />
        </div>)
    }
}

export default withTranslation()(SystemParametersPage);
