import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Flip, toast } from 'react-toastify';
import { PrivilegeConstants } from '../../utils/PrivilegeConstants';
import tr from 'date-fns/locale/tr';
import en from 'date-fns/locale/en-GB';
import { DEFAULT_PAGINATION_PROPS, POPUP_POSITIONS, ANNOUNCEMENT_CONSTANTS, HOUR_FORMAT, CAPITAL_DAILY_FORMAT } from '../../utils/Constants';
import { Base64 } from 'js-base64';
import { showError, showSuccess } from '../../utils/ToastHelpers';
import { Button, Table, Tab, Dropdown, Label, Reveal, Popup, Icon, Modal, Confirm, Segment } from 'semantic-ui-react';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import moment from 'moment';
import {
    deleteAnnouncement, getLiveAnnouncements, getNextAnnouncements, getPastAnnouncements,
    markAnnouncementAsRead, markAnnouncementAsUnread
} from '../../api/apiCalls'
import ReactTableV8 from '../../components/ReactTableV8/ReactTableV8';
import ShowMoreDialog from '../../components/ShowMoreDialog';
import { showMoreText, showMoreLabel } from '../../utils/Methods';
import {
    ACTIVE_LABEL, announcementMessages, CLOSE_BUTTON, DESCRIPTION_HEADER, NAME_HEADER, ANNOUNCER_HEADER, CONFIRM_BUTTON,
    CANCEL_BUTTON, TARGET_HEADER, SHOW_MORE_BUTTON, EVENTS_HEADER, ACTIONS_HEADER, EDIT_BUTTON, DELETE_BUTTON
} from '../../utils/UIMessages';
import { EDIT_ICON, TRASH_ICON, BULLHORN_ICON, USER_OUTLINE_ICON, CHECK_ICON, X_ICON } from '../../utils/UiIcons';
import NewAnnouncementFormModal from './NewAnnouncementFormModal';
registerLocale('tr', tr);
registerLocale('en', en);

const AnnouncementsModal = ({
    openShowAnnouncemetsModalProp, closeShowAnnouncementModalFuncProp,
    getNewAnnouncementsFuncProp, localeProp
}) => {

    const { t } = useTranslation();
    /*User Privileges*/
    const [privileges, setPrivileges] = useState([]);

    /*Annnouncement Arrays*/
    const [liveAnnouncements, setLiveAnnouncements] = useState([]);
    const [nextAnnouncements, setNextAnnouncements] = useState([]);
    const [pastAnnouncements, setPastAnnouncements] = useState([]);

    /*History React Table States*/
    const [page, setPage] = useState(DEFAULT_PAGINATION_PROPS.PAGE_INDEX);
    const [size, setSize] = useState(DEFAULT_PAGINATION_PROPS.PAGE_SIZE);
    const [totalPages, setTotalPages] = useState(-1);

    /* BELOW - Create-Update Announcement From Inputs_____________________________________________________________________*/
    const [announcementIdFormInp, setAnnouncementIdFormInp] = useState('');
    //on 'update announcement' form as information.
    const [updatableAnnouncementObject, setUpdatableAnnouncementObject] = useState(null);

    /*ABOVE - Create-Update Announcement From Inputs_____________________________________________________________________*/

    /*Pending*/
    const [loading, setLoading] = useState(false);

    /*Modal Visibility Modifiers*/
    const [openShowAnnouncemetsModal, setOpenShowAnnouncemetsModal] = useState(false);
    const [openAnnouncementFormModal, setOpenAnnouncementFormModal] = useState(false);

    /*Show More Reusable Dialog Box Variables*/
    const [openShowMoreTargetsDialogBox, setOpenShowMoreTargetsDialogBox] = useState(false);
    const [showMoreTargetOfAnnouncementArray, setShowMoreTargetOfAnnouncementArray] = useState({});

    /*Description Visibility*/
    const [showOrHide, setShowOrHide] = useState(true);
    const [descriptionId, setDescriptionId] = useState(null);
    const [confirmVisibility, setConfirmVisibility] = useState(false);

    //Get Live Announcements : (starDate < Now && endDate > Now && isActive === true)
    const refreshLiveAnnouncements = () => {
        setLoading(true);
        getLiveAnnouncements()
            .then(res => {
                //All PUBLISHING announcements (active=true and startDate < Today > endDate)
                setLiveAnnouncements(res?.data);
            })
            .catch(() => { showError(announcementMessages().NOT_FETCH_PUBLISHING_ANNOUNCEMENTS) })
            .finally(() => { setLoading(false) })
    }

    const refreshPastAnnouncements = (pageParam, sizeParam) => {
        setLoading(true);
        setPastAnnouncements([]);
        getPastAnnouncements({ page: pageParam === undefined ? page : pageParam, size: sizeParam || size })
            .then(res => {
                setPastAnnouncements(res?.data?.content);
                setSize(res?.data?.size);
                setPage(res?.data?.number);
                setTotalPages(res?.data?.totalPages);
            })
            .catch(() => {
                showError(announcementMessages().HISTORY_ERROR);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    /*If the current user is privileged, this func will be executed */
    const refreshNextAnnouncements = () => {
        if (localStorage.getItem('userDetails') && privileges?.includes(PrivilegeConstants.ANNOUNCEMENT_MANAGEMENT) === true) {
            setLoading(true);
            getNextAnnouncements().then(res => {
                setNextAnnouncements(res?.data);
            })
                .catch(() => {
                    showError(announcementMessages().AWAITING_ERROR)
                })
                .finally(() => {
                    setLoading(false);
                })
        }
    }

    const handleDeleteAnnouncement = () => {
        setLoading(true);
        deleteAnnouncement(announcementIdFormInp)
            .then(() => {
                setConfirmVisibility(false);
                //Renew current announcements tab
                refreshLiveAnnouncements();
                //Gwaitings tab
                refreshNextAnnouncements();
                //renew history tab
                refreshPastAnnouncements();
                showSuccess(announcementMessages().DELETE_SUCCESSFULL);
                toast.success(announcementMessages().OPERATION_WILL_BE_VISIBLE_IN_5_MINUTES, {
                    transition: Flip,
                    autoClose: 5000
                })
            })
            .catch(e => {
                showError(t(e.response?.data?.message) || announcementMessages().DELETE_ERROR);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    /*Mark an announcement as READ.*/
    const markAsRead = (announcementId) => {
        setLoading(true);
        markAnnouncementAsRead(announcementId).then(() => {
            showSuccess(announcementMessages().READ_SUCCESSFUL);
            refreshLiveAnnouncements();
        })
            .catch((err) => {
                showError(announcementMessages().READ_ERROR + ' ' + err?.response?.data?.message ?? err.toString());
            })
            .finally(() => { setLoading(false) });
    }

    /*Mark an announcement as UNREAD again.*/
    const markAsUnread = (announcementId) => {
        setLoading(true);
        markAnnouncementAsUnread(announcementId).then(() => {
            showSuccess(announcementMessages().UNREAD_SUCCESFULLY);
            refreshLiveAnnouncements();
        })
            .catch((err) => {
                showError(announcementMessages().UNREAD_ERROR + ' ' + err?.response?.data?.message ?? err.toString());
            })
            .finally(() => { setLoading(false) });
    }

    //Below Code Segment: Columns of React Table for Announcement History------------------------------
    const columnsRender = () => [
        {
            header: () => <div>{NAME_HEADER()}</div>,
            accessorKey: 'name',
            id: 'name',
            cell: info => (<span>{info.row.original.name}</span>)
        },
        {
            header: () => <div>{DESCRIPTION_HEADER()}</div>,
            accessorKey: 'description',
            id: 'description',
            cell: info => (
                <div className='dsp-flx-jstfy-contnt-space-btw' style={{ flexDirection: 'row' }}>
                    {showMoreText(showOrHide, descriptionId, info.row.original)}
                    {info.row.original.description.length > ANNOUNCEMENT_CONSTANTS.ANNOUNCEMENT_HISTORY_DESCRIPTION_LENGTH &&
                        showMoreLabel(setShowOrHide, setDescriptionId, showOrHide, info.row.original, descriptionId)
                    }
                </div>

            )
        },
        {
            header: () => <div>{ANNOUNCER_HEADER()}</div>,
            accessorKey: 'announcerUsername',
            id: 'announcerUsername',
            cell: info => (<span>
                {info.row.original.announcerUsername}
                {info.row.original?.lastUpdaterUsername &&
                    <>
                        <br />
                        <Popup
                            position={POPUP_POSITIONS.BOTTOM_RIGHT}
                            content={announcementMessages().LASTLY_EDITED_BY + '|' + moment(info.row.original?.lastUpdateDate).format(`${HOUR_FORMAT}, ${CAPITAL_DAILY_FORMAT}`)}
                            trigger={<b>({announcementMessages().EDT_LABEL}: {info.row.original?.lastUpdaterUsername})</b>}>
                        </Popup>
                    </>
                }
            </span>)
        },
        {
            header: () => <div>{announcementMessages().TIME_INTERVAL_HEADER}</div>,
            accessorKey: 'startDate',
            id: 'startDate',
            cell: info => (<span>
                {announcementMessages().START_ABBR}: {moment(info.row.original.startDate).format(`${CAPITAL_DAILY_FORMAT}, ${HOUR_FORMAT}`)}{<br />}
                {announcementMessages().END_ABBR}: {moment(info.row.original.endDate).format(`${CAPITAL_DAILY_FORMAT}, ${HOUR_FORMAT}`)}
            </span>)
        },
        {
            header: () => <div>{TARGET_HEADER()}</div>,
            accessorKey: 'announcementTargetGroups',
            id: 'announcementTargetGroups',
            cell: info => (<div>
                {info.row.original?.publicAnnouncement && <div>{announcementMessages().PUBLIC_LABEL}</div>}
                {info.row.original?.grouplessUsersTargeted && <div>{announcementMessages().GROUPLESS_LABEL}</div>}
                {info.row.original?.announcementTargetGroups?.length > 0 ? info.row.original?.announcementTargetGroups?.length > 2 ?
                    <>
                        {info.row.original?.announcementTargetGroups?.map((oneGroup, idx) => idx < 2 && <div key={idx}>-{oneGroup.name}</div>)}
                        <div className={'text-of-show-more'} onClick={() => {
                            setShowMoreTargetOfAnnouncementArray(info.row.original?.announcementTargetGroups);
                            setOpenShowMoreTargetsDialogBox(true);
                        }}>{SHOW_MORE_BUTTON()}...</div>
                    </>
                    : info.row.original?.announcementTargetGroups?.map((oneGroup, idx) => <div key={idx}>-{oneGroup.name}</div>) : null
                }
            </div>)
        },
        {
            header: () => <div>{EVENTS_HEADER()}</div>,
            accessorKey: 'deletedBy',
            id: 'deletedBy',
            cell: info => (<div>
                {info.row.original?.deletedBy ?
                    <div style={{ color: 'red' }}>
                        <div>{announcementMessages().DELETED_BY_LABEL}: {info.row.original?.deletedBy}</div>
                        <div>{moment(info.row.original?.deletionDate).format(`${CAPITAL_DAILY_FORMAT}, ${HOUR_FORMAT}`)}</div>
                    </div>
                    : <div style={{ color: 'green' }}>{announcementMessages().COMPLETED_LABEL}.</div>}

            </div>)
        }
    ]

    /* BELOW CODE SEGMENT: Panes for Show Announcements Modal____________________________________________________________________________________*/
    const panes = () => [
        {
            menuItem: { key: 'actAnn', icon: 'play', color: 'green', content: `${announcementMessages().LIVE_TAB}` },
            render: () => <Tab.Pane loading={loading} attached={true} className='announcement-list-panes'>
                {liveAnnouncements?.length < 1 ? <h3 style={{ width: '100%', textAlign: 'center' }}>{announcementMessages().NO_ANNOUNCEMENT}.</h3>
                    : <>
                        {liveAnnouncements?.map((oneAnnouncement, i) =>
                            <Segment key={i} className='each-publishing-annoucement-segment'>
                                <div className='left-part-publishing-ann-segment' >
                                    <div className='announcer-icon-and-name-div'>
                                        <div>
                                            <Icon size='huge' name={USER_OUTLINE_ICON} />
                                            <div style={{ marginTop: '10px' }}><b>{oneAnnouncement?.announcerUsername}</b></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='right-part-publishing-ann-segment dsp-flx-jstfy-contnt-space-btw'>
                                    <div className='left-in-right-publishing-ann-segment'>
                                        <h3>{oneAnnouncement.name}</h3>
                                        <p className='text-area-preserve-writing-format'>{oneAnnouncement.description?.trim()}</p>
                                        {oneAnnouncement?.lastUpdaterUsername && <div style={{ marginBottom: '5px' }}>
                                            <i>{announcementMessages().LASTLY_EDITED_BY} : {oneAnnouncement?.lastUpdaterUsername} | {moment(oneAnnouncement?.lastUpdateDate)
                                                .format(`${HOUR_FORMAT}, ${CAPITAL_DAILY_FORMAT}`)}</i>
                                        </div>}
                                        <div>
                                            <b>{TARGET_HEADER()}: </b>
                                            {oneAnnouncement.publicAnnouncement && <Label color='orange' content={announcementMessages().PUBLIC_LABEL} icon='group' className='group-labels' />}
                                            {oneAnnouncement.grouplessUsersTargeted && <Label color='purple' content={announcementMessages().GROUPLESS_LABEL} icon='group' className='group-labels' />}
                                            {oneAnnouncement?.announcementTargetGroups?.length > 0 && <>
                                                {oneAnnouncement.announcementTargetGroups?.map((oneGroup, idx) =>
                                                    <Label key={idx} content={oneGroup.name} icon='group' className='group-labels' />)
                                                }</>
                                            }
                                        </div>
                                    </div>
                                    <div className='right-in-right-publishing-ann-segment dsp-flx-jstfy-contnt-space-btw'>
                                        <div className='vertical-line-div'>
                                            {/* Seperator line */}
                                            <div className='vertical-line-as-hr'></div>
                                        </div>
                                        <div className='unread-ann-div-as-button'>
                                            {!oneAnnouncement.read ?
                                                <Reveal animated='move up'>
                                                    <Reveal.Content visible>
                                                        <div className='ann-reveal-function-divs'>
                                                            <b className='new-word-ann-modal'>{announcementMessages().NEW_LABEL_UNREAD}!</b>
                                                        </div>
                                                    </Reveal.Content>
                                                    <Reveal.Content hidden>
                                                        <div className='ann-reveal-function-divs'
                                                            onClick={() => markAsRead(oneAnnouncement.id)}>
                                                            <Popup
                                                                position={POPUP_POSITIONS.BOTTOM_RIGHT}
                                                                content={announcementMessages().MARK_READ_POPUP}
                                                                trigger={
                                                                    <Icon name='check' color='green' size='big' />
                                                                }>
                                                            </Popup>
                                                        </div>
                                                    </Reveal.Content>
                                                </Reveal>
                                                : <Popup
                                                    position={POPUP_POSITIONS.BOTTOM_RIGHT}
                                                    content={announcementMessages().MARK_UNREAD_POPUP}
                                                    trigger={
                                                        <Icon className='mark-as-unread-ann-icon' name='undo' size='big'
                                                            onClick={() => markAsUnread(oneAnnouncement.id)} />
                                                    }>
                                                </Popup>}
                                        </div>
                                    </div>
                                </div>
                            </Segment>)}
                    </>}
            </Tab.Pane>
        },
        privileges?.includes(PrivilegeConstants.ANNOUNCEMENT_MANAGEMENT) === true &&
        {
            menuItem: { key: 'psvAnn', icon: 'clock', color: 'blue', content: `${announcementMessages().AWAITING_TAB}` },
            render: () => <Tab.Pane loading={loading} attached={true} className='announcement-list-panes'>
                <Table celled padded selectable className='table-border-none'>
                    {nextAnnouncements?.length < 1
                        ? <thead className='announcements-tab'><tr><th>{announcementMessages().NO_ANNOUNCEMENT}.</th></tr></thead>
                        : <>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{NAME_HEADER()}</Table.HeaderCell>
                                    <Table.HeaderCell>{DESCRIPTION_HEADER()}</Table.HeaderCell>
                                    <Table.HeaderCell>{TARGET_HEADER()}</Table.HeaderCell>
                                    <Table.HeaderCell className='text-align-c-imp'>{ACTIVE_LABEL()}</Table.HeaderCell>
                                    <Table.HeaderCell>{ANNOUNCER_HEADER()}</Table.HeaderCell>
                                    <Table.HeaderCell className='text-align-c-imp'>{ACTIONS_HEADER()}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {nextAnnouncements.map((oneAnnouncement, i) =>
                                    <Table.Row key={i}>
                                        <Table.Cell width={3}>{oneAnnouncement.name}</Table.Cell>
                                        <Table.Cell width={8} className='text-area-preserve-writing-format'>{oneAnnouncement.description?.trim()}</Table.Cell>
                                        <Table.Cell width={2}>
                                            {oneAnnouncement.publicAnnouncement && `${announcementMessages().PUBLIC_LABEL}`}
                                            {oneAnnouncement.grouplessUsersTargeted && `${announcementMessages().GROUPLESS_LABEL}`}
                                            {oneAnnouncement?.announcementTargetGroups?.length > 0 ? oneAnnouncement?.announcementTargetGroups?.length > 2 ?
                                                <>{oneAnnouncement?.announcementTargetGroups.map((oneGroup, idx) => idx < 2 && <div key={idx}>-{oneGroup.name}</div>)}
                                                    <div className={'text-of-show-more'} onClick={() => {
                                                        setShowMoreTargetOfAnnouncementArray(oneAnnouncement?.announcementTargetGroups);
                                                        setOpenShowMoreTargetsDialogBox(true);
                                                    }}>{SHOW_MORE_BUTTON()}
                                                    </div>
                                                </>
                                                : oneAnnouncement.announcementTargetGroups.map((oneGroup, idx) => <div key={idx}>-{oneGroup.name}</div>) : null
                                            }
                                        </Table.Cell>
                                        <Table.Cell width={1} className='text-align-c-imp'>{
                                            oneAnnouncement.active === true
                                                ? <Icon name={CHECK_ICON} color='green' />
                                                : <Icon name={X_ICON} color='red' />
                                        }
                                        </Table.Cell>
                                        <Table.Cell width={2}>
                                            {oneAnnouncement?.announcerUsername}
                                            {oneAnnouncement?.lastUpdaterUsername &&
                                                <>
                                                    <br />
                                                    <Popup
                                                        position={POPUP_POSITIONS.BOTTOM_RIGHT}
                                                        content={announcementMessages().LASTLY_EDITED_BY + ' |' + moment(oneAnnouncement?.lastUpdateDate)
                                                            .format(`${HOUR_FORMAT}, ${CAPITAL_DAILY_FORMAT}`)}
                                                        trigger={<i>({announcementMessages().EDT_LABEL}: {oneAnnouncement?.lastUpdaterUsername})</i>}>
                                                    </Popup>
                                                </>
                                            }
                                        </Table.Cell>
                                        <Table.Cell width={1} className='text-align-c-imp'>
                                            <Dropdown
                                                icon='ellipsis horizontal'
                                                button
                                                className='icon'
                                            >
                                                <Dropdown.Menu direction='left'>
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            setUpdatableAnnouncementObject(oneAnnouncement)
                                                            setOpenAnnouncementFormModal(true);
                                                        }}
                                                    >
                                                        <Icon name={EDIT_ICON} />{EDIT_BUTTON()}
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        disabled={loading}
                                                        loading={loading.toString()}
                                                        onClick={() => {
                                                            setAnnouncementIdFormInp(oneAnnouncement.id);
                                                            setConfirmVisibility(true);
                                                        }}
                                                    >
                                                        <Icon color='red' name={TRASH_ICON} />{DELETE_BUTTON()}
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                        </>}
                </Table>
            </Tab.Pane >
        },
        {
            menuItem: { key: 'hstAnn', icon: 'undo', color: 'red', content: `${announcementMessages().HISTORY_TAB}` },
            render: () => <Tab.Pane attached={true} className='announcement-list-panes'>
                <ReactTableV8
                    columnwiseFilterableProp={true}
                    columns={columnsRender()}
                    data={pastAnnouncements}
                    loadingProp={loading}
                    //Manual Pagination
                    manualPaginationProp={true}
                    pageIndexProp={page}
                    pageSizeProp={size}
                    totalPagesProp={totalPages}
                    onPaginationChangeProp={(pageIndex, sizeValue) => {
                        refreshPastAnnouncements(pageIndex, sizeValue)
                    }}
                />
            </Tab.Pane>
        }
    ]
    /* ABOVE CODE SEGMENT: Panes for Show Announcements Modal____________________________________________________________________________________*/

    /*Each Tab change will trigger it's data to be refreshed from related backend endpoint. */
    const refreshActiveTabPaneData = (activeIndex) => {
        if (activeIndex === 0) {
            //Refresh Publishing Announcements Tab
            refreshLiveAnnouncements();
        } else if (activeIndex === 1) {
            //Refresh In-Process Announcements Tab
            refreshNextAnnouncements();
        } else {
            //Refresh History Announcements Tab
            //Following line is disabled to prevent duplicate api calls
            //refreshPastAnnouncements();
        }
    }

    /* BELOW CODE SEGMENT : Use Effects____________________________________________________________________________________*/
    useEffect(() => {
        setOpenShowAnnouncemetsModal(openShowAnnouncemetsModalProp);
        //Call this api's only when the modal is opened.
        if (openShowAnnouncemetsModalProp) {
            refreshLiveAnnouncements();
            //for privileged
            //getInProcessAnnouncementsFunc();
            //for privileged
            //getAnnouncementHistoryFunc(DEFAULT_PAGINATION_PROPS.PAGE_INDEX, DEFAULT_PAGINATION_PROPS.PAGE_SIZE);
        }
    }, [openShowAnnouncemetsModalProp]);

    useEffect(() => {
        if (!openAnnouncementFormModal && updatableAnnouncementObject != null) {
            setUpdatableAnnouncementObject(null);
        }
    }, [openAnnouncementFormModal]);

    useEffect(() => {
        if (localStorage.getItem('privileges')) {
            setPrivileges(Base64.decode(
                localStorage.getItem('privileges')
            ).split(','));
        }
    }, []);
    /* ABOVE CODE SEGMENT : Use Effects____________________________________________________________________________________*/

    return (<>
        {/* BELOW CODE SEGMENT : Show Announcements Modal______________________________________________________________________*/}
        <Modal
            id='announcement-list-modal'
            open={openShowAnnouncemetsModal}
            onClose={() => {
                setOpenShowMoreTargetsDialogBox(false);
                closeShowAnnouncementModalFuncProp(false);
                getNewAnnouncementsFuncProp();
            }}
            closeIcon
        >
            <Modal.Header style={{ textAlign: 'center' }}>
                <div style={{ fontSize: '25px' }}><Icon name={BULLHORN_ICON} />{announcementMessages().ANNOUNCEMENTS_LABEL}</div>
            </Modal.Header>
            <Modal.Content>
                {/* announcement tabs, tables and react table placed here */}
                <Tab onTabChange={(e, data) => refreshActiveTabPaneData(data?.activeIndex)} style={{ width: '100%', margin: '0px auto' }} menu={{ secondary: true }} panes={panes()} />
            </Modal.Content>
            <Modal.Actions style={{ background: 'white' }}>
                {privileges?.includes(PrivilegeConstants.ANNOUNCEMENT_MANAGEMENT) === true &&
                    <Button color='blue'
                        onClick={() => {
                            setOpenAnnouncementFormModal(true);
                        }}
                        disabled={loading}
                        loading={loading}
                        icon={BULLHORN_ICON}
                        labelPosition='left'
                        content={announcementMessages().CREATE_NEW_ANNOUNCEMENT}
                    />
                }
                <Button color='red' onClick={() => {
                    //Close dialog box if it is still open
                    setOpenShowMoreTargetsDialogBox(false);
                    closeShowAnnouncementModalFuncProp(false);
                    //When modal is closed, get only new announcements again in App.js
                    getNewAnnouncementsFuncProp();
                }}
                    disabled={loading}
                    loading={loading}
                    content={CLOSE_BUTTON()}
                    icon={X_ICON}

                />
            </Modal.Actions>
        </Modal>
        {/* ABOVE CODE SEGMENT : Show Announcements Modal______________________________________________________________________*/}

        {/* Reusable Diaglog Box Component for showing more of target announcement groups*/}
        {
            openShowMoreTargetsDialogBox && <ShowMoreDialog
                inputProp={showMoreTargetOfAnnouncementArray}
                setOpenFuncProp={(event) => setOpenShowMoreTargetsDialogBox(event)}
                valueExtensionProp={'name'}
                segmentClassNameProp={'show-more-dialogbox-segment-announcement-targets'}
                innerDivClassNameProp={'show-more-dialogbox-inner-div-announcement-targets'}
                iconNameProp={'group'}
                headerProp={'Target Groups'}
            />
        }

        {/* Announcement Registration From---------------------------------------------- */}
        {
            openAnnouncementFormModal && <NewAnnouncementFormModal
                //Minimum requirements for opening modal and posting a new announcement
                openModalProp={openAnnouncementFormModal}
                setOpenModalFuncProp={(option) => setOpenAnnouncementFormModal(option)}
                updateListFunctionProp={()=>refreshNextAnnouncements()}

                // If modal is open for update
                updatableAnnouncementObjectProp={updatableAnnouncementObject}

                localeProp={localeProp}
            />
        }

        <Confirm
            content={announcementMessages().DELETE_CONFIRM}
            open={confirmVisibility}
            onCancel={() => setConfirmVisibility(false)}
            onConfirm={handleDeleteAnnouncement}
            confirmButton={CONFIRM_BUTTON()}
            cancelButton={CANCEL_BUTTON()}
        />
        {/* ABOVE CODE SEGMENT : Create & Update Announcements Modal__________________________________________________________________*/}
    </>);
}

export default AnnouncementsModal;
