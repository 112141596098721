import React from 'react'
import axios from 'axios'
import {Button, Dimmer, Grid, Loader, Segment} from 'semantic-ui-react'
import DeviceClient from '../../utils/DeviceClient';
import AndroidEventEmitter from '../../utils/AndroidEventEmitter';
import ManageDeviceInfo from './ManageDeviceInfo';
import MultiManageOperation from './MultiManageOperation';
import ManageLog from './ManageLog';
import {withRouter} from 'react-router-dom';
import {toast} from 'react-toastify';
import {withTranslation} from 'react-i18next';
import SwipeElements from '../../components/SwipeElements'
import {fetchScreenImageViaAppium, swipe} from '../../utils/AppiumClient'
import {
  APPIUM_SESSION_ID, DEBOUNCE_WAIT,
  DEVICEOS,
  ERROR_BASE64_SAMPLE,
  IMAGE_MODES,
  INSPECTOR_MODES,
  WEBSOCKET_STATES, WINDOW_RELOAD_TIMEOUT,
  INSPECTOR_SIZE_PX,
  INSPECTOR_SIZE_VW
} from '../../utils/Constants';
import {showError, showInfo} from '../../utils/ToastHelpers';
import _ from 'lodash';
import moment from 'moment';
import {
  getActiveTestSession,
  pingSession,
  reconnectSession,
  takeScreenshot,
  clickToTakeScreenshot,
  swipeToTakeScreenshot,
  getDeviceReservationInfo
} from '../../api/apiCalls'
import Chat from '../../components/chat/Chat'
import { parseJwt, getUserDetails } from '../../utils/Methods';
import {  CONCURRENT_ACCESS_LIMIT_LABEL, CONNECTION_TERMINATED_MESSAGE, deviceInstanceMessages, 
  FAILED_ERROR_MESSAGE, GET_REMOTE_INFO_ERROR, LOADING, NOT_STREAMED_DATA_MESSAGE, NOT_TAKE_SCREENSHOT, 
  OK_BUTTON, RETURN_TO_DEVICE_LIST_LABEL, TAKING_SCREENSHOTS_LOADER, busyDevicePubModal } from '../../utils/UIMessages';

import { ANGLE_LEFT_ICON, EXCHANGE_ICON, HOME_ICON, EXCLAMATION_ICON } from '../../utils/UiIcons';

import { connect } from "react-redux";
import BusyDeviceInfoModal from '../../components/BusyDeviceInfoModal';
import BusyDeviceSubModal from '../../components/BusyDeviceSubModal';
import BusyDevicePubModal from '../../components/BusyDevicePubModal';
import { HTTP_STATUS_CODES, WEBSOCKET_MESSAGES } from '../../utils/Constants';

const SECOND_SS_DELAY_MS = 1000;

class DeviceInstance extends React.Component {
  manageLogRef = undefined;
  dragImg = {};
  client = null;
  smartSSKeyBuffer = [];
  smartSSSwipeBuffer = [];
  state = {
    selectedDevice: {},
    deviceScreenState: "",                  // 1 = landscape | 0 = portrait
    scaleRatio: [],
    scaleRatioForFrozenInspector: [],              //Smaller ratio for frozen inspector screen
    imageAspectRatio: 1,
    imageBounds: [],
    screenResolution: [],
    icon: undefined,                        // anlık ekran görüntüsü
    socketStartRequestTaken: false,
    deviceImgStyle: {
      maxHeight: "calc(93vh - 120px)",
      maxWidth: "calc(-180px + 50vw)"
    },
    testSessionField: undefined,
    socketMessageID: "",
    activeIndex: 0,
    inspectorMode: INSPECTOR_MODES.MANUAL,
    swipeStart: [],
    swipeEnd: [],
    swipeStartOriginal: [],
    swipeEndOriginal: [],
    multiTouch: [],
    multiTouchToggle: false,
    isRecording: false,
    recordedActions: [],
    qualityValue: 0.5,
    hoveredElement: {},
    selectedElement: {},
    selectedElementPath: "",
    sourceJSON: {},
    boundsJSON: {},
    afterInspector: false,
    isSmartScreenshotOn: false,
    smartScreenshotClickInfo: { clickOffsetX: 0, clickOffsetY: 0, clickWidth: 0, clickHeight: 0 },
    smartScreenshotLoading: false,
    blockKeys: false,
    deviceReservationData:[],
    currentReservation:{},
    chatPortalVisibility : false,
    isVFAgentAvailable: false,
    userId:0,
    udId:'',
    numberOfClick: 1,
    numberOfFinger: 2,
    scroll :[],
    busyDeviceArgs : null,
    busyDeviceInfoModalVisibility: false,
    busyDeviceModalHeader: '',
    busyDeviceModalContent: '',
    busyDeviceButtonContent: '',
    busyDeviceSubModalVisibility: false,
    busyDeviceSubModalHeader: '',
    busyDeviceSubModalContent: '',
    busyDevicePubModalVisibility: false,
    busyDevicePubModalHeader: '',
    busyDevicePubModalContent: '',
    userDetails:{},
    clientSubscription: {},
    allowedUser: false,
    lastScrollDate: Date.now()
  }


  getDeviceInfo = async () => {
    let promiseObject
    //const isEmbedMode = this.props.match.path.includes('/embed-')
    const isEmbedMode = this.props.embedMode;
    if (isEmbedMode) {
      const token = this.props.match.params;
      const tokenPayload = parseJwt(token);
      const headers = {'Embedded-Screen-Key': `${token.token}`}
      promiseObject = axios.get(`/api/devices/${tokenPayload.udId}`, {headers})
    } else {
      promiseObject = axios.get(`/api/devices/${this.props.id}`)
    }
    try {
       const res = await promiseObject;
       if(res.data?.offlineReservedInfo?.reservationId){
          return;
       }
       const username = localStorage.getItem('username');
       this.setState({ selectedDevice: res.data }, () => {
         if (!isEmbedMode){
            this.getReservationInfo(username)
         }
       });
      // eslint-disable-next-line no-unused-vars
      if (Object.entries(res.data.status).some(([k, v]) => v) &&
      !this.state.selectedDevice?.users?.some(user => user.userName === username) &&
      (isEmbedMode || !this.state.selectedDevice?.status.Reserved)) {
        this.props.addToBusyStateList(res.data.deviceId, res.data.status);
        this.props.openUsedDeviceWarningModal();
      }
      this.props.addToChatDeviceList(res.data.id,res.data.deviceId)
    } catch (err) {
      showError(`${FAILED_ERROR_MESSAGE()} ${err?.response?.status}`);
    }
  }


  updateScaleRatio = () => {
    if (this.state.imageAspectRatio !== 1) {
      let resolutionWidth = this.state.screenResolution[0];
      let resolutionHeight = this.state.screenResolution[1];
      let updatedImageHeight = window.innerHeight * 0.93 - 120;  // 93vh-120px
      let frozenInspectorUpdatedImageHeight = window.innerHeight * 0.70 - 120;  // smaller sizes for frozen inspector, 70vh-120px
      if (this.state.deviceScreenState === '1' || this.state.deviceScreenState === '2') { //landscape mode
        // 26.8vw - 177px
        updatedImageHeight = window.innerWidth * INSPECTOR_SIZE_VW - INSPECTOR_SIZE_PX;
        //26.8vw - 177px
        frozenInspectorUpdatedImageHeight = window.innerWidth * INSPECTOR_SIZE_VW - INSPECTOR_SIZE_PX;
        resolutionWidth = this.state.screenResolution[1];
        resolutionHeight = this.state.screenResolution[0];
      }
      let updatedImageWidth = updatedImageHeight * this.state.imageAspectRatio; //Normal secreen's size attributes
      let frozenInspectorUpdatedImageWidth = frozenInspectorUpdatedImageHeight * this.state.imageAspectRatio;
      this.setState({
        scaleRatioForFrozenInspector: resolutionWidth / frozenInspectorUpdatedImageWidth, //small inspector's size attributes
        scaleRatio: [updatedImageWidth / resolutionWidth, updatedImageHeight / resolutionHeight],
        imageBounds: [updatedImageWidth, updatedImageHeight]
      });
    }
  }


  onLoadImage = (event) => {
    let imageHeight = window.innerHeight * 0.93 - 120;  // 93vh-120px
    if (this.state.deviceScreenState === '1' || this.state.deviceScreenState === '2') { //landscape mode
      // 26.8vw - 177px
      imageHeight = window.innerWidth * INSPECTOR_SIZE_VW - INSPECTOR_SIZE_PX;
    }
    let imageWidth = imageHeight * this.state.imageAspectRatio;
    this.setState({
      imageBounds: [imageWidth, imageHeight],
      imageAspectRatio: event.target.width / event.target.height
    }, () => this.updateScaleRatio());
  }


  takeDelayedScreenshot = (delayMs) => {
    setTimeout(async () => {
      try {
        /**
         * await axios.post(`/api/test-session/${this.state.testSessionField?.id}/screenshot`);
         */
        await takeScreenshot(this.state.testSessionField?.id);
      } catch (err) {
        showError(`${NOT_TAKE_SCREENSHOT()}: ${err?.response?.status}`);  
      } finally {
        this.setState({ smartScreenshotLoading: false });
      }
    }, delayMs);
  }

  smartSSForSwipe = (offsetX, offsetY, width, height) => {
    this.setState({ smartScreenshotLoading: true });
    const swipeBuffer = this.smartSSSwipeBuffer;
    /**
     * axios.post(`/api/test-session/${this.state.testSessionField?.id}/screenshot`, {
      type: "swipe",
      x1: swipeBuffer[0].x * 1.0 / width,
      y1: swipeBuffer[0].y * 1.0 / height,
      x2: offsetX * 1.0 / width,
      y2: offsetY * 1.0 / height
    })
     */

    swipeToTakeScreenshot(this.state.testSessionField?.id,swipeBuffer,offsetX, offsetY, width, height).then(() => {
      this.eventEmitter.emit("CLICK" + this.props.id, swipeBuffer[0].x, swipeBuffer[0].y, width, height );
      for (let i = 1; i < swipeBuffer.length; ++i) {
        this.eventEmitter.emit("DRAG" + this.props.id, swipeBuffer[i].x, swipeBuffer[i].y, width, height);
      }
      this.eventEmitter.emit("RELEASE" + this.props.id, offsetX, offsetY, width, height );
      this.takeDelayedScreenshot(SECOND_SS_DELAY_MS);
    }).catch(err => {
        showError(`${NOT_TAKE_SCREENSHOT()}: ${err?.response?.status}`);
        this.setState({ smartScreenshotLoading: false });
    })
  }

  smartSSForKey = () => {
    this.setState({ smartScreenshotLoading: true });
    /**
     * axios.post(`/api/test-session/${this.state.testSessionField?.id}/screenshot`)
     */
    takeScreenshot(this.state.testSessionField?.id).then(() => {
        for (let i = 0; i < this.smartSSKeyBuffer.length; ++i) {
          if (this.smartSSKeyBuffer[i].value) {
            this.eventEmitter.emit(this.smartSSKeyBuffer[i].type + this.props.id, this.smartSSKeyBuffer[i].value);
          } else {
            this.eventEmitter.emit(this.smartSSKeyBuffer[i].type + this.props.id);
          }
        }
        this.smartSSKeyBuffer = [];
        this.takeDelayedScreenshot(SECOND_SS_DELAY_MS);
      })
      .catch(err => {
        showError(`${NOT_TAKE_SCREENSHOT()}: ${err?.response?.status}`);
        this.setState({ smartScreenshotLoading: false });
      });
  }
  smartSSForKey = _.debounce(this.smartSSForKey, DEBOUNCE_WAIT.MEDIUM);

  onAddEventListeners = () => {
    window.addEventListener('resize', this.updateScaleRatio);
  }


  handleOnClickForSwipe = (e) => {
    if (this.state.swipeStart.length > 0 && this.state.swipeEnd.length > 0) {
      showError(deviceInstanceMessages().WAIT_SWIPE_COMPLETE_MESSAGE);
    } else if (this.state.swipeStart.length > 0) {
      this.setState({
        swipeEnd: [e.nativeEvent.offsetX, e.nativeEvent.offsetY],
        swipeEndOriginal: [e.nativeEvent.offsetX / this.state.scaleRatio[0], e.nativeEvent.offsetY / this.state.scaleRatio[1]]
      }, () => {
        // send swipe request to Appium with coordinates based on original screen resolution
        let sessionId = localStorage.getItem(APPIUM_SESSION_ID)
        if (sessionId) {
          swipe(sessionId, this.state.swipeStartOriginal[0], this.state.swipeStartOriginal[1], this.state.swipeEndOriginal[0], this.state.swipeEndOriginal[1]).then(() => {
            fetchScreenImageViaAppium(sessionId).then(res => {
              this.handleScreenshot(res?.data?.value)
              this.setState({ swipeStart: [], swipeEnd: [] })
              if (this.state.isRecording) {
                // record swipe action
                let updatedActionList = this.state.recordedActions.concat({
                  action: 'swipe', params: {
                    x1: this.state.swipeStartOriginal[0],
                    y1: this.state.swipeStartOriginal[1],
                    x2: this.state.swipeEndOriginal[0],
                    y2: this.state.swipeEndOriginal[1]
                  }
                })
                this.setState({ recordedActions: updatedActionList })
              }
            }).catch(err => {
              console.log(`Error in fetching screenshot from Appium: ${err}`)
            })
          })
        }
      })
    } else {
      this.setState({
        swipeStart: [e.nativeEvent.offsetX, e.nativeEvent.offsetY],
        swipeStartOriginal: [e.nativeEvent.offsetX / this.state.scaleRatio[0], e.nativeEvent.offsetY / this.state.scaleRatio[1]]
      })
    }
  }


  handleOnClick = (e) => {
    if (this.state.inspectorMode === INSPECTOR_MODES.SWIPE) {
      this.handleOnClickForSwipe(e);
    } else {
      if (this.client.getSocketState() === WEBSOCKET_STATES.OPEN) {
        if (this.state.multiTouchToggle) {
          let params = {
            x: e.nativeEvent.offsetX,
            y: e.nativeEvent.offsetY,
            width: e.target.width,
            height: e.target.height
          };
          this.setState(
            prevState => ({ multiTouch: [...prevState.multiTouch, params] }),
            () => {
              if (this.state.multiTouch.length === this.state.numberOfFinger) {
                this.eventEmitter.emit('MULTI_TOUCH' + this.props.id, this.state.multiTouch, this.state.numberOfFinger, this.state.numberOfClick);
                this.setState({ multiTouch: [], multiTouchToggle: false })
              }
            })
        } else {
          if (this.state.testSessionField?.id && this.state.isSmartScreenshotOn) {
            this.setState({ 
              smartScreenshotClickInfo: {
                clickOffsetX: e.nativeEvent.offsetX,
                clickOffsetY: e.nativeEvent.offsetY,
                clickWidth: e.target.width,
                clickHeight: e.target.height 
            }});
          } else {
            this.eventEmitter.emit("CLICK" + this.props.id, e.nativeEvent.offsetX, e.nativeEvent.offsetY, e.target.width, e.target.height)
          }
        }
        if (this.state.isRecording) {
          let updatedList = this.state.recordedActions.concat({
            action: 'tap',
            params: { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY }
          })
          this.setState({ recordedActions: updatedList })
        }
      }
    }
    const isServerDead = this.client?.getSocketState() === WEBSOCKET_STATES.CLOSED &&
      this.state.socketMessageID !== "CONNECTION_TERMINATED_BY" &&
      !this.props.idleModalVisibility &&
      this.state.activeIndex !== 4 &&
      this.state.icon !== ERROR_BASE64_SAMPLE &&
      this.state.inspectorMode !== INSPECTOR_MODES.SWIPE &&
      this.state.inspectorMode !== INSPECTOR_MODES.TAPELEMENTS

    if (isServerDead) {
      this.props.setErrorInfo(true, `${deviceInstanceMessages().CONNECTION_BROKEN_MESSSAGE} (${this.props.id})`, deviceInstanceMessages().CONNECTION_LOST_MESSAGE, RETURN_TO_DEVICE_LIST_LABEL());
      this.setState({ icon: ERROR_BASE64_SAMPLE });
    }
  };


  handleKeyEvents = (e) => {
    if(e.keyCode === 17 || e.metaKey) { //CTRL
      this.setState({"blockKeys": true})
    }else{
      this.setState({"blockKeys": false})
    }

    if (this.client.getSocketState() === WEBSOCKET_STATES.OPEN && !this.state.blockKeys) {
      if (this.state.testSessionField?.id && this.state.isSmartScreenshotOn) {
        if (!this.state.smartScreenshotLoading) {
          if (e.key === 'Enter') {
            if (this.state.selectedDevice.os.toLowerCase() === DEVICEOS.IOS) {
              this.smartSSKeyBuffer.push({type: "KEY", value: '\u000D'});
            } else {
              this.smartSSKeyBuffer.push({type: "ENTER"});
            }
          } else if (e.key === 'ArrowRight') {
            this.smartSSKeyBuffer.push({type: "RIGHTKEY", value: e.key});
          } else if (e.key === 'ArrowLeft') {
            this.smartSSKeyBuffer.push({type: "LEFTKEY", value: e.key});
          } else if (e.keyCode === 8) {
            this.smartSSKeyBuffer.push({type: "KEY", value: '\u0008'});
          } else if (e.keyCode === 46) {
            this.smartSSKeyBuffer.push({type: "KEY", value: '\u007F'});
          } else if (e.keyCode === 32) {
            e.preventDefault()
            this.smartSSKeyBuffer.push({type: "KEY", value: e.key});
          } else {
            this.smartSSKeyBuffer.push({type: "KEY", value: e.key});
          }
          this.smartSSForKey();
        }
      } else {
        if (e.key === 'Enter') {
          if (this.state.selectedDevice.os.toLowerCase() === DEVICEOS.IOS) {
            this.eventEmitter.emit("KEY" + this.props.id, '\u000D');
          } else {
            this.eventEmitter.emit("ENTER" + this.props.id)
          }
        } else if (e.key === 'ArrowRight') {
          this.eventEmitter.emit("RIGHTKEY" + this.props.id, e.key)
        } else if (e.key === 'ArrowLeft') {
          this.eventEmitter.emit("LEFTKEY" + this.props.id, e.key)
        } else if (e.keyCode === 8) { // backspace
          this.eventEmitter.emit("KEY" + this.props.id, '\u0008');
        } else if (e.keyCode === 46) { // delete
          this.eventEmitter.emit("KEY" + this.props.id, '\u007F')
        } else if (e.keyCode === 32) { // space
          e.preventDefault()
          this.eventEmitter.emit("KEY" + this.props.id, e.key)
        } else {
          this.eventEmitter.emit("KEY" + this.props.id, e.key)
        }
      }
    }
  }


  handleDragEventStart = (e) => {
    e.dataTransfer.setDragImage(this.dragImg, 0, 0);
    if (this.client.getSocketState() === WEBSOCKET_STATES.OPEN) {
      if (this.state.testSessionField?.id && this.state.isSmartScreenshotOn) {
        this.smartSSSwipeBuffer = [ { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY } ];
      } else {
        e.dataTransfer.setDragImage(this.dragImg, 0, 0);
        this.eventEmitter.emit("CLICK" + this.props.id, e.nativeEvent.offsetX, e.nativeEvent.offsetY, e.target.width, e.target.height)
        e.dataTransfer.setDragImage(this.dragImg, 0, 0);
      }
    }
  }

  handleDragEvent = (e) => {
    if (this.client.getSocketState() === WEBSOCKET_STATES.OPEN) {
      if (this.state.testSessionField?.id && this.state.isSmartScreenshotOn) {
        const { offsetX, offsetY } = e.nativeEvent;
        this.smartSSSwipeBuffer.push({ x: offsetX, y: offsetY });
      } else {
        this.eventEmitter.emit("DRAG" + this.props.id, e.nativeEvent.offsetX, e.nativeEvent.offsetY, e.target.width, e.target.height)
      }
      e.stopPropagation();
      e.preventDefault();
    }
  }

  handleDragEventStop = (e) => {
    if (this.client.getSocketState() === WEBSOCKET_STATES.OPEN) {
      // for ondragend event, offsetx and offsety equals 0 on mozilla firefox
      if (this.state.testSessionField?.id && this.state.isSmartScreenshotOn) {
        const { offsetX, offsetY } = e.nativeEvent;
        const { width, height } = e.target;
        this.smartSSForSwipe(offsetX, offsetY, width, height);
      } else {
        this.eventEmitter.emit("RELEASE" + this.props.id, e.nativeEvent.offsetX, e.nativeEvent.offsetY, e.target.width, e.target.height)
      }
    }
  }

  handleDropEvent = (e) => {
    if (this.client.getSocketState() === WEBSOCKET_STATES.OPEN) {
      if (this.state.testSessionField?.id && this.state.isSmartScreenshotOn) {
        const { offsetX, offsetY } = e.nativeEvent;
        const { width, height } = e.target;
        this.smartSSForSwipe(offsetX, offsetY, width, height);
      } else {
        this.eventEmitter.emit("RELEASE" + this.props.id, e.nativeEvent.offsetX, e.nativeEvent.offsetY, e.target.width, e.target.height)
      }
    }
  }

  handleMouseRelease = (e) => {
    if (!this.state.multiTouchToggle && this.client.getSocketState() === WEBSOCKET_STATES.OPEN) {
      if (this.state.testSessionField?.id && this.state.isSmartScreenshotOn) {
        const { offsetX, offsetY } = e.nativeEvent;
        const { width, height } = e.target;
        this.setState({ smartScreenshotLoading: true });
        const { clickOffsetX, clickOffsetY, clickWidth, clickHeight } = this.state.smartScreenshotClickInfo;
        /**
         * axios.post(`/api/test-session/${this.state.testSessionField?.id}/screenshot`, {
          type: "click",
          x1: clickOffsetX * 1.0 / clickWidth,
          y1: clickOffsetY * 1.0 / clickHeight
        })
         */

        clickToTakeScreenshot(this.state.testSessionField?.id,clickOffsetX, clickOffsetY, clickWidth, clickHeight).then(() => {
          this.eventEmitter.emit("CLICK" + this.props.id, clickOffsetX, clickOffsetY, clickWidth, clickHeight);
          this.eventEmitter.emit("RELEASE" + this.props.id, offsetX, offsetY, width, height);
          this.takeDelayedScreenshot(SECOND_SS_DELAY_MS);
        }).catch(err => {
            showError(`${NOT_TAKE_SCREENSHOT()}: ${err?.response?.status}`);
            this.setState({ smartScreenshotLoading: false });
        })
      } else {
        this.eventEmitter.emit("RELEASE" + this.props.id, e.nativeEvent.offsetX, e.nativeEvent.offsetY, e.target.width, e.target.height);
      }
    }
  }


  handleMultiTouch = () => {
    this.setState(prevState => ({
      multiTouchToggle: !prevState.multiTouchToggle
    }));
  }

  toggleSmartScreenshot = () => {
    this.setState(prev => ({
      isSmartScreenshotOn: !prev.isSmartScreenshotOn
    }));
  }

  handleChangeDeviceLocale = locale => {
    this.eventEmitter.emit('CLANG' + this.props.id , locale);
  }

  handleQuality = event => {
    if (this.client.getSocketState() === WEBSOCKET_STATES.OPEN) {
      this.setState({ qualityValue: event.target.value }, () => {
        this.eventEmitter.emit("QUALITY" + this.props.id, this.state.qualityValue)
      });
    }
  };

  handleRotate = () => {
    this.eventEmitter.emit("ROTATE" + this.props.id);
  }

  handleSwitchLock = () => {
    this.eventEmitter.emit("SWITCHLOCK" + this.props.id);
  }

  handleScroll = (event) => {
    event.preventDefault()
    if (Date.now() - this.state.lastScrollDate < 250) {
      return;
    }
    this.setState({ lastScrollDate: Date.now() });

    const params = {
      x: event.offsetX ?? event.nativeEvent?.offsetX,
      y: event.offsetY ?? event.nativeEvent?.offsetY,
      width: event?.target?.width,
      height: event?.target?.height
    };
    this.setState(
      prevState => ({ scroll: [...prevState.scroll, params] }),
      () => {
        this.eventEmitter.emit('SCROLL' + getScrollDirection(event) + this.props.id, this.state.scroll);
        this.setState({ scroll: [] })
      })
  }

  handleHasFocus = () => {
    this.eventEmitter.emit(WEBSOCKET_MESSAGES.PONG + this.props.id, `FOCUS|${document.hasFocus()}`);
  }

  handleCursorMove = () => {
    this.eventEmitter.emit(WEBSOCKET_MESSAGES.PONG + this.props.id, `CURSOR|true`);
  }

  handleOnCloseWarning = () => {
    this.eventEmitter.emit(WEBSOCKET_MESSAGES.PONG + this.props.id, `DEVICE|true`);
  }

  startDeviceStream = () => {
    this.eventEmitter = new AndroidEventEmitter();

    this.eventEmitter.on('jpeg' + this.props.id, (jpeg) => {
      if (jpeg) {
        this.setState({ icon: jpeg }, () => {
          let img = new Image();
          img.src = "data:image/png;base64," + jpeg;
          if (this.props.detailedInfoId === this.props.id) {
            this.setState({ deviceImgStyle: {
              maxHeight: "calc(93vh - 120px)",
              maxWidth: "calc(-180px + 50vw)"
            }});
          } else {
            this.setState({ deviceImgStyle: {
              maxHeight: "calc(93vh - 120px)",
              maxWidth: "calc(95vw)"
            }});
          }
          if (img.height > img.width) { //portrait mode
            this.setState({deviceScreenState: "0"})
          }
          if (img.height < img.width) { //landscape mode
            this.setState({deviceScreenState: "1" });
          }
        })
      } else {
        showError(NOT_STREAMED_DATA_MESSAGE());
        setTimeout(() => this.props.history.push('/device-list'), 1750);
      }
    });

    this.eventEmitter.on('connection', data => {
      const {payload, id, args, action} = data;

      if(payload === 'concurrent access limit' || payload === 'license access limit'){
        this.props.setErrorInfo(true, CONCURRENT_ACCESS_LIMIT_LABEL(),deviceInstanceMessages(data.payload).MAXIMUM_LIMIT_REACHED_MESSAGE, OK_BUTTON());
        this.props.removeFromConnectedDeviceIdList(this.props.id,false);
      }else if(payload.toString().startsWith('screenRotate') && args && action){
        if(action !== 'rotation-update'){
          showInfo(this.props.t(payload).replace('%s', args[0]));
        }
      }else if(id === 'CONNECTION_TERMINATED_BY'){
        toast.dismiss();
        this.props.setErrorInfo(true, `${CONNECTION_TERMINATED_MESSAGE()} (${this.props.id})`, this.props.t(payload)
            .replace('%s', args[0]), RETURN_TO_DEVICE_LIST_LABEL());
        this.setState({ socketMessageID: id });
      }else if (data.id === 'VF_AGENT'){
        this.setState({isVFAgentAvailable: payload.available})
      }else if(data.id === 'USER_WANTS_TO_USE_DEVICE'){
        const user = data?.args[0]?.split(':');
        this.setBusyDevicePubModal(true, busyDevicePubModal().HEADER,busyDevicePubModal(user).CONTENT , data?.args);
      }else if (data.id === 'QUALITY'){
        this.setState({qualityValue: payload.value})
      } else if (data.id === 'PING') {
        const query = payload.query;
        if (query === "focus") {
          this.handleHasFocus();
        }
      } else if (data.id === 'WARN') {
        const message = payload.message;
        this.props.warnForIdleDevice(message);
      }  else if (data.id === 'TERM') {
        this.manageLogRef?.setDeviceDisconnected();
        const message = payload.message;
        this.props.termForIdleDevice(message);
      } else{
        showInfo(this.props.t(payload));
      }
    })

      let promiseObject;

      if (!this.props.match.path.includes('/embed-')) {
        promiseObject = axios.get('/api/screen-share/start', {params: {udId: this.props.id}})
      } else {
        const token = this.props.match.params;
        const tokenPayload = parseJwt(token);
        this.setState({ userId: tokenPayload.userId });
        this.setState({ udId: tokenPayload.udId });
        if (this.props.match.path.includes('/embed-manage/')) {
          promiseObject = axios.get('/api/screen-share/manage', {params: {udId:tokenPayload.udId}, headers: {'Embedded-Screen-Key':`${token.token}`}})
        }
        if (this.props.match.path.includes('/embed-inspector/')) {
          promiseObject = axios.get('/api/screen-share/inspector', {params: {udId:tokenPayload.udId}, headers: {'Embedded-Screen-Key':`${token.token}`}})
        }
      }

      promiseObject.then(response => {
      if (response?.data?.port) {
        let ipAddress
        let url;
        let schema;
        ipAddress = window.location.host;
        if (window.location.protocol === "https:") {
          schema = "wss";
        } else {
          schema = "ws";
        }
        if (window.location.host.includes("localhost:") || process.env.NODE_ENV === 'development') { //#fordevelopment
          schema = response.data.schema;
          ipAddress = response.data.ipAddress;
        }
        url = schema + "://" + ipAddress + "/websocket/" + response.data.port.toString();
        url += `?token=${response.data.token}`;

        this.client = new DeviceClient(url, this.eventEmitter, this.props.id);
        this.props.addToClientList(this.client);
        this.setState({ socketStartRequestTaken: false })

      } else {
        if(response?.data?.status == HTTP_STATUS_CODES[6].value){
          this.setBusyDeviceSubModal(true, deviceInstanceMessages().BUSY_DEVICE, this.props.t(deviceInstanceMessages().BUSY_DEVICE_INFO_MSG));
          return;
        }else{
          showError(GET_REMOTE_INFO_ERROR());
          setTimeout(() => this.props.history.push('/device-list'), 1750);
        }
      }
    }).catch(err => {
      const message = err?.response?.data?.message;
      if (message) {
        if(message.includes('concurrent access limit')){
          this.props.setErrorInfo(true, deviceInstanceMessages().LIMIT_ACCESS_ERROR_MESSAGE, this.props.t(message), RETURN_TO_DEVICE_LIST_LABEL());
          return;
        }else if(message.includes('offline reserved')){
          this.props.setErrorInfo(true, deviceInstanceMessages().OFFLINE_RESERVED, this.props.t(message), deviceInstanceMessages().RETURN_TO_DEVICE_LIST);
          return;
        }else if(message.includes(deviceInstanceMessages().BUSY_DEVICE_GROUP_ONLY) || message.includes(deviceInstanceMessages().BUSY_DEVICE_UNAVAILABLE )){
          this.setBusyDeviceInfo(true, deviceInstanceMessages().BUSY_DEVICE, this.props.t(message), deviceInstanceMessages().RETURN_TO_DEVICE_LIST);
          return;
        }else{
          showError(`${err?.response?.data?.message}`);
        }
      } else if (err?.response?.data) {
        showError(`${err?.response?.data}`);
      } else {
        showError(FAILED_ERROR_MESSAGE());
      }

      setTimeout(() => this.props.history.push('/device-list'), 1750);
    });
    if(this.state.allowedUser){
      setTimeout(() => {
        this.state.clientSubscription?.unsubscribe(this.props.id + ':' + this.state.userDetails?.id);
      },1000)
    }
  }


  pressPyhsicalButton = (buttonType) => {
    if (this.client.getSocketState() === WEBSOCKET_STATES.OPEN) {
      this.eventEmitter.emit(buttonType + this.props.id);
    }
  }


  buttonSelector = () => {
    if (this.state.selectedDevice?.os?.toLowerCase().indexOf(DEVICEOS.IOS) >= 0) {
      return (<>
        <Button icon={HOME_ICON}
          onClick={() => this.pressPyhsicalButton("HOME")} />
      </>
      )
    } else {
      return (
        <>
          <Button icon={ANGLE_LEFT_ICON}
            onClick={() => {
              this.pressPyhsicalButton("BACK")
              if (this.state.isRecording) {
                let updatedList = this.state.recordedActions.concat({ action: 'back', params: {} })
                this.setState({ recordedActions: updatedList })
              }
            }} />
          <Button icon={HOME_ICON}
            onClick={() => this.pressPyhsicalButton("HOME")} />
          <Button icon={EXCHANGE_ICON}
            onClick={() => this.pressPyhsicalButton("MENU")} />
        </>
      )
    }
  }


  findElementByPath(path) {
    let selectedElement = this.state.sourceJSON;
    for (let index of path.split('.')) {
      if (selectedElement.children !== undefined) {
        selectedElement = selectedElement.children[index]
      }
    }
    return {...selectedElement};
  }


  selectHoveredElement = (path) => {
    // hovered element path can be found here
    this.setState({ hoveredElement: this.findElementByPath(path) });
  }


  setActiveIndex = activeIndex => this.setState({ activeIndex });


  handleChangeImageMode(imageMode) {
    if (imageMode === IMAGE_MODES.FROZEN) {
      if (this.client) {
        this.client.closeSocket();
      }
    } else if (imageMode === IMAGE_MODES.STREAM) {
      if (this.client.getSocketState() === WEBSOCKET_STATES.CLOSED && !this.state.socketStartRequestTaken) {
        this.setState({ socketStartRequestTaken: true, hoveredElement: {}, selectedElement: {} }, () => {
          this.startDeviceStream()
        })
      }
    } else {
      console.log(`Error in image mode. Mode ${imageMode} is not valid.`)
    }
  }


  handleChangeInspectorMode = (inspectorMode) => {
    if (this.state.inspectorMode !== inspectorMode) {
      this.setState({ inspectorMode: inspectorMode });
      if (inspectorMode !== INSPECTOR_MODES.SWIPE) {
        this.setState({ swipeStart: [], swipeEnd: [] });
      }
      if (inspectorMode === INSPECTOR_MODES.MANUAL) {
        this.handleChangeImageMode(IMAGE_MODES.STREAM);
      } else {
        this.handleChangeImageMode(IMAGE_MODES.FROZEN);
        this.updateScaleRatio();
      }
    }
  }


  optimizeBounds(sourceJSON) {
    if (!sourceJSON) {
      return [];
    }
    let temp_list = [];
    if (sourceJSON?.children?.length === 0) {
      return [];
    }
    sourceJSON.children.map((element) => {
      let x = element.attributes.x
      let y = element.attributes.y
      let width = element.attributes.width
      let height = element.attributes.height
      
      let inspectorWidth=0 ,inspectorHeight=1; //for IOS device's ladnscape mode adaptation
      if(this.state.selectedDevice.os==='IOS'){
        //IOS Devices
        if (this.state.deviceScreenState === '1' || this.state.deviceScreenState === '2') { //Landscape Mode
          inspectorWidth = 1;
          inspectorHeight = 0;
        }
        //else portrait mode will continue as 0-1 values, respectively.
      }
      if (((+x + +width) > this.state.screenResolution[inspectorWidth]) || ((+y + +height) > this.state.screenResolution[inspectorHeight])) {
        element.attributes.x = 0
        element.attributes.y = 0
        element.attributes.width = 0
        element.attributes.height = 0
      }
      if (+x < 0) {
        element.attributes.x = 0
      }
      if (+y < 0) {
        element.attributes.x = 0
      }
      temp_list.push({
        ...element,
        children: this.optimizeBounds(element),
        tagName: element.tagName,
        attributes: element.attributes,
        xpath: element.xpath,
        path: element.path
      });
    });
    return temp_list
  }


  handleSelectedElement = (path) => {
    this.setState({ selectedElement: this.findElementByPath(path) });
  }


  handleAppSource = (sourceJSON) => {
    let optimizedBounds = this.optimizeBounds(sourceJSON);
    if (optimizedBounds.length > 0) {
      this.setState({
        sourceJSON: sourceJSON,
        boundsJSON: this.optimizeBounds(sourceJSON)[0]
      }, this.updateScaleRatio);
    } else {
      this.setState({
        sourceJSON: sourceJSON,
        boundsJSON: sourceJSON
      }, this.updateScaleRatio);
    }
  }


  resetWebSocket = () => {
    if (this.client) {
      if (this.client.getSocketState() === WEBSOCKET_STATES.OPEN) {
        this.client.closeSocket();
        this.startDeviceStream();
      } else {
        setTimeout(this.resetWebSocket, 5);
        return;
      }
    }
  }


  handleScreenshot = (base64) => {
    this.setState({
      icon: base64,
      hoveredElement: {},
      selectedElement: {}
    }, () => {
      this.updateScaleRatio();
      this.selectSelectedElement("0");
    });
  }


  convertToTreePath(path) {
    if (typeof (path) === 'string') {
      if (path.length > 0) {
        // replace dots with hypens
        var treePath = path.replace(/\./g, '-');
        // add initial zero and hypen which are required for Tree View
        treePath = "0-" + treePath;
        path = treePath
      }
    }
    return path
  }


  selectSelectedElement = (path) => {
    this.setState({ selectedElementPath: this.convertToTreePath(path) }, () => {
      this.handleSelectedElement(path)
    })
  }


  handleScreenResolution = (screenResolution) => {
    this.setState({ screenResolution: screenResolution });
  }


  updateRecordedActions = (updatedActions) => {
    this.setState({ recordedActions: updatedActions })
  }


  handleRecordingStatus = () => {
    this.setState({ isRecording: !this.state.isRecording })
  }


  handleClearActions = () => {
    this.setState({ recordedActions: [], isRecording: false })
  }


  setAfterInspector = (afterInspector) => {
    this.setState({ afterInspector: afterInspector })
  }


  handleInspectorStarted = () => {
    this.setState({ afterInspector: true })
  }

  pingSession = () => {
    if (this.client?.getSocketState() !== WEBSOCKET_STATES.CLOSED) {
      //axios.put(`/api/test-session/ping/${this.state.testSessionField.id}`)
      pingSession(this.state.testSessionField.id).catch(err => {
        this.props.setErrorInfo(true, deviceInstanceMessages().SESSION_ERROR, err?.response?.data?.message, OK_BUTTON());
      })
    }
  }


  getActiveSession = (sessionId) => {
    //axios.get(`/api/test-session/active/${sessionId}`)
    getActiveTestSession(sessionId).then(res => {
      this.startDeviceStream()
      this.setState({ testSessionField: res.data })
      setInterval(this.pingSession, 30000)
      this.pingSession();
    }).catch(err => {
      if (err?.response?.status === 403) {
        this.props.setErrorInfo(true, deviceInstanceMessages().FORBIDDEN_ERROR, err?.response?.data?.message, OK_BUTTON());
      } else {
        //axios.get(`/api/test-session/reconnect/${sessionId}`)
        reconnectSession(sessionId).then(res2 => {
          this.startDeviceStream()
          this.setState({ testSessionField: res2.data })
          setInterval(this.pingSession, 30000)
          this.pingSession();
        }).catch(err2 => {
          this.props.setErrorInfo(true, deviceInstanceMessages().SESSION_ERROR, err2?.response?.data?.message, OK_BUTTON());
        })
      }
    });
  }

  onPaste = (event) => {
    event.stopPropagation();
    event.preventDefault();
    let clipboardData = event.clipboardData || window.clipboardData;
    let pastedData = clipboardData.getData('Text');
    if(localStorage.getItem("currentDeviceId") != null){
      this.eventEmitter.emit("TEXT" + localStorage.getItem("currentDeviceId"), pastedData)
    }
  }

  getReservationInfo = username => {
    getDeviceReservationInfo(this.state.selectedDevice.id).then(res => {
      this.setState({deviceReservationData: res.data});
      if (this.state.selectedDevice.status?.Reserved) {
        const currentReservation = res.data.find(r => moment().isBetween(r.startTime, r.endTime));
        if (currentReservation) {
          this.setState({currentReservation});
          if(currentReservation.username !== username){
            this.props.addToBusyStateList(this.state.selectedDevice?.deviceId, this.state.selectedDevice?.status);
            this.props.openUsedDeviceWarningModal();
          }
        }
      }
    });
  };
  
  componentDidUpdate(prevProps) {
    if(this.props.refresh !== prevProps.refresh){
      this.initialize();
    }    
  }

  initialize = () => {
    this.getDeviceInfo();
    this.setState({userDetails : getUserDetails()})
    if (this.props.sessionId) {
      this.getActiveSession(this.props.sessionId);
    } else {
      this.startDeviceStream();
    }
    this.dragImg = new Image(0, 0);
    this.dragImg.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
  }

  async componentDidMount() {
    this.props.onRef(this);
    this.initialize();
  }


  componentWillUnmount() {
    this.props.onRef(undefined)
    console.log("Closing Socket Entry")
    window.removeEventListener('resize', this.updateScaleRatio);
    if (this.client) {
      console.log("Closing Socket")
      this.client.closeSocket()
    }
    console.log("Closing Socket End")
  }

  handleOnMouseOver = (e) => {
    localStorage.setItem("currentDeviceId", e.currentTarget.dataset.id)
    document.addEventListener('paste', this.onPaste);
    document.addEventListener('wheel', this.handleScroll, { passive: false })
  }

  handleOnMouseLeave = () => {
    localStorage.setItem("currentDeviceId", null)
    document.removeEventListener('paste', this.onPaste);
    document.removeEventListener('wheel', this.handleScroll, { passive: false })
  }

  renderRemainingReservationTime = () => {
    const then = new Date(this.state.currentReservation.endTime);
    const now = new Date();
    const time = moment.utc(moment(then, 'DD/MM/YYYY HH:mm:ss').diff(moment(now, 'DD/MM/YYYY HH:mm:ss'))).format('HH:mm:ss');
    if (time === '00:00:01') {
      setTimeout(() => window.location.reload(), WINDOW_RELOAD_TIMEOUT.ZERO);
    }
    return time
  };


  setAppiumSessionId = (appiumSessionId) => {
    if (this.client.getSocketState() === WEBSOCKET_STATES.OPEN) {
      this.eventEmitter.emit("SET_APPIUM_SESSION_ID"  + this.props.id, appiumSessionId);
    }
  };

  clearAppiumSessionId = () => {
    if (this.client.getSocketState() === WEBSOCKET_STATES.OPEN) {
      this.eventEmitter.emit("CLEAR_APPIUM_SESSION_ID" + this.props.id);
    }
  };

  handleFingerMultiTouch = (e, {value}) => {
    this.setState({
      numberOfFinger: value
    })
  }

  handleClickMultiTouch = (e, {value}) => {
    this.setState({
      numberOfClick: value
    })
  }

  resetHandleMultiTouch = () => {this.setState({multiTouch: []})}


  rejectUsage = msg =>{
    if(this.state.userDetails?.id === msg?.userId && this.props.id === msg?.udId){
      this.setState({busyDeviceSubModalVisibility:false},()=>{
        this.props.history.push('/device-list');
        this.state.clientSubscription.unsubscribe(this.props.id+':'+this.state.userDetails?.id);
      });
    }
  }

  confirmUsage = msg =>{
    if(this.state.userDetails?.id === msg?.userId && this.props.id === msg?.udId){
      this.setState({busyDeviceSubModalVisibility:false, allowedUser: true},()=>this.startDeviceStream());
    }
  }

  setBusyDeviceInfo = (infoVisibility, infoHeader, infoContent, infoBtnContent) => {
    this.setState({
      busyDeviceInfoModalVisibility: infoVisibility,
      busyDeviceModalHeader: infoHeader,
      busyDeviceModalContent: infoContent,
      busyDeviceButtonContent: infoBtnContent
    });
  }

  setBusyDeviceSubModal = (subVisibility, subHeader, subContent) => {
    this.setState({
      busyDeviceSubModalVisibility: subVisibility,
      busyDeviceSubModalHeader: subHeader,
      busyDeviceSubModalContent: subContent
    });
  }

  setBusyDevicePubModal = (pubVisibility, pubHeader, pubContent, args) => {
    this.setState({
      busyDevicePubModalVisibility: pubVisibility,
      busyDevicePubModalHeader: pubHeader,
      busyDevicePubModalContent: pubContent,
      busyDeviceArgs: args,
    });
  }

  onClickBusyModalConfirm = () => {
    this.props.history.push('/device-list');
  }


  render() {
    const { icon,busyDevicePubModalVisibility,busyDeviceSubModalVisibility, busyDeviceInfoModalVisibility} = this.state;
    if (icon !== undefined || this.props.errorModalVisibility || busyDevicePubModalVisibility || busyDeviceSubModalVisibility || busyDeviceInfoModalVisibility) {
      return (
        <>
       
          <BusyDeviceInfoModal 
              closeOnDimmerClick={false}
              closeOnEscape={false}
              headerIcon={EXCLAMATION_ICON}
              visibility={this.state.busyDeviceInfoModalVisibility}
              header={this.state.busyDeviceModalHeader}
              content={this.state.busyDeviceModalContent}
              buttonContent={this.state.busyDeviceButtonContent}
              onClick={this.onClickBusyModalConfirm}

          />

          <BusyDevicePubModal 
            closeOnDimmerClick={false}
            closeOnEscape={false}
            headerIcon='exclamation'
            visibility={this.state.busyDevicePubModalVisibility}
            header={this.state.busyDevicePubModalHeader}
            content={this.state.busyDevicePubModalContent}
            args={this.state.busyDeviceArgs}
            onClose={()=>this.setState({busyDevicePubModalVisibility:false})}
          />

          { this.props.client != null &&
            <BusyDeviceSubModal 
              closeOnDimmerClick={false}
              closeOnEscape={false}
              headerIcon={EXCLAMATION_ICON}
              visibility={this.state.busyDeviceSubModalVisibility}
              header={this.state.busyDeviceSubModalHeader}
              content={this.state.busyDeviceSubModalContent}
              client ={this.props.client}
              userId ={this.state.userDetails?.id}
              deviceId = {this.props.id}
              onReject={this.rejectUsage}
              onConfirm={this.confirmUsage}
              setClientSubscription={value => this.setState({clientSubscription: value})}
           />
          }

          <Grid.Column style={{
            display: this.props.detailedInfoId !== null && this.props.detailedInfoId !== this.props.id ? 'none' : 'flex',
            justifyContent: 'center',
            paddingTop: 20
          }} className={"multi-device-column " + (this.state.deviceScreenState === "1" && this.props.connectedDeviceCount === 3 ? "landscape-mode" : "")}>
            <div>
              <Dimmer.Dimmable dimmed={this.state.smartScreenshotLoading}>

                <Dimmer active={this.state.smartScreenshotLoading} inverted>
                  <Loader>{TAKING_SCREENSHOTS_LOADER()}</Loader>
                </Dimmer>

                {this.state.selectedDevice?.status?.Reserved &&
                <div className='device-instance-time-info'>{deviceInstanceMessages().RESERVATION_REMAINING_TIME_HEADER}:
                  <strong> {this.renderRemainingReservationTime()}</strong></div>}
                  <div id={"remoteSegment-" + this.props.id} onWheel={this.handleScroll} style={{ display: 'flex', position: 'relative' }}
                  draggable={true} data-id={this.props.id} onMouseOver={this.handleOnMouseOver} onMouseLeave={this.handleOnMouseLeave}
                  onMouseDownCapture={this.handleOnClick}
                  onMouseUpCapture={this.handleMouseRelease}
                  onDragStart={this.handleDragEventStart} onDragOver={this.handleDragEvent}
                  {...(this.props.isFirefox ? { onDrop: this.handleDropEvent } : { onDragEnd: this.handleDragEventStop })}
                >
                  <img className="deviceImage" onLoad={(event) => this.onLoadImage(event)}
                    style={this.state.deviceImgStyle}
                    src={"data:image/png;base64," + this.state.icon} alt="phone"
                    onKeyDown={this.handleKeyEvents} tabIndex="0"
                  />
                  {/* {this.state.inspectorMode === INSPECTOR_MODES.TAPELEMENTS &&
                    <HighlighterRects
                      hoveredElement={this.state.hoveredElement}
                      selectedElement={this.state.selectedElement}
                      selectHoveredElement={this.selectHoveredElement}
                      selectSelectedElement={this.selectSelectedElement}
                      source={this.state.boundsJSON}
                      ratio={this.state.scaleRatio}
                    />} */}
                  {this.state.inspectorMode === INSPECTOR_MODES.SWIPE &&
                    <SwipeElements
                      scaleRatio={this.state.scaleRatio}
                      imageBounds={this.state.imageBounds}
                      swipeStart={this.state.swipeStart}
                      swipeEnd={this.state.swipeEnd}
                    />}
                </div>

                 <Segment vertical
                  style={{ backgroundColor: "#222222", border: "0", padding: "0", margin: "0" }}>
                  <Button.Group fluid secondary>
                    {this.buttonSelector()}
                  </Button.Group>
                 </Segment>
              </Dimmer.Dimmable>
            </div>
            <MultiManageOperation
              id={this.props.id}
              detailedInfoId={this.props.detailedInfoId}
              setDetailedInfoId={this.props.setDetailedInfoId}
              multiTouch={this.state.multiTouchToggle}
              multiTouchToggle={this.handleMultiTouch}
              privileges={this.props.privileges}
              handleQuality={this.handleQuality}
              handleRotate={this.handleRotate}
              handleSwitchLock={this.handleSwitchLock}
              qualityValue={this.state.qualityValue}
              deviceOs={this.state.selectedDevice.os}
              deviceScreenState={this.state.deviceScreenState}
              removeFromConnectedDeviceIdList={this.props.removeFromConnectedDeviceIdList}
              testSessionField={this.state.testSessionField}
              isSmartScreenshotOn={this.state.isSmartScreenshotOn}
              toggleSmartScreenshot={this.toggleSmartScreenshot}
              connectedDeviceCount={this.props.connectedDeviceCount}
              deviceReservationData={this.state.deviceReservationData}
              selectedDevice={this.state.selectedDevice}
              getReservationInfo={this.getReservationInfo}
              embedMode={this.props.embedMode}
              isReservable={this.state.selectedDevice.bookable}
              isVFAgentAvailable={this.state.isVFAgentAvailable}
              handleChangeDeviceLocale={this.handleChangeDeviceLocale}
              numberOfClick={this.state.numberOfClick}
              numberOfFinger={this.state.numberOfFinger}
              handleClickMultiTouch={this.handleClickMultiTouch}
              handleFingerMultiTouch={this.handleFingerMultiTouch}
              resetHandleMultiTouch={this.resetHandleMultiTouch}
            />
          </Grid.Column>
          {
            this.props.id === this.props.detailedInfoId &&
            <Grid.Column className="device-manage-right">
              {
                this.state.testSessionField === undefined ?
                  <ManageLog
                    onRef={ref => this.manageLogRef = ref}
                    deviceOs={this.state.selectedDevice.os}
                    privileges={this.props.privileges}
                    id={this.props.id}
                    handleChangeInspectorMode={this.handleChangeInspectorMode}
                    inspectorMode={this.state.inspectorMode}
                    handleSelectedElement={this.handleSelectedElement}
                    handleAppSource={this.handleAppSource}
                    handleScreenshot={this.handleScreenshot}
                    handleScreenResolution={this.handleScreenResolution}
                    updateRecordedActions={this.updateRecordedActions}
                    isRecording={this.state.isRecording}
                    handleRecordingStatus={this.handleRecordingStatus}
                    recordedActions={this.state.recordedActions}
                    clearActions={this.handleClearActions}
                    selectedElementPath={this.state.selectedElementPath}
                    resetWebSocket={this.resetWebSocket}
                    setAfterInspector={this.setAfterInspector}
                    afterInspector={this.state.afterInspector}
                    inspectorStarted={this.handleInspectorStarted}
                    setActiveIndex={this.setActiveIndex}
                    embedMode={this.props.embedMode}
                    userId={this.state.userId}
                    udId={this.state.udId}
                    token={this.props.match.params.token}

                    // below part----------: frozen inspector screen attributes toward ManageInspector.js
                    detailedInfoId={this.props.detailedInfoId}
                    idd={this.props.id}
                    deviceScreenState={this.state.deviceScreenState}
                    connectedDeviceCount={this.props.connectedDeviceCount} 
                    smartScreenshotLoading={this.state.smartScreenshotLoading}
                    selectedDevice={this.state.selectedDevice}
                    onLoadImage={this.onLoadImage}
                    deviceImgStyle={this.state.deviceImgStyle}
                    icon={this.state.icon}
                    handleKeyEvents={this.handleKeyEvents}
                    hoveredElement={this.state.hoveredElement}
                    selectedElement={this.state.selectedElement}
                    selectHoveredElement={this.selectHoveredElement}
                    selectSelectedElement={this.selectSelectedElement}
                    source={this.state.boundsJSON}
                    ratio={this.state.scaleRatioForFrozenInspector}                    
                    // above part------------: frozen inspector screen attributes toward ManageInspector.js                                      

                    setAppiumSessionId={this.setAppiumSessionId}
                    clearAppiumSessionId={this.clearAppiumSessionId}
                  /> :
                  <ManageDeviceInfo id={this.props.id}
                    setAfterInspector={this.setAfterInspector}
                    afterInspector={this.state.afterInspector}
                    testSessionField={this.state.testSessionField}
                    onClickEndSession={this.props.onClickEndSession} />
              }
            </Grid.Column>
          }
           {(this.state.selectedDevice && this.props.chatAppAvailability ==='true' && this.props.chatPortalVisibility ==='true') &&
             <Chat
               type={'device'}
               device = {this.state.selectedDevice}
               chatPortalVisibility={this.props.chatPortalVisibility} 
               setchatPortalVisibility={(value)=>this.props.setchatPortalVisibility(value)}
             />
           } 

        </>
      );
    } else {
      return (
        <Grid.Column className='left-grid-device'>
          <div className="load-device">
            <Dimmer active>
              <Loader active={true} size={"large"} indeterminate>
                <div id={"loaderText"}>{LOADING()}</div>
              </Loader>
            </Dimmer>
          </div>
        </Grid.Column>
      );
    }
  }
}

function getScrollDirection(event) {
  if (event.deltaY > 0) {
    return "DOWN";
  } else {
    return "UP";
  }
}

const mapStateToProps = state => {
  return{
    client : state.stompClient.client
  }
}

export default withTranslation()(connect(mapStateToProps,{})(withRouter(DeviceInstance)));
