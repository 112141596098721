import React, { useEffect, useState } from 'react';
import { Dropdown, Form, Icon } from 'semantic-ui-react';
import { changeActiveGroup, getUserGroups } from '../api/apiCalls';
import { setSessionValuesFromToken } from '../utils/Methods';
import { showError } from '../utils/ToastHelpers';
import { PUBLIC } from '../utils/UIMessages';
import '../styles.css';

const ChangeActiveGroupDropdown = () => {

    const [selectedGroup, setSelectedGroup] = useState(parseInt(localStorage.getItem('activeGroup')));
    const [groupOptions, setGroupOptions] = useState([]);
    const [apiProgress, setApiProgress] = useState(false);

    useEffect(() => {
        getUserGroups().then(res => {
            const options = res.data?.map(({ id, name }) => ({ key: id, text: name, value: id }));
            setGroupOptions([{ key: -1, text: PUBLIC(), value: -1 }, ...options]);
        }).catch(err => showError(err?.response?.data?.message));
    }, []);
    const handleChange = async (e, { value }) => {
        setSelectedGroup(value);
        setApiProgress(true);
        try {
            const response = await changeActiveGroup(value);
            setSessionValuesFromToken(response.data);
            window.location.reload();
        } catch (err) {
            showError(err?.response?.data?.message);
        }finally{
            setApiProgress(false);
        }
    };
    return (
        <>
            <Form>
                <Form.Field>
                    <div className="custom-dropdown">
                        <Icon name="users" />
                        <Dropdown
                            onChange={handleChange}
                            value={selectedGroup}
                            options={groupOptions}
                            loading={apiProgress}
                        />
                    </div>
                </Form.Field>
            </Form>
        </>
    );
};

export default ChangeActiveGroupDropdown;