import React, { useEffect, useState } from 'react';
import { Modal, Tab, Button, Segment, Label } from 'semantic-ui-react'
import { promoteFeatureMessages, VERSION_HEADER, OK_BUTTON } from '../../utils/UIMessages';
import packageJSON from '../../../package.json';
import { viewVersionPromoterModal, getVersionInnovations } from '../../api/apiCalls';
import { showError, showSuccess } from '../../utils/ToastHelpers';
import _ from 'lodash';

const VersionPromoterModal = ({ openProp = false, setOpenProp = () => { } }) => {
    //Data will be fetched and this array will be filled up with the responsed data
    const [innovationsData, setInnovationsData] = useState({});
    //Header of modal will be dynamic and be changed in every tab change, accordingly.
    const [modalHeaderVersionNumber, setModalHeaderVersionNumber] = useState(packageJSON.version);
    const [pendigRequest, setPendingRequest] = useState(false);

    const panes = () => Object.keys(innovationsData).map(eachVersion => {
        return {
            menuItem: `${eachVersion}`,
            render: () => <Tab.Pane loading={pendigRequest} className='version-promoter-modal-pane-each-tab' attached={true}>
                {innovationsData[eachVersion].map((eachInnovation, idx) => (
                    <Segment key={idx} className='version-promoter-modal-segment'>
                        <Label className='version-promoter-modal-type-label' ribbon='right'><span className='version-promoter-modal-type-label-text'>{promoteFeatureMessages(eachInnovation).CUSTOM_TYPE}</span></Label>
                        <b className='version-promoter-modal-segment-header'>{promoteFeatureMessages(eachInnovation).CUSTOM_TITLE}</b>
                        <p className='version-promoter-modal-segment-description'>
                            {promoteFeatureMessages(eachInnovation).CUSTOM_DESCRIPTION}
                        </p>
                    </Segment>
                ))}
            </Tab.Pane>
        }
    });

    useEffect(() => {
        if (openProp === true) {
            setModalHeaderVersionNumber(packageJSON.version)
            setPendingRequest(true);
            getVersionInnovations()
                .then(res => setInnovationsData(res?.data))
                .catch(err => showError(`${promoteFeatureMessages().ERR_FETCH_VERSION_INOVATIONS}`
                    + err.response?.data?.message ?? err.toString()))
                .finally(() => setPendingRequest(false));
        }
    }, [openProp]);

    return (
        <Modal open={openProp} onClose={() => setOpenProp(false)} dimmer={'blurring'} size='small' id='version-promoter-modal'>
            <Modal.Header className='version-promoter-modal-header'>
                Visium Farm {VERSION_HEADER()} {modalHeaderVersionNumber} | {promoteFeatureMessages().PROMOTER_MODAL_HEADER}
            </Modal.Header>
            {!_.isEmpty(innovationsData) ?
                <Modal.Content scrolling className='version-promoter-modal-content'>
                    <Tab className='version-promoter-tab' panes={panes()} onTabChange={(event, data) => {
                        setModalHeaderVersionNumber(data.panes[data.activeIndex].menuItem.toString());
                    }} />
                </Modal.Content> :
                <Modal.Content>
                    <h3 className='version-promoter-empty-modal-content'>{promoteFeatureMessages().NO_INNOVATION_MESSAGE}</h3>
                </Modal.Content>
            }
            <Modal.Actions>
                <Button
                    className='version-promoter-modal-ok-button'
                    content={JSON.parse(localStorage.getItem('showVersionPromoterModal')) === true
                        ? `${OK_BUTTON()}, ${promoteFeatureMessages().LETS_START}!`
                        : `${OK_BUTTON()}`}
                    labelPosition='right' icon='checkmark' onClick={() => {
                        if (localStorage.getItem('showVersionPromoterModal') && JSON.parse(localStorage.getItem('showVersionPromoterModal')) === true) {
                            localStorage.setItem('showVersionPromoterModal', false)
                            viewVersionPromoterModal(packageJSON.version.replace(/(\d+\.\d+.\d+)[-\\.].*/, "$1")).then(() => {
                                JSON.parse(localStorage.getItem('showVersionPromoterModal')) === true && showSuccess(promoteFeatureMessages().WELCOME_MSG_PROMOTER_MODAL + '!');
                            })
                                .catch(err => showError(`${promoteFeatureMessages().ERR_VIEW_VERSION_PROMOTE_MODAL}`
                                    + err.response?.data?.message ?? err.toString()));
                        }
                        setOpenProp(false);
                    }}
                />
            </Modal.Actions>
        </Modal>
    );
}

export default VersionPromoterModal;