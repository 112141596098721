export const PrivilegeConstants = {
    DEVICE_LIST: '4',
    DEVICE_CONTROL: '24',
    SUPREME_DEVICE_MANAGEMENT: '27',
    ACCESS_AUTOMATION: '33',
    AUTOMATION_TEST_SESSIONS_MANAGEMENT: '39',
    MANUAL_TEST_SESSIONS_MANAGEMENT: '40',
    GROUP_MANAGEMENT:'35',
    REMOVE_GRID_SESSION: '12',

    //Application Management
    APPLICATION_MANAGEMENT:{
        LIBRARY_ACCESS:'20',
        INSTALL:'2',
        UPLOAD_TO_LIB:'55',
        DOWNLOAD_FROM_LIB:'56',
        DELETE_FROM_LIB:'57'
    },

    //Reports
    RAW_DEVICE_USAGE_REPORT: '13',
    DEVICE_USAGE_REPORT: '10',
    INSTANT_USAGE_REPORT: '15',
    API_USAGE_REPORT: '41',
    ANNOUNCEMENT_MANAGEMENT: '53',

    DEVICE_INFORMATION: '7',

    SYSTEM_PARAMETER: '37',

    RESERVATION: {
        NORMAL: '14',
        OFFLINE: '44',
        GROUP: '58'
    },

    //File Privileges
    FILE_MANAGEMENT: {
        DEPLOY: '31',
        DOWNLOAD: '46',
        LIST: '47',
        DELETE: '48',
        EDIT: '49',
        UPLOAD: '50'
    },

    WEBHOOK: '51',
    ALARMS: '52',

    EDIT_DEVICE_INFORMATION: '11',

    REMOVE_DEVICE: '6',

    MULTI_TOUCH: '17',

    INTEGRATION: '54',

    REBOOT_SHUTDOWN: '16',

    DIAGNOSIS: '21',

    SHUTDOWN_ALL: '28',

    LICENSE_MANAGEMENT: '29',
    
    SECONDARY_SERVER_MANAGEMENT : '59',

    LDAP_CONFIGURATION: '22',
    
    OAUTH2_CONFIGURATION: '60',

    //Manual Test Session Management
    MANUAL_TEST_SESSIONS: {
        OVERALL_MANAGEMENT: '40',
        CREATE: '62',
        DELETE: '63',
        DELETE_ANY: '64',
        VIEW_ANY: '65',
        DELETE_FILE: '66',
        DOWNLOAD_ANY: '67',
        DELETE_ANY_FILE: '68'
    },

    //Automation Test Session Management
    AUTOMATION_TEST_SESSIONS: {
        OVERALL_MANAGEMENT: '39',
        CREATE: '69',
        DELETE: '70',
        DELETE_ANY: '71',
        VIEW_ANY: '72',
        DELETE_FILE: '73',
        DOWNLOAD_ANY: '74',
        DELETE_ANY_FILE: '75'
    }


}
